import { ActionType } from "../action/actionType";

const initialState = {
  courseResponse: [],
  totalNumberOfPagesAsPerGivenPageLimit: 0,
  pageCount: 0,
  createCourseResponse: {
    courseId: "",
    statusCode: 0,
  },
  createSectionResponse: {
    response: "",
    statusCode: 0,
  },
  getCourseByIdResponse: {
    statusCode: 0,
    responseData: [],
  },
  courseDeleteResponse: {
    statusCode: 0,
    responseData: "",
  },
  getSectionByCourseId: {
    statusCode: 0,
    responseData: [],
  },
  createChapterBySectionId: {
    statusCode: 0,
    responseData: "",
    sectionId: "",
  },
  getAllChapterBySectionId: {
    statusCode: 0,
    responseData: [],
  },
  createQuestionRequest: {
    questionRequest: [],
  },
  chapterQuestionMapping: {
    statusCode: 0,
    responseData: "",
  },
  uploadFilesByChapterId: {
    statusCode: 0,
    responseData: "",
  },
  getChapterById: {
    statusCode: 0,
    responseData: {},
  },
  deleteTypeById: {
    statusCode: 0,
    responseData: "",
  },
  deleteSection: {
    statusCode: 0,
    responseData: "",
  },
  deleteSectionMapping: {
    statusCode: 0,
    responseData: "",
  },
  mapDurationVideos: {
    statusCode: 0,
    responseData: "",
  },
  deleteQuestionById: {
    statusCode: 0,
    responseData: "",
  },
  deleteQuestionByIdFromHere: {
    statusCode: 0,
    responseData: "",
  },
  updateSection: {
    statusCode: 0,
    responseData: "",
  },
  deleteChapterById: {
    statusCode: 0,
    responseData: "",
  },
  updateCourse: {
    statusCode: 0,
    responseData: "",
  },
  publishCourse: {
    statusCode: 0,
    responseData: "",
  },
  questionImage: {
    statusCode: 0,
    responseData: [],
  },
  editQuestionImage: {
    statusCode: 0,
    responseData: [],
  },
  editQuestion: {
    statusCode: 0,
    responseData: "",
  },
  getAllCourse: {
    statusCode: 0,
    responseData: [],
  },
  getSectionByCourseIdForCopyFiles: {
    statusCode: 0,
    responseData: [],
  },
  getAllChapterBySectionIdForCopyFiles: {
    statusCode: 0,
    responseData: [],
  },
  getAllCourseForCopyQuestions: {
    statusCode: 0,
    responseData: [],
  },
  getSectionByCourseIdForCopyQuestions: {
    statusCode: 0,
    responseData: [],
  },
  getAllChapterBySectionIdForCopyQuestions: {
    statusCode: 0,
    responseData: [],
  },
  addFile: {
    statusCode: 0,
    responseData: "",
  },
  addQuestions: {
    statusCode: 0,
    responseData: "",
  },
  deleteQuestionImageById: {
    statusCode: 0,
    responseData: "",
  },
  getAllPublishedCourses: {
    statusCode: 0,
    responseData: [],
  },
  copyChapter: {
    statusCode: 0,
    responseData: "",
  },
  copySection: {
    statusCode: 0,
    responseData: "",
  },
  copyCourse: {
    statusCode: 0,
    responseData: "",
  },
  deleteCourseDurationMapping: {
    statusCode: 0,
    responseData: "",
  },
  getQuestionsByChapterId: {
    statusCode: 0,
    responseData: "",
  },
  getAllQuestion: {
    statusCode: 0,
    responseData: {},
  },
  mapOrderWithQuestion: {
    statusCode: 0,
    responseData: ""
  },
  getQuestionById: {
    statusCode: 0,
    responseData: "",
  },
  getResultsByChapterId: {
    statusCode: 0,
    responseMessage: "",
  },
};

export const CourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_ALL_COURSE:
      return {
        ...state,
        courseResponse: [
          ...state.courseResponse,
          ...action.payload.courseResponse,
        ],
        totalNumberOfPagesAsPerGivenPageLimit:
          action.payload.totalNumberOfPagesAsPerGivenPageLimit,
      };
    case ActionType.RESET_ALL_COURSE:
      return {
        ...state,
        courseResponse: [],
        totalNumberOfPagesAsPerGivenPageLimit: 0,
        pageCount: 0,
        createCourseResponse: {
          courseId: "",
          statusCode: 0,
        },
        createSectionResponse: {
          response: "",
          statusCode: 0,
        },
        getCourseByIdResponse: {
          statusCode: 0,
          responseData: [],
        },
        courseDeleteResponse: {
          statusCode: 0,
          responseData: "",
        },
        getSectionByCourseId: {
          statusCode: 0,
          responseData: [],
        },
        createChapterBySectionId: {
          statusCode: 0,
          responseData: "",
          sectionId: "",
        },
        getAllChapterBySectionId: {
          statusCode: 0,
          responseData: [],
        },
        createQuestionRequest: {
          questionRequest: [],
        },
        chapterQuestionMapping: {
          statusCode: 0,
          responseData: "",
        },
        uploadFilesByChapterId: {
          statusCode: 0,
          responseData: "",
        },
        getChapterById: {
          statusCode: 0,
          responseData: {},
        },
        deleteTypeById: {
          statusCode: 0,
          responseData: "",
        },
        deleteSection: {
          statusCode: 0,
          responseData: "",
        },
        deleteSectionMapping: {
          statusCode: 0,
          responseData: "",
        },
        mapDurationVideos: {
          statusCode: 0,
          responseData: "",
        },
        deleteQuestionById: {
          statusCode: 0,
          responseData: "",
        },
        updateSection: {
          statusCode: 0,
          responseData: "",
        },
        deleteChapterById: {
          statusCode: 0,
          responseData: "",
        },
        updateCourse: {
          statusCode: 0,
          responseData: "",
        },
        publishCourse: {
          statusCode: 0,
          responseData: "",
        },
        deleteQuestionByIdFromHere: {
          statusCode: 0,
          responseData: "",
        },
        questionImage: {
          statusCode: 0,
          responseData: [],
        },
        editQuestionImage: {
          statusCode: 0,
          responseData: [],
        },
        editQuestion: {
          statusCode: 0,
          responseData: "",
        },
        getAllCourse: {
          statusCode: 0,
          responseData: [],
        },
        getSectionByCourseIdForCopyFiles: {
          statusCode: 0,
          responseData: [],
        },
        getAllChapterBySectionIdForCopyFiles: {
          statusCode: 0,
          responseData: [],
        },
        addFile: {
          statusCode: 0,
          responseData: "",
        },
        getAllCourseForCopyQuestions: {
          statusCode: 0,
          responseData: [],
        },
        getSectionByCourseIdForCopyQuestions: {
          statusCode: 0,
          responseData: [],
        },
        getAllChapterBySectionIdForCopyQuestions: {
          statusCode: 0,
          responseData: [],
        },
        addQuestions: {
          statusCode: 0,
          responseData: "",
        },
        deleteQuestionImageById: {
          statusCode: 0,
          responseData: "",
        },
        getAllPublishedCourses: {
          statusCode: 0,
          responseData: [],
        },
        copyChapter: {
          statusCode: 0,
          responseData: "",
        },
        copySection: {
          statusCode: 0,
          responseData: "",
        },
        copyCourse: {
          statusCode: 0,
          responseData: "",
        },
        deleteCourseDurationMapping: {
          statusCode: 0,
          responseData: "",
        },
        getQuestionsByChapterId: {
          statusCode: 0,
          responseData: "",
        },
        getAllQuestion: {
          statusCode: 0,
          responseData: {},
        },
        mapOrderWithQuestion: {
          statusCode: 0,
          responseData: ""
        },
        getQuestionById: {
          statusCode: 0,
          responseData: "",
        },
        getResultsByChapterId: {
          statusCode: 0,
          responseMessage: "",
        },
      };
    case ActionType.UPDATE_PAGE_COUNT:
      return {
        ...state,
        pageCount: action.payload.pageCount,
      };
    case ActionType.COURSE_CREATE_RESPONSE:
      return {
        ...state,
        createCourseResponse: {
          courseId: action.payload.createCourseResponse.courseId,
          statusCode: action.payload.createCourseResponse.statusCode,
        },
      };
    case ActionType.RESET_COURSE_CREATE_RESPONSE:
      return {
        ...state,
        createCourseResponse: {
          courseId: "",
          statusCode: 0,
        },
      };
    case ActionType.SECTION_CREATE_RESPONSE:
      return {
        ...state,
        createSectionResponse: {
          courseId: action.payload.createSectionResponse.response,
          statusCode: action.payload.createSectionResponse.statusCode,
        },
      };
    case ActionType.GET_COURSE_BY_ID:
      return {
        ...state,
        getCourseByIdResponse: {
          responseData: action.payload.getCourseByIdResponse.responseData,
          statusCode: action.payload.getCourseByIdResponse.statusCode,
        },
      };
    case ActionType.RESET_GET_COURSE_BY_ID:
      return {
        ...state,
        getCourseByIdResponse: {
          responseData: [],
          statusCode: 0,
        },
      };
    case ActionType.DELETE_COURSE:
      return {
        ...state,
        courseDeleteResponse: {
          responseData: action.payload.courseDeleteResponse.responseData,
          statusCode: action.payload.courseDeleteResponse.statusCode,
        },
      };
    case ActionType.RESET_DELETE_COURSE_RESPONSE:
      return {
        ...state,
        courseDeleteResponse: {
          responseData: "",
          statusCode: 0,
        },
      };
    case ActionType.GET_SECTION_BY_COURSE_ID:
      return {
        ...state,
        getSectionByCourseId: {
          statusCode: action.payload.getSectionByCourseId.statusCode,
          responseData: action.payload.getSectionByCourseId.responseData,
        },
      };
    case ActionType.RESET_SECTION_BY_COURSE_ID:
      return {
        ...state,
        getSectionByCourseId: {
          statusCode: 0,
          responseData: [],
        },
      };
    case ActionType.RESET_CREATE_SECTION_RESPONSE:
      return {
        ...state,
        createSectionResponse: {
          response: "",
          statusCode: 0,
        },
      };
    case ActionType.CREATE_CHAPTER_BY_SECTION_ID:
      return {
        ...state,
        createChapterBySectionId: {
          statusCode: action.payload.createChapterBySectionId.statusCode,
          responseData: action.payload.createChapterBySectionId.responseData,
          sectionId: action.payload.createChapterBySectionId.sectionId,
        },
      };
    case ActionType.RESET_CREATE_CHAPTER_BY_SECTION_ID:
      return {
        ...state,
        createChapterBySectionId: {
          statusCode: 0,
          responseData: "",
          sectionId: "",
        },
      };
    case ActionType.RESET_GET_ALL_CHAPTER_BY_SECTION_ID:
      return {
        ...state,
        getAllChapterBySectionId: {
          statusCode: 0,
          responseData: [],
        },
      };
    case ActionType.GET_ALL_CHAPTER_BY_SECTION_ID:
      return {
        ...state,
        getAllChapterBySectionId: {
          statusCode: action.payload.getAllChapterBySectionId.statusCode,
          responseData: action.payload.getAllChapterBySectionId.responseData,
        },
      };
    case ActionType.CREATE_QUESTION_REQUEST:
      return {
        ...state,
        createQuestionRequest: {
          questionRequest: [
            ...state.createQuestionRequest.questionRequest,
            ...action.payload.createQuestionRequest.questionRequest,
          ],
        },
      };
    case ActionType.RESET_CREATE_QUESTION_REQUEST:
      return {
        ...state,
        createQuestionRequest: {
          questionRequest: [],
        },
      };
    case ActionType.CHAPTER_QUESTION_MAPPING:
      return {
        ...state,
        chapterQuestionMapping: {
          statusCode: action.payload.chapterQuestionMapping.statusCode,
          responseData: action.payload.chapterQuestionMapping.responseData,
        },
      };
    case ActionType.RESET_CHAPTER_QUESTION_MAPPING:
      return {
        ...state,
        chapterQuestionMapping: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.UPLOAD_FILES_BY_CHAPTER_ID:
      return {
        ...state,
        uploadFilesByChapterId: {
          statusCode: action.payload.uploadFilesByChapterId.statusCode,
          responseData: action.payload.uploadFilesByChapterId.responseData,
        },
      };
    case ActionType.RESET_UPLOAD_FILES_BY_CHAPTER_ID:
      return {
        ...state,
        uploadFilesByChapterId: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.GET_CHAPTER_BY_ID:
      return {
        ...state,
        getChapterById: {
          statusCode: action.payload.getChapterById.statusCode,
          responseData: action.payload.getChapterById.responseData,
        },
      };
    case ActionType.RESET_GET_CHAPTER_BY_ID:
      return {
        ...state,
        getChapterById: {
          statusCode: 0,
          responseData: {},
        },
      };
    case ActionType.DELETE_TYPE_BY_ID:
      return {
        ...state,
        deleteTypeById: {
          statusCode: action.payload.deleteTypeById.statusCode,
          responseData: action.payload.deleteTypeById.responseData,
        },
      };
    case ActionType.RESET_DELETE_TYPE_BY_ID:
      return {
        ...state,
        deleteTypeById: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.DELETE_SECTION:
      return {
        ...state,
        deleteSection: {
          statusCode: action.payload.deleteSection.statusCode,
          responseData: action.payload.deleteSection.responseData,
        },
      };
    case ActionType.RESET_DELETE_SECTION:
      return {
        ...state,
        deleteSection: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.SECTION_DELETE_MAPPING:
      return {
        ...state,
        deleteSectionMapping: {
          statusCode: action.payload.deleteSectionMapping.statusCode,
          responseData: action.payload.deleteSectionMapping.responseData,
        },
      };
    case ActionType.RESET_SECTION_DELETE_MAPPING:
      return {
        ...state,
        deleteSectionMapping: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.MAP_DURATION_WITH_VIDEOS:
      return {
        ...state,
        mapDurationVideos: {
          statusCode: action.payload.mapDurationVideos.statusCode,
          responseData: action.payload.mapDurationVideos.responseData,
        },
      };
    case ActionType.RESET_MAP_DURATION_WITH_VIDEOS:
      return {
        ...state,
        mapDurationVideos: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.DELETE_QUESTION_BY_ID:
      return {
        ...state,
        deleteQuestionById: {
          statusCode: action.payload.deleteQuestionById.statusCode,
          responseData: action.payload.deleteQuestionById.responseData,
        },
      };
    case ActionType.RESET_DELETE_QUESTION_BY_ID:
      return {
        ...state,
        deleteQuestionById: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.DELETE_QUESTION_BY_ID_FROM_HERE:
      return {
        ...state,
        deleteQuestionByIdFromHere: {
          statusCode: action.payload.deleteQuestionByIdFromHere.statusCode,
          responseData: action.payload.deleteQuestionByIdFromHere.responseData,
        },
      };
    case ActionType.RESET_DELETE_QUESTION_BY_ID_FROM_HERE:
      return {
        ...state,
        deleteQuestionByIdFromHere: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.UPDATE_SECTION:
      return {
        ...state,
        updateSection: {
          statusCode: action.payload.updateSection.statusCode,
          responseData: action.payload.updateSection.responseData,
        },
      };
    case ActionType.RESET_UPDATE_SECTION:
      return {
        ...state,
        updateSection: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.DELETE_CHAPTER_BY_ID:
      return {
        ...state,
        deleteChapterById: {
          statusCode: action.payload.deleteChapterById.statusCode,
          responseData: action.payload.deleteChapterById.responseData,
        },
      };
    case ActionType.RESET_DELETE_CHAPTER_BY_ID:
      return {
        ...state,
        deleteChapterById: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.UPDATE_COURSE_RESPONSE:
      return {
        ...state,
        updateCourse: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.RESET_UPDATE_COURSE_RESPONSE:
      return {
        ...state,
        updateCourse: {
          statusCode: action.payload.updateCourse.statusCode,
          responseData: action.payload.updateCourse.responseData,
        },
      };
    case ActionType.PUBLISH_COURSE:
      return {
        ...state,
        publishCourse: {
          statusCode: action.payload.publishCourse.statusCode,
          responseData: action.payload.publishCourse.responseData,
        },
      };
    case ActionType.RESET_PUBLISH_COURSE:
      return {
        ...state,
        publishCourse: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.QUESTION_IMAGE:
      return {
        ...state,
        questionImage: {
          statusCode: action.payload.questionImage.statusCode,
          responseData: action.payload.questionImage.responseData,
        },
      };
    case ActionType.RESET_QUESTION_IMAGE:
      return {
        ...state,
        questionImage: {
          statusCode: 0,
          responseData: [],
        },
      };
    case ActionType.EDIT_QUESTION_IMAGE:
      return {
        ...state,
        editQuestionImage: {
          statusCode: action.payload.editQuestionImage.statusCode,
          responseData: action.payload.editQuestionImage.responseData,
        },
      };
    case ActionType.RESET_EDIT_QUESTION_IMAGE:
      return {
        ...state,
        editQuestionImage: {
          statusCode: 0,
          responseData: [],
        },
      };
    case ActionType.EDIT_QUESTION:
      return {
        ...state,
        editQuestion: {
          statusCode: action.payload.editQuestion.statusCode,
          responseData: action.payload.editQuestion.responseData,
        },
      };
    case ActionType.RESET_EDIT_QUESTION:
      return {
        ...state,
        editQuestion: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.GET_ALL_COURSE_RESPONSE:
      return {
        ...state,
        getAllCourse: {
          statusCode: action.payload.getAllCourse.statusCode,
          responseData: action.payload.getAllCourse.responseData,
        },
      };
    case ActionType.RESET_GET_ALL_COURSE_RESPONSE:
      return {
        ...state,
        getAllCourse: {
          statusCode: 0,
          responseData: [],
        },
      };
    case ActionType.GET_SECTION_BY_COURSE_ID_FOR_COPY_FILES:
      return {
        ...state,
        getSectionByCourseIdForCopyFiles: {
          statusCode:
            action.payload.getSectionByCourseIdForCopyFiles.statusCode,
          responseData:
            action.payload.getSectionByCourseIdForCopyFiles.responseData,
        },
      };
    case ActionType.RESET_GET_SECTION_BY_COURSE_ID_FOR_COPY_FILES:
      return {
        ...state,
        getSectionByCourseIdForCopyFiles: {
          statusCode: 0,
          responseData: [],
        },
      };
    case ActionType.GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_FILES:
      return {
        ...state,
        getAllChapterBySectionIdForCopyFiles: {
          statusCode:
            action.payload.getAllChapterBySectionIdForCopyFiles.statusCode,
          responseData:
            action.payload.getAllChapterBySectionIdForCopyFiles.responseData,
        },
      };
    case ActionType.RESET_GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_FILES:
      return {
        ...state,
        getAllChapterBySectionIdForCopyFiles: {
          statusCode: 0,
          responseData: [],
        },
      };
    case ActionType.ADD_FILE:
      return {
        ...state,
        addFile: {
          statusCode: action.payload.addFile.statusCode,
          responseData: action.payload.addFile.responseData,
        },
      };
    case ActionType.RESET_ADD_FILE:
      return {
        ...state,
        addFile: {
          statusCode: 0,
          responseData: "",
        },
      };

    case ActionType.GET_ALL_COURSE_FOR_COPY_QUESTIONS_RESPONSE:
      return {
        ...state,
        getAllCourseForCopyQuestions: {
          statusCode: action.payload.getAllCourseForCopyQuestions.statusCode,
          responseData:
            action.payload.getAllCourseForCopyQuestions.responseData,
        },
      };
    case ActionType.RESET_GET_ALL_COURSE_FOR_COPY_QUESTIONS_RESPONSE:
      return {
        ...state,
        getAllCourseForCopyQuestions: {
          statusCode: 0,
          responseData: [],
        },
      };
    case ActionType.GET_SECTION_BY_COURSE_ID_FOR_COPY_QUESTIONS:
      return {
        ...state,
        getSectionByCourseIdForCopyQuestions: {
          statusCode:
            action.payload.getSectionByCourseIdForCopyQuestions.statusCode,
          responseData:
            action.payload.getSectionByCourseIdForCopyQuestions.responseData,
        },
      };
    case ActionType.RESET_GET_SECTION_BY_COURSE_ID_FOR_COPY_QUESTIONS:
      return {
        ...state,
        getSectionByCourseIdForCopyQuestions: {
          statusCode: 0,
          responseData: [],
        },
      };
    case ActionType.GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_QUESTIONS:
      return {
        ...state,
        getAllChapterBySectionIdForCopyQuestions: {
          statusCode:
            action.payload.getAllChapterBySectionIdForCopyQuestions.statusCode,
          responseData:
            action.payload.getAllChapterBySectionIdForCopyQuestions
              .responseData,
        },
      };
    case ActionType.RESET_GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_QUESTIONS:
      return {
        ...state,
        getAllChapterBySectionIdForCopyQuestions: {
          statusCode: 0,
          responseData: [],
        },
      };

    case ActionType.ADD_QUESTIONS:
      return {
        ...state,
        addQuestions: {
          statusCode: action.payload.addQuestions.statusCode,
          responseData: action.payload.addQuestions.responseData,
        },
      };
    case ActionType.RESET_ADD_QUESTIONS:
      return {
        ...state,
        addQuestions: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.DELETE_QUESTION_IMAGE_BY_ID:
      return {
        ...state,
        deleteQuestionImageById: {
          statusCode: action.payload.deleteQuestionImageById.statusCode,
          responseData: action.payload.deleteQuestionImageById.responseData,
        },
      };
    case ActionType.RESET_DELETE_QUESTION_IMAGE_BY_ID:
      return {
        ...state,
        deleteQuestionImageById: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.GET_ALL_PUBLISHED_COURSES:
      return {
        ...state,
        getAllPublishedCourses: {
          statusCode: 0,
          responseData: [...action.payload.getAllPublishedCourses.responseData],
        },
      };
    case ActionType.RESET_GET_ALL_PUBLISHED_COURSES:
      return {
        ...state,
        getAllPublishedCourses: {
          statusCode: 0,
          responseData: [],
        },
      };
    case ActionType.COPY_CHAPTER:
      return {
        ...state,
        copyChapter: {
          statusCode: action.payload.copyChapter.statusCode,
          responseData: action.payload.copyChapter.responseData,
        },
      };
    case ActionType.RESET_COPY_CHAPTER:
      return {
        ...state,
        copyChapter: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.COPY_SECTION:
      return {
        ...state,
        copySection: {
          statusCode: action.payload.copySection.statusCode,
          responseData: action.payload.copySection.responseData,
        },
      };
    case ActionType.RESET_COPY_SECTION:
      return {
        ...state,
        copySection: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.COPY_COURSE:
      return {
        ...state,
        copyCourse: {
          statusCode: action.payload.copyCourse.statusCode,
          responseData: action.payload.copyCourse.responseData,
        },
      };
    case ActionType.RESET_COPY_COURSE:
      return {
        ...state,
        copyCourse: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.DELETE_COURSE_DURATION_MAPPING:
      return {
        ...state,
        deleteCourseDurationMapping: {
          statusCode: action.payload.deleteCourseDurationMapping.statusCode,
          responseData: action.payload.deleteCourseDurationMapping.responseData,
        },
      };
    case ActionType.RESET_DELETE_COURSE_DURATION_MAPPING:
      return {
        ...state,
        deleteCourseDurationMapping: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.GET_QUESTIONS_BY_CHAPTER_ID:
      return {
        ...state,
        getQuestionsByChapterId: {
          statusCode: action.payload.getQuestionsByChapterId.statusCode,
          responseData: action.payload.getQuestionsByChapterId.responseData,
        },
      };
    case ActionType.RESET_GET_QUESTIONS_BY_CHAPTER_ID:
      return {
        ...state,
        getQuestionsByChapterId: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.GET_ALL_QUESTION:
      return {
        ...state,
        getAllQuestion: {
          statusCode: action.payload.getAllQuestion.statusCode,
          responseData: action.payload.getAllQuestion.responseData,
        },
      };
    case ActionType.RESET_GET_ALL_QUESTION:
      return {
        ...state,
        getAllQuestion: {
          statusCode: 0,
          responseData: {},
        },
      };
      case ActionType.MAP_ORDER_WITH_QUESTION:
      return {
        ...state,
        mapOrderWithQuestion: {
          statusCode: action.payload.mapOrderWithQuestion.statusCode,
          responseData: action.payload.mapOrderWithQuestion.responseData,
        },
      };
      case ActionType.RESET_MAP_ORDER_WITH_QUESTION:
      return {
        ...state,
        mapOrderWithQuestion: {
          statusCode: 0,
          responseData: "",
        },
      };
      case ActionType.GET_QUESTION_BY_ID:
      return {
        ...state,
        getQuestionById: {
          statusCode: action.payload.getQuestionById.statusCode,
          responseData: action.payload.getQuestionById.responseData,
        },
      };
      case ActionType.RESET_GET_QUESTION_BY_ID:
      return {
        ...state,
        getQuestionById: {
          statusCode: 0,
          responseData: "",
        },
      };
      case ActionType.GET_RESULT_BY_CHAPTER_ID:
      return {
        ...state,
        getResultsByChapterId: {
          statusCode: action.payload.getResultsByChapterId.statusCode,
          responseMessage: action.payload.getResultsByChapterId.responseMessage,
        },
      };
      case ActionType.RESET_GET_RESULT_BY_CHAPTER_ID:
      return {
        ...state,
        getResultsByChapterId: {
          statusCode: 0,
          responseMessage: "",
        },
      };
    default:
      return state;
  }
};
