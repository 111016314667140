import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import "./PreviewFilesForQuestionPopup.css";
import CancelIcon from "@mui/icons-material/Cancel";

const PreviewFilesForQuestionPopup = ({
  show,
  questionData,
  onCancelClick,
}) => {
  //? handle close preview files
  const handleClosePreviewFiles = () => {
    onCancelClick();
  };

  return (
    <>
      <div>
        <Dialog open={show}>
          <DialogTitle>
            <div className="close-icon" onClick={handleClosePreviewFiles}>
              <CancelIcon />
            </div>
          </DialogTitle>
          <DialogContent className="preview-files-for-question-popup-dialog">
            {questionData !== undefined && (
              <div>
                {questionData.questionImageUrl !== undefined &&
                  questionData.questionImageUrl.length !== 0 && (
                    <p>Question Image</p>
                  )}
                {questionData.questionImageUrl !== undefined &&
                  questionData.questionImageUrl.length !== 0 &&
                  questionData.questionImageUrl.map((val, key) => {
                    return (
                      <a
                        key={key}
                        className="image-item"
                        href={val.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <span className="text-style-image-url">
                            Question image : {key + 1}
                          </span>
                        </div>
                      </a>
                    );
                  })}
              </div>
            )}
            {questionData !== undefined && (
              <div>
                {questionData !== undefined &&
                  questionData.optionRequest !== undefined &&
                  questionData.optionRequest.length !== 0 &&
                  questionData.optionRequest.map(
                    (optionData, keyOptionRequest) => {
                      return (
                        <div>
                          {optionData.optionImageUrl !== undefined &&
                            optionData.optionImageUrl.length !== 0 && (
                              <p>Option {keyOptionRequest + 1} Image</p>
                            )}
                          {optionData.optionImageUrl !== undefined &&
                            optionData.optionImageUrl.length !== 0 &&
                            optionData.optionImageUrl.map((value, keys) => {
                              return (
                                <a
                                  key={keys}
                                  className="image-item"
                                  href={value.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div>
                                    <span className="text-style-image-url">
                                      Option image : {keys + 1}
                                    </span>
                                  </div>
                                </a>
                              );
                            })}
                        </div>
                      );
                    }
                  )}
              </div>
            )}
            {questionData !== undefined && (
              <div>
                {questionData !== undefined &&
                  questionData.descriptionImageUrl !== undefined &&
                  questionData.descriptionImageUrl.length !== 0 && (
                    <p>Description Image</p>
                  )}
                {questionData !== undefined &&
                  questionData.descriptionImageUrl !== undefined &&
                  questionData.descriptionImageUrl.length !== 0 &&
                  questionData.descriptionImageUrl.map(
                    (value, keyDescription) => {
                      return (
                        <a
                          key={keyDescription}
                          className="image-item"
                          href={value.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div>
                            <span className="text-style-image-url">
                              Description image : {keyDescription + 1}
                            </span>
                          </div>
                        </a>
                      );
                    }
                  )}
              </div>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export { PreviewFilesForQuestionPopup };
