import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import { useEffect } from "react";
import { Constant } from "../constants/Constant";
import { NetworkConstant } from "../network/NetworkConstant";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "../redux/action/actionType";
import { BlueLoader } from "../util/AppUtils";
import {
  formatNumber,
  parseDataIntoDateAndTimeInHumanReadableForm,
  parseDate,
} from "../util/CommonUtils";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./Transactions.css";
import { styled } from "@mui/material/styles";
import { makeNetworkCallGetAllPayment } from "../redux/action/PaymentAction";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ClearAllIcon from "@mui/icons-material/ClearAll";


const Transactions = () => {
  const dispatch = useDispatch();
  const paymentReducer = useSelector((state) => state.paymentReducer);
  const apiState = useSelector((state) => state.apiStateReducer);
  const [userData, setUserData] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const paymentStatus = [
    Constant.COURSE_STATUS_ALL,
    Constant.PAYMENT_CAPTURED,
    Constant.PAYMENT_AUTHORIZED,
  ];
  const [paymentStatusValue, setPaymentStatusValue] = useState(
    Constant.COURSE_STATUS_ALL
  );
  useEffect(() => {
    fetchPayments();
    return () => {
      dispatch({
        type: ActionType.RESET_GET_ALL_PAYMENTS,
        payload: {},
      });
    };
  }, []);

  //? handle selection range
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  //? handle select date
  const handleSelect = (date) => {
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
    setUserData([]);
    const choseStartDate = new Date(parseDate(date.selection.startDate));
    const choseEndDate = new Date(parseDate(date.selection.endDate));

    let filteredData = paymentReducer?.getAllPayments?.responseData?.filter(
      (val) => {
        return (
          new Date(parseDate(val?.paymentDate)) >= choseStartDate &&
          new Date(parseDate(val?.paymentDate)) <= choseEndDate
        );
      }
    );
    setUserData(filteredData);
  };

  //? handle clear data
  const handleClearData = () => {
    setShowCalendar(false);
    setUserData([]);
    setUserData(paymentReducer?.getAllPayments?.responseData);
  };

  //? handle change payment value
  const handleChangePaymentValue = (event) => {
    setPaymentStatusValue(event.target.value);
    dispatch({
      type: ActionType.RESET_GET_ALL_PAYMENTS,
      payload: {},
    });
    fetchGetAllPayments(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //? fetch payment data
  const fetchPayments = async () => {
    dispatch(
      makeNetworkCallGetAllPayment(NetworkConstant.getAllPayments, {}, {})
    );
  };

  //? fetch get all payments
  const fetchGetAllPayments = async (paymentStatus) => {
    paymentStatus = paymentStatus.toLowerCase();
    const updatedUrl =
      paymentStatus === Constant.COURSE_STATUS_ALL.toLowerCase()
        ? `${NetworkConstant.getAllPayments}`
        : `${NetworkConstant.getAllPayments}?payment-status=${paymentStatus}`;
    dispatch(makeNetworkCallGetAllPayment(updatedUrl, {}, {}));
  };

  useEffect(() => {
    if (paymentReducer?.getAllPayments?.statusCode === 200) {
      setUserData(paymentReducer?.getAllPayments?.responseData);
    }
  }, [paymentReducer?.getAllPayments?.statusCode]);

  //? styled row data and column

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function Row(props) {
    const { row, count } = props;
    const [open, setOpen] = useState(false);

    return (
      <>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell align="right">
            {<span>{count}</span>}
          </StyledTableCell>
          <StyledTableCell align="right">
            {<span>{row?.userEmailId}</span>}
          </StyledTableCell>
          <StyledTableCell align="right">
            {<span>{row?.contact}</span>}
          </StyledTableCell>
          <StyledTableCell align="right">
            {
              <span>
                {parseDataIntoDateAndTimeInHumanReadableForm(row?.paymentDate)}
              </span>
            }
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  <span>Payment</span>
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>{<span>Status</span>}</StyledTableCell>
                      <StyledTableCell>{<span>Type</span>}</StyledTableCell>
                      <StyledTableCell>
                        {<span>Transaction Id</span>}
                      </StyledTableCell>
                      <StyledTableCell>{<span>Order Id</span>}</StyledTableCell>
                      <StyledTableCell>{<span>Amount</span>}</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        {<span> {row?.paymentStatus}</span>}
                      </StyledTableCell>
                      <StyledTableCell>
                        {<span>{<span> {row?.paymentMethod}</span>}</span>}
                      </StyledTableCell>
                      <StyledTableCell>
                        {
                          <span>
                            {<span>{row?.paymentTransactionId}</span>}
                          </span>
                        }
                      </StyledTableCell>
                      <StyledTableCell>
                        {<span>{<span> {row?.orderId}</span>}</span>}
                      </StyledTableCell>
                      <StyledTableCell>
                        {
                          <span>
                            {<span>{formatNumber(row?.amount)}</span>}
                          </span>
                        }
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </>
    );
  }

  return (
    <>
      <div className="user-table-view">
        <div className="calendar-container">
          <Tooltip title="Calendar" arrow>
            <Fab
              className="button-focus"
              style={{
                marginRight: "20px",
              }}
              aria-label="Calendar"
              size="small"
              onClick={() => {
                setShowCalendar(!showCalendar);
              }}
            >
              <CalendarMonthIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Clear" arrow>
            <Fab
              className="button-focus"
              style={{
                marginRight: "20px",
              }}
              aria-label="Clear"
              size="small"
              onClick={() => {
                handleClearData();
              }}
            >
              <ClearAllIcon />
            </Fab>
          </Tooltip>
        </div>
        {showCalendar && (
          <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
        )}
        <FormControl
          style={{
            width: "100%",
            alignSelf: "center",
            marginTop: "20px",
          }}
        >
          <InputLabel>
            {
              <span className="font-change-style">
                {<span>Select payment</span>}
              </span>
            }
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={paymentStatusValue}
            label={
              <span className="font-change-style">
                {<span>Select payment</span>}
              </span>
            }
            MenuProps={MenuProps}
            onChange={handleChangePaymentValue}
          >
            {paymentStatus?.map((val, key) => {
              return (
                <MenuItem key={key} value={val}>
                  {<span className="font-change-style">{val}</span>}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TableContainer
          component={Paper}
          style={{
            marginTop: "20px",
          }}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell align="right">
                  {<span>S.No.</span>}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {<span>Email Id</span>}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {<span>Mobile No</span>}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {<span>Payment Date</span>}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData?.map((val, key) => {
                return <Row key={key} row={val} count={key + 1} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {apiState.showLoader && <BlueLoader />}
      </div>
    </>
  );
};

export default Transactions;
