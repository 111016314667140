import { initializeApp } from "firebase/app";
// Optionally import the services that you want to use
import { getAnalytics, logEvent } from "firebase/analytics";

import { getDatabase } from "firebase/database";
// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBMraYIiAJnWf5iPNOicgvUQ1OxTR5zKBM",
  authDomain: "edulabz-c0a8f.firebaseapp.com",
  projectId: "edulabz-c0a8f",
  storageBucket: "edulabz-c0a8f.appspot.com",
  messagingSenderId: "711487760543",
  appId: "1:711487760543:web:bbe0f8ce147970119bdaaa",
  measurementId: "G-V1G43TYZE6",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, "notification_received");

const database = getDatabase(app);
export { analytics, database };
