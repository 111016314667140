import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import filesTransferAnimation from "../../../assets/lottie/filesTransferAnimation.json";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useEffect } from "react";
import { useState } from "react";
import { ActionType } from "../../../redux/action/actionType";
import {
  makeNetworkCallForAddQuestions,
  makeNetworkCallForGetAllCourseForQuestionsUpload,
  makeNetworkCallForGetChapterBySectionIdForCopyQuestions,
  makeNetworkCallForGetSectionByCourseIdForCopyQuestions,
} from "../../../redux/action/action";
import { RequestType } from "../../../network/RequestType";
import { NetworkConstant } from "../../../network/NetworkConstant";
import Lottie from "react-lottie-player";
import "./CopyQuestionsAndUploadPopupForAll.css";
import CancelIcon from "@mui/icons-material/Cancel";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ShowSnackBar } from "../../../snackbar/SnackBar";
import { Constant } from "../../../constants/Constant";

const CopyQuestionsAndUploadPopupForAll = ({
  show,
  questionIdList,
  onCancelCopyQuestionsAndUploadPopup,
  onResponseOfCancelCopyQuestionsAndUploadPopup,
}) => {
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.apiStateReducer);
  const courseReducer = useSelector((state) => state.courseReducer);
  const [courseList, setCourseList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [chapterId, setChapterId] = useState("");
  const [loading, setLoading] = useState(false);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  //? handle response for get all course
  useEffect(() => {
    if (courseReducer.getAllCourseForCopyQuestions.statusCode === 200) {
      setCourseList(courseReducer.getAllCourseForCopyQuestions.responseData);
      dispatch({
        type: ActionType.RESET_GET_ALL_COURSE_FOR_COPY_QUESTIONS_RESPONSE,
        payload: {},
      });
    }
  }, [courseReducer.getAllCourseForCopyQuestions.statusCode]);

  //? handle response for get section by course id
  useEffect(() => {
    if (courseReducer.getSectionByCourseIdForCopyQuestions.statusCode === 200) {
      setSectionList(
        courseReducer.getSectionByCourseIdForCopyQuestions.responseData
      );
      dispatch({
        type: ActionType.RESET_GET_SECTION_BY_COURSE_ID_FOR_COPY_QUESTIONS,
        payload: {},
      });
    }
  }, [courseReducer.getSectionByCourseIdForCopyQuestions.statusCode]);

  //? handle response for get all chapter by section id
  useEffect(() => {
    if (
      courseReducer.getAllChapterBySectionIdForCopyQuestions.statusCode === 200
    ) {
      setChapterList(
        courseReducer.getAllChapterBySectionIdForCopyQuestions.responseData
      );
      dispatch({
        type: ActionType.RESET_GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_QUESTIONS,
        payload: {},
      });
    }
  }, [courseReducer.getAllChapterBySectionIdForCopyQuestions.statusCode]);

  //? handle response for add questions
  useEffect(() => {
    if (courseReducer.addQuestions.statusCode === 200) {
      setLoading(false);
      onResponseOfCancelCopyQuestionsAndUploadPopup(true);
      dispatch({
        type: ActionType.RESET_ADD_QUESTIONS,
        payload: {},
      });
    }
  }, [courseReducer.addQuestions.statusCode]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (show) {
      setCourseList([]);
      setCourseId("");
      setSectionList([]);
      setChapterList([]);
      setChapterId("");
      setSectionId("");
      setLoading(false);
      dispatch(
        makeNetworkCallForGetAllCourseForQuestionsUpload(
          0,
          RequestType.GET,
          NetworkConstant.getAllCourse,
          {},
          {}
        )
      );
    }
  }, [show]);

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  //? handle close popup
  const handleCloseCopyQuestionsPopup = () => {
    onCancelCopyQuestionsAndUploadPopup();
  };

  //? handle course change
  const handleChangeCourse = (event) => {
    setCourseId(event.target.value);
    setSectionId("");
    setChapterId("");
    setSectionList([]);
    setChapterList([]);
    dispatch(
      makeNetworkCallForGetSectionByCourseIdForCopyQuestions(
        event.target.value,
        RequestType.GET,
        NetworkConstant.getSectionByCourseId,
        {},
        {}
      )
    );
  };

  //? handle change section
  const handleChangeSection = (event) => {
    setSectionId(event.target.value);
    setChapterList([]);
    setChapterId("");
    dispatch(
      makeNetworkCallForGetChapterBySectionIdForCopyQuestions(
        RequestType.GET,
        NetworkConstant.getAllChapterBySectionId,
        {},
        {},
        event.target.value
      )
    );
  };

  //? handle change chapter
  const handleChangeChapter = (event) => {
    setChapterId(event.target.value);
  };

  //? handle upload button click
  const handleUploadClick = () => {
    if (courseId === "" || sectionId === "" || chapterId === "") {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Please select all field.",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: false,
        },
      });
    } else {
      setLoading(true);
      const addQuestionsRequest = {
        chapterId: chapterId,
        questionIds: questionIdList,
      };
      dispatch(
        makeNetworkCallForAddQuestions(
          RequestType.POST,
          NetworkConstant.addQuestions,
          addQuestionsRequest,
          {}
        )
      );
    }
  };

  return (
    <>
      <Dialog open={show}>
        <DialogTitle>
          <div
            className="copy-questions-and-upload-popup-cancel-icon"
            onClick={handleCloseCopyQuestionsPopup}
          >
            <CancelIcon />
          </div>
          <div className="copy-questions-and-upload-popup-container">
            <Lottie
              loop
              animationData={filesTransferAnimation}
              play
              style={{ width: 200, height: 200, alignSelf: "center" }}
            />
            <div className="copy-questions-and-upload-popup-message">
              <p>Are you sure you want to Copy ?</p>
            </div>
            <FormControl>
              <InputLabel>
                {<span className="font-change-style">Select Course</span>}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={courseId}
                label={
                  <span className="font-change-style">Select Section</span>
                }
                MenuProps={MenuProps}
                onChange={handleChangeCourse}
              >
                {courseList.map((val, key) => {
                  return (
                    <MenuItem value={val.courseId}>
                      {<span className="font-change-style">{val.title}</span>}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              style={{
                marginTop: "10px",
              }}
            >
              <InputLabel>
                {<span className="font-change-style">Select Section</span>}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={sectionId}
                label={
                  <span className="font-change-style">Select Section</span>
                }
                MenuProps={MenuProps}
                onChange={handleChangeSection}
              >
                {sectionList.map((val, key) => {
                  return (
                    <MenuItem value={val.sectionId}>
                      {
                        <span className="font-change-style">
                          {val.sectionName}
                        </span>
                      }
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              style={{
                marginTop: "10px",
              }}
            >
              <InputLabel>
                {<span className="font-change-style">Select Chapter</span>}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={chapterId}
                label={
                  <span className="font-change-style">Select Chapter</span>
                }
                MenuProps={MenuProps}
                onChange={handleChangeChapter}
              >
                {chapterList.map((val, key) => {
                  return (
                    <MenuItem value={val.chapterId}>
                      {<span className="font-change-style">{val.name}</span>}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </DialogTitle>
        <DialogContent>
          <Stack
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
            direction="row"
            spacing={1}
          >
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<ContentCopyIcon />}
              variant="contained"
              onClick={handleUploadClick}
            >
              Upload
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};

export { CopyQuestionsAndUploadPopupForAll };
