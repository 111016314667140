import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";
import { BlueLoader, inputPropsStyle } from "../../util/AppUtils";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
  makeNetworkCallCreateCourse,
  makeNetworkCallForDeleteCourseDurationPriceMapping,
  makeNetworkCallGetCourseById,
  makeNetworkCallUpdateCourse,
} from "../../redux/action/action";
import { RequestType } from "../../network/RequestType";
import { ActionType } from "../../redux/action/actionType";
import { NetworkConstant } from "../../network/NetworkConstant";
import {
  GetPriceList,
  checkUrlPattern,
  formatNumber,
  isNumber,
} from "../../util/CommonUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { ShowSnackBar } from "../../snackbar/SnackBar";
import { Constant } from "../../constants/Constant";
import Lottie from "react-lottie-player";
import fileAnimation from "../../assets/lottie/files_animation.json";

const ManageCourses = ({ courseId }) => {
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.apiStateReducer);
  const courseReducer = useSelector((state) => state.courseReducer);
  const [titleEditable, setTitleEditable] = useState(false);
  const [descriptionEditable, setDescriptionEditable] = useState(false);
  const [durationEditable, setDurationEditable] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [attachment, setAttachment] = useState("");
  const [localAttachment, setLocalAttachment] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [duration, setDuration] = useState("");
  const [courseDurationPriceList, setCourseDurationPriceList] = useState([]);
  const [tagOne, setTagOne] = useState("");
  const [tagTwo, setTagTwo] = useState("");
  const [tagOneList, setTagOneList] = useState([]);
  const [tagTwoList, setTagTwoList] = useState([]);
  const [errorTitlevisible, setErrorTitlevisible] = useState(false);
  const [errorDescriptionvisible, setErrorDescriptionvisible] = useState(false);
  const [errorThumbnailVisible, setErrorThumbnailVisible] = useState(false);
  const [errorAttachmentlVisible, setErrorAttachmentVisible] = useState(false);
  const [priceValue, setPriceValue] = useState("");
  const [showAddPricingPopup, setShowAddPricingPopup] = useState(false);
  const [showDurationError, setShowDurationError] = useState(false);
  const [showPriceError, setShowPriceError] = useState(false);
  const [durationValue, setDurationValue] = useState("");
  const thumbnailInputFile = useRef(null);
  const [thumbnailUploaded, setThumbnailUploaded] = useState(false);
  const [thumbnailFromUrl, setThumbnailFromUrl] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const handleDeletePrice = (deleteKey, courseDurationId) => {
    dispatch(
      makeNetworkCallForDeleteCourseDurationPriceMapping(
        RequestType.DELETE,
        NetworkConstant.deleteCourseDurationMapping,
        courseDurationId,
        {},
        {}
      )
    );
    const filterValue = courseDurationPriceList.filter(
      (item, key) => deleteKey !== key
    );
    setCourseDurationPriceList(filterValue);
  };

  useEffect(() => {
    if (courseReducer.deleteCourseDurationMapping.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_DELETE_COURSE_DURATION_MAPPING,
        payload: {},
      });
    }
  }, [courseReducer.deleteCourseDurationMapping.statusCode]);

  const handleShowAddPricingPopup = () => {
    setPriceValue("");
    setShowPriceError(false);
    setShowAddPricingPopup(true);
  };

  const handleChangePrice = (event) => {
    setPriceValue(event.target.value);
  };

  const handleChangeDuration = (event) => {
    setDurationValue(event.target.value);
  };

  const handleOnChangeFile = (event) => {
    if (
      event.target.files[0] !== undefined &&
      event.target.files[0] !== null &&
      event.target.files[0] !== ""
    ) {
      setLocalAttachment(event.target.files[0]);
    }
  };

  const handleOnChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleOnChangeCourseDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleOnChangeTagOne = (event) => {
    setTagOne(event.target.value);
  };

  const handleOnChangeTagTwo = (event) => {
    setTagTwo(event.target.value);
  };

  const handleOnChangeThumbnail = (event) => {
    if (
      event.target.files[0] !== undefined &&
      event.target.files[0] !== null &&
      event.target.files[0] !== ""
    )
      setThumbnail(event.target.files[0]);
    setThumbnailUploaded(true);
    setThumbnailFromUrl(false);
    // const imageSrc = URL.createObjectURL(event.target.files[0]);
    // const imagePreviewElement = document.querySelector(
    //   "#preview-selected-thumbnail"
    // );
    // imagePreviewElement.src = imageSrc;
  };

  const checkForValueOfPricePopUp = () => {
    let isError = false;
    if (priceValue === "") {
      setShowPriceError(true);
      isError = true;
    }
    if (durationValue === "") {
      setShowDurationError(true);
      isError = true;
    }

    if (priceValue !== "" && !isNumber(priceValue)) {
      setShowPriceError(true);
      isError = true;
    }
    return isError;
  };

  const handleDoneClick = () => {
    if (!checkForValueOfPricePopUp()) {
      setCourseDurationPriceList((previousData) => [
        ...previousData,
        {
          duration: durationValue,
          price: parseInt(priceValue),
        },
      ]);
      setShowAddPricingPopup(false);
    }
  };

  useEffect(() => {
    dispatch(
      makeNetworkCallGetCourseById(
        courseId,
        RequestType.GET,
        NetworkConstant.getCourseByCourseId,
        {},
        {}
      )
    );
    return () => {
      dispatch({
        type: ActionType.RESET_GET_COURSE_BY_ID,
        payload: {},
      });
    };
  }, []);

  useEffect(() => {
    if (courseReducer.updateCourse.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_UPDATE_COURSE_RESPONSE,
        payload: {},
      });
    }
  }, [courseReducer.updateCourse.statusCode]);

  function checkForAllValues() {
    let isValid = true;
    if (title === "") {
      setErrorTitlevisible(true);
      isValid = false;
    }
    if (description === "") {
      setErrorDescriptionvisible(true);
      isValid = false;
    }
    if (thumbnail === "") {
      setErrorThumbnailVisible(true);
      isValid = false;
    }
    if (attachment === "") {
      setErrorAttachmentVisible(true);
      isValid = false;
    }
    if (
      courseDurationPriceList === "" ||
      courseDurationPriceList.length === 0
    ) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Please add pricing.",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
      isValid = false;
    }

    return isValid;
  }

  const handleOnClickSave = (event) => {
    event.preventDefault();

    //!check for all values
    if (checkForAllValues()) {
      let courseRequestJson = {
        courseId: courseId,
        title: title,
        description: description,
        // userId: getDataStoreItem(DataStoreKey.USER_ID),
        // tag1: tagOneList,
        // tag2: tagTwoList,
        courseDurationPriceMapping: courseDurationPriceList,
      };
      let formData = new FormData();
      formData.append("thumbnail", thumbnail);
      if (localAttachment !== "" && localAttachment !== undefined) {
        formData.append("attachment", localAttachment);
      } else if (attachment !== null && attachment !== undefined) {
        formData.append("attachment", attachment);
      }
      formData.append("courseRequestJson", JSON.stringify(courseRequestJson));
      dispatch(
        makeNetworkCallUpdateCourse(
          RequestType.POST,
          NetworkConstant.updateCourse,
          formData,
          {}
        )
      );
    }
  };

  const handleDescriptionEditable = () => {
    setDescriptionEditable(true);
  };

  useEffect(() => {
    if (courseReducer.getCourseByIdResponse.statusCode === 200) {
      setTitle(courseReducer.getCourseByIdResponse.responseData[0].title);
      setDescription(
        courseReducer.getCourseByIdResponse.responseData[0].description
      );
      setDuration(courseReducer.getCourseByIdResponse.responseData[0].duration);
      setCourseDurationPriceList(
        courseReducer.getCourseByIdResponse.responseData[0]
          .courseDurationPriceResponse
      );
      setThumbnail(
        courseReducer.getCourseByIdResponse.responseData[0].thumbnail
      );
      setAttachment(
        courseReducer.getCourseByIdResponse.responseData[0].filePath
      );
      // courseReducer.getCourseByIdResponse.responseData[0].tagResponse.map(
      //   (val, key) => {
      //     if (val.tagType === "tag1") {
      //       setTagOneList((initialData) => [...initialData, val.tagValue]);
      //     } else {
      //       setTagTwoList((initialData) => [...initialData, val.tagValue]);
      //     }
      //   }
      // );
      setThumbnailUploaded(true);
      setThumbnailFromUrl(true);
    }
  }, [courseReducer.getCourseByIdResponse.statusCode]);

  // const handleDeleteOfChip = (tagName, deletedKey) => {
  //   if (tagName === "TAG1") {
  //     const filteredItem = tagOneList.filter((item, key) => key !== deletedKey);
  //     setTagOneList(filteredItem);
  //   } else {
  //     const filteredItem = tagTwoList.filter((item, key) => key !== deletedKey);
  //     setTagTwoList(filteredItem);
  //   }
  // };

  // const handleKeyboardEnter = (event, tagName) => {
  //   if (event.key == "Enter") {
  //     if (tagName === "TAG1") {
  //       setTagOneList((initialData) => [...initialData, tagOne]);
  //       setTagOne("");
  //     } else {
  //       setTagTwoList((initialData) => [...initialData, tagTwo]);
  //       setTagTwo("");
  //     }
  //   }
  // };

  const handleTitleEditable = () => {
    setTitleEditable(true);
  };

  const handleDurationEditable = () => {
    setDurationEditable(true);
  };

  function checkForAllValues() {
    let isValid = true;
    if (title === "") {
      setErrorTitlevisible(true);
      isValid = false;
    }
    if (description === "") {
      setErrorDescriptionvisible(true);
      isValid = false;
    }
    if (thumbnail === "") {
      setErrorThumbnailVisible(true);
      isValid = false;
    }
    if (attachment === "") {
      setErrorAttachmentVisible(true);
      isValid = false;
    }
    if (
      courseDurationPriceList === "" ||
      courseDurationPriceList.length === 0
    ) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Please add pricing.",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
      isValid = false;
    }

    return isValid;
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  //? convert days into month and year

  const convertDaysInMonthOrYear = (days) => {
    var convertedValue = "";
    if (days < 32) {
      convertedValue = days + " " + Constant.DAYS;
    } else if (days % 384 === 0) {
      convertedValue = days / 384 + " " + Constant.YEAR;
    } else {
      convertedValue = days / 32 + " " + Constant.MONTH;
    }
    return convertedValue;
  };


  return (
    <>
      <div className="form-create-course">
        <h3 className="heading">Course Title</h3>

        <TextField
          disabled={!titleEditable}
          error={false}
          inputProps={inputPropsStyle.inputProp}
          id="outlined-error-helper-text"
          label={<span>Title</span>}
          rows={20}
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton edge="end" onClick={handleTitleEditable}>
                <EditIcon />
              </IconButton>
            ),
          }}
        />
        <h3 className="heading">Course Description</h3>
        <TextField
          disabled={!descriptionEditable}
          error={false}
          id="outlined-multiline-static"
          label={<span>Description</span>}
          inputProps={inputPropsStyle.inputProp}
          multiline
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          rows={5}
          InputProps={{
            endAdornment: (
              <IconButton edge="end" onClick={handleDescriptionEditable}>
                <EditIcon />
              </IconButton>
            ),
          }}
        />

        {/* <h3 className="heading">Tag 1</h3> */}
        {/* <Stack direction="row" spacing={1}>
          {tagOneList.map((val, key) => {
            return (
              <Chip
                key={key}
                label={val}
                onDelete={() => handleDeleteOfChip("TAG1", key)}
              />
            );
          })}
        </Stack> */}
        {/* <TextField
          style={{
            marginTop: "2%",
          }}
          id="outlined-error-helper-text"
          label="Enter Tag 1"
          onChange={handleOnChangeTagOne}
          value={tagOne}
          onKeyDown={(event) => handleKeyboardEnter(event, "TAG1")}
        /> */}
        {/* <h3 className="heading">Tag 2</h3> */}
        {/* <Stack direction="row" spacing={1}>
          {tagTwoList.map((val, key) => {
            return (
              <Chip
                key={key}
                label={val}
                onDelete={() => handleDeleteOfChip("TAG2", key)}
              />
            );
          })}
        </Stack> */}
        {/* <TextField
          style={{
            marginTop: "2%",
          }}
          id="outlined-error-helper-text"
          label="Enter Tag 2"
          onChange={handleOnChangeTagTwo}
          value={tagTwo}
          onKeyDown={(event) => handleKeyboardEnter(event, "TAG2")}
        /> */}

        {/* //?add pricing list ui */}

        <div className="price-item-container">
          {courseDurationPriceList.map((val, key) => {
            return (
              <div className={"price-item-" + ((key + 1) % 4)}>
                <h3 className="heading-title">Price</h3>
                <p className="value-title">Rs {formatNumber(val.price)}</p>
                <h3 className="heading-title">Duration</h3>
                <p className="value-title">
                  {convertDaysInMonthOrYear(val.duration)}
                </p>
                <div className="delete-icon-box">
                  <Tooltip title="Delete" arrow>
                    <Fab
                      size="small"
                      className="button-delete"
                      color="error"
                      aria-label="delete"
                      onClick={() =>
                        handleDeletePrice(key, val.courseDurationId)
                      }
                    >
                      <DeleteIcon />
                    </Fab>
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>

        {/* //? Add Pricing button */}

        <Fab
          onClick={handleShowAddPricingPopup}
          style={{
            width: "20%",
            height: "40px",
            borderRadius: "5px",
            marginTop: "2%",
            alignSelf: "flex-end",
            backgroundColor: "#ffbd03",
            fontFamily: "FuturaCondensed",
            textAlignment: "center",
            letterSpacing: "1px",
          }}
        >
          <AddIcon
            style={{
              marginRight: "5px",
            }}
          />
          Add Pricing
        </Fab>
        {thumbnailUploaded && (
          <div className="thumbnail-container">
            <img
              id="preview-selected-thumbnail"
              className={
                thumbnailUploaded ? "thumbnail-preview" : "initial-thumbnail"
              }
              src={
                thumbnailFromUrl ? thumbnail : URL.createObjectURL(thumbnail)
              }
            />
          </div>
        )}
        <button
          className="upload-thumbnail-button"
          onClick={() => thumbnailInputFile.current.click()}
        >
          Upload Thumbnail
        </button>
        <input
          hidden
          className="file-thumbnail"
          type="file"
          onChange={handleOnChangeThumbnail}
          ref={thumbnailInputFile}
        />
        <div className="attachment-container">
          {localAttachment !== "" ? (
            <a
              href={URL.createObjectURL(localAttachment)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Lottie
                loop
                animationData={fileAnimation}
                play
                style={{ width: 200, height: 150 }}
              />
            </a>
          ) : (
            attachment !== "" && (
              <a href={attachment} target="_blank" rel="noopener noreferrer">
                <Lottie
                  loop
                  animationData={fileAnimation}
                  play
                  style={{ width: 200, height: 150 }}
                />
              </a>
            )
          )}
          <span>Course Attachment</span>
        </div>
        <input
          className="file-attachment"
          type="file"
          onChange={handleOnChangeFile}
        />

        <button className="button-save" onClick={handleOnClickSave}>
          Save
        </button>
      </div>

      {showAddPricingPopup && (
        <Dialog open={showAddPricingPopup}>
          <DialogTitle
            style={{
              height: "auto",
              width: "500px",
              display: "flex",
              flexDirection: "column",
              paddingTop: "30px",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            <p className="add-pricing-title">Select Duration</p>
            <FormControl
              style={{
                width: "100%",
                alignSelf: "center",
              }}
            >
              <InputLabel>Duration (Days)</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={durationValue}
                label="Duration (Days)"
                MenuProps={MenuProps}
                onChange={handleChangeDuration}
              >
                {GetPriceList().map((val, key) => {
                  var showValueOnUi = "";
                  if (key === 0) {
                    showValueOnUi = val + " " + Constant.DAYS;
                  } else if (key === 1) {
                    showValueOnUi = val + " " + Constant.DAYS;
                  } else if (key === 2) {
                    showValueOnUi = val + " " + Constant.DAYS;
                  } else if (val % 384 === 0) {
                    showValueOnUi = val / 384 + " " + Constant.YEAR;
                  } else {
                    showValueOnUi = val / 32 + " " + Constant.MONTH;
                  }
                  return <MenuItem value={val}>{showValueOnUi}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <p className="add-pricing-title-add-price">Add Price</p>
            <TextField
              style={{
                width: "100%",
                alignSelf: "center",
              }}
              id="outlined-error-helper-text"
              label={<span>Price</span>}
              inputProps={inputPropsStyle.inputProp}
              onChange={handleChangePrice}
              value={priceValue}
              error={showPriceError}
              helperText={showPriceError ? "Please enter price in number" : ""}
            />
          </DialogTitle>
          <DialogContent>
            <div className="button-container-add-price">
              <button
                className="button-style-cancel-add-price"
                onClick={() => {
                  return setShowAddPricingPopup(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button-style-done-add-price"
                onClick={handleDoneClick}
              >
                Done
              </button>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {apiState.showLoader && <BlueLoader />}
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={
          apiState.showSnackBar ||
          errorThumbnailVisible ||
          errorAttachmentlVisible
        }
        message={
          errorThumbnailVisible || errorAttachmentlVisible
            ? apiState.snackbarMessage
            : apiState.snackBarMessage
        }
      />
    </>
  );
};

export default ManageCourses;
