import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import { useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import {
  makeNetworkCallDeleteQuestionImageById,
  makeNetworkCallUploadQuestionImage,
  makeNetworkCallUploadQuestionImageForEditQuestion,
} from "../../../../../redux/action/action";
import { RequestType } from "../../../../../network/RequestType";
import { NetworkConstant } from "../../../../../network/NetworkConstant";
import { ShowSnackBar } from "../../../../../snackbar/SnackBar";
import { ActionType } from "../../../../../redux/action/actionType";

import { useEffect } from "react";

const EditQuestionUploadFilesPopup = ({
  show,
  questionImageList,
  optionOneImageList,
  optionTwoImageList,
  optionThreeImageList,
  optionFourImageList,
  descriptionImageList,
  onCancelImageUploadClick,
  onUploadImageResponse,
}) => {
  const questionImageReference = useRef(null);
  const optionOneImageReference = useRef(null);
  const optionTwoImageReference = useRef(null);
  const optionThreeImageReference = useRef(null);
  const optionFourImageReference = useRef(null);
  const descriptionImageReference = useRef(null);
  const [questionImageListFromApi, setQuestionImageListFromApi] = useState([]);
  const [optionOneImageListFromApi, setOptionOneImageListFromApi] = useState(
    []
  );
  const [optionTwoImageListFromApi, setOptionTwoImageListFromApi] = useState(
    []
  );
  const [optionThreeImageListFromApi, setOptionThreeImageListFromApi] =
    useState([]);
  const [optionFourImageListFromApi, setOptionFourImageListFromApi] = useState(
    []
  );
  const [descriptionImageListFromApi, setDescriptionImageListFromApi] =
    useState([]);
  const [questionImage, setQuestionImage] = useState([]);
  const [optionOneImage, setOptionOneImage] = useState([]);
  const [optionTwoImage, setOptionTwoImage] = useState([]);
  const [optionThreeImage, setOptionThreeImage] = useState([]);
  const [optionFourImage, setOptionFourImage] = useState([]);
  const [descriptionImage, setDescriptionImage] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [deleteQuestionImageKey, setDeleteQuestionImageKey] = useState("");
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.apiStateReducer);
  const courseReducer = useSelector((state) => state.courseReducer);

  //? handle snackbar visibility

  useEffect(() => {
    if (apiState.showSnackBar) {
      setShowLoading(false);
      setTimeout(() => {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: false,
            snackBarMessage: "",
            snackBarSeverity: "",
            apiError: false,
          },
        });
      }, 5000);
    }
  }, [apiState.showSnackBar]);

  useEffect(() => {
    if (show) {
      setQuestionImageListFromApi([]);
      setOptionOneImageListFromApi([]);
      setOptionTwoImageListFromApi([]);
      setOptionThreeImageListFromApi([]);
      setOptionFourImageListFromApi([]);
      setDescriptionImageListFromApi([]);
      setQuestionImageListFromApi(questionImageList);
      setOptionOneImageListFromApi(optionOneImageList);
      setOptionTwoImageListFromApi(optionTwoImageList);
      setOptionThreeImageListFromApi(optionThreeImageList);
      setOptionFourImageListFromApi(optionFourImageList);
      setDescriptionImageListFromApi(descriptionImageList);
    }
  }, [show]);

  //? handle api response

  useEffect(() => {
    if (courseReducer.editQuestionImage.statusCode === 200) {
      setShowLoading(false);
      clearAllData();
      onUploadImageResponse(courseReducer.editQuestionImage.responseData);
    }
  }, [courseReducer.editQuestionImage.statusCode]);

  //? handle response of delete question image by id
  useEffect(() => {
    if (courseReducer.deleteQuestionImageById?.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_DELETE_QUESTION_IMAGE_BY_ID,
        payload: {},
      });

      const filteredQuestionImageListFromApi = questionImageListFromApi.filter(
        (val, key) => {
          return deleteQuestionImageKey !== val.id;
        }
      );
      setQuestionImageListFromApi(filteredQuestionImageListFromApi);

      const filteredOptionOneImageListFromApi =
        optionOneImageListFromApi.filter((val, key) => {
          return deleteQuestionImageKey !== val.id;
        });
      setOptionOneImageListFromApi(filteredOptionOneImageListFromApi);

      const filteredOptionTwoImageListFromApi =
        optionTwoImageListFromApi.filter((val, key) => {
          return deleteQuestionImageKey !== val.id;
        });
      setOptionTwoImageListFromApi(filteredOptionTwoImageListFromApi);

      const filteredOptionThreeImageListFromApi =
        optionThreeImageListFromApi.filter((val, key) => {
          return deleteQuestionImageKey !== val.id;
        });
      setOptionThreeImageListFromApi(filteredOptionThreeImageListFromApi);

      const filteredOptionFourImageListFromApi =
        optionFourImageListFromApi.filter((val, key) => {
          return deleteQuestionImageKey !== val.id;
        });
      setOptionFourImageListFromApi(filteredOptionFourImageListFromApi);

      const filteredDescriptionImageListFromApi =
        descriptionImageListFromApi.filter((val, key) => {
          return deleteQuestionImageKey !== val.id;
        });
      setDescriptionImageListFromApi(filteredDescriptionImageListFromApi);

      setDeleteQuestionImageKey("");
    }
  }, [courseReducer.deleteQuestionImageById?.statusCode]);

  //? handle question image
  const handleQuestionImage = (event) => {
    setQuestionImage((previousData) => [
      ...previousData,
      event.target.files[0],
    ]);
  };

  //? handle option one image
  const handleOptionOneImage = (event) => {
    setOptionOneImage((previousData) => [event.target.files[0]]);
  };

  //? handle option two image
  const handleOptionTwoImage = (event) => {
    setOptionTwoImage((previousData) => [event.target.files[0]]);
  };

  //? handle option three image
  const handleOptionThreeImage = (event) => {
    setOptionThreeImage((previousData) => [event.target.files[0]]);
  };

  //? handle option four image
  const handleOptionFourImage = (event) => {
    setOptionFourImage((previousData) => [event.target.files[0]]);
  };

  //? handle description image
  const handleDescriptionImage = (event) => {
    setDescriptionImage((previousData) => [
      ...previousData,
      event.target.files[0],
    ]);
  };

  //? method for question image delete
  const handleQuestionImageDelete = (deleteKey) => {
    const filteredQuestionImage = questionImage.filter((val, key) => {
      return deleteKey !== key;
    });
    setQuestionImage(filteredQuestionImage);
  };

  //? method for option one image delete
  const handleOptionOneImageDelete = (deleteKey) => {
    const filteredOptionOneImage = optionOneImage.filter((val, key) => {
      return deleteKey !== key;
    });
    setOptionOneImage(filteredOptionOneImage);
  };

  //? method for option two image delete
  const handleOptionTwoImageDelete = (deleteKey) => {
    const filteredOptionTwoImage = optionTwoImage.filter((val, key) => {
      return deleteKey !== key;
    });
    setOptionTwoImage(filteredOptionTwoImage);
  };

  //? method for option three image delete
  const handleOptionThreeImageDelete = (deleteKey) => {
    const filteredOptionThreeImage = optionThreeImage.filter((val, key) => {
      return deleteKey !== key;
    });
    setOptionThreeImage(filteredOptionThreeImage);
  };

  //? method for option four image delete
  const handleOptionFourImageDelete = (deleteKey) => {
    const filteredOptionFourImage = optionFourImage.filter((val, key) => {
      return deleteKey !== key;
    });
    setOptionFourImage(filteredOptionFourImage);
  };

  //? method for description image delete
  const handleDescriptionImageDelete = (deleteKey) => {
    const filteredDescriptionImage = descriptionImage.filter((val, key) => {
      return deleteKey !== key;
    });
    setDescriptionImage(filteredDescriptionImage);
  };

  //? handle cancel click of popup method
  const handleCancelClick = () => {
    clearAllData();
    onCancelImageUploadClick();
  };

  //? clear all data

  const clearAllData = () => {
    setQuestionImage([]);
    setOptionOneImage([]);
    setOptionTwoImage([]);
    setOptionThreeImage([]);
    setOptionFourImage([]);
    setDescriptionImage([]);
  };

  //? handle delete images
  const handleDeleteImagesFromServer = (event, key) => {
    event.stopPropagation();
    setDeleteQuestionImageKey(key);
    dispatch(
      makeNetworkCallDeleteQuestionImageById(
        RequestType.DELETE,
        NetworkConstant.deleteQuestionImageById,
        key,
        {},
        {}
      )
    );
  };

  //? handle upload click of image
  const handleUploadClick = () => {
    if (
      questionImage.length === 0 &&
      optionOneImage.length === 0 &&
      optionTwoImage.length === 0 &&
      optionThreeImage.length === 0 &&
      optionFourImage.length === 0 &&
      descriptionImage.length === 0
    ) {
    } else {
      let formData = new FormData();
      if (questionImage.length !== 0) {
        for (var i = 0; i < questionImage.length; i++) {
          formData.append("questionImage", questionImage[i]);
        }
      }
      if (optionOneImage.length !== 0) {
        for (var i = 0; i < optionOneImage.length; i++) {
          formData.append("option1Image", optionOneImage[i]);
        }
      }
      if (optionTwoImage.length !== 0) {
        for (var i = 0; i < optionTwoImage.length; i++) {
          formData.append("option2Image", optionTwoImage[i]);
        }
      }
      if (optionThreeImage.length !== 0) {
        for (var i = 0; i < optionThreeImage.length; i++) {
          formData.append("option3Image", optionThreeImage[i]);
        }
      }
      if (optionFourImage.length !== 0) {
        for (var i = 0; i < optionFourImage.length; i++) {
          formData.append("option4Image", optionFourImage[i]);
        }
      }
      if (descriptionImage.length !== 0) {
        for (var i = 0; i < descriptionImage.length; i++) {
          formData.append("descriptionImage", descriptionImage[i]);
        }
      }
      setShowLoading(true);
      dispatch(
        makeNetworkCallUploadQuestionImageForEditQuestion(
          RequestType.POST,
          NetworkConstant.questionImage,
          formData,
          {}
        )
      );
    }
  };

  return (
    <>
      <Dialog open={show}>
        <DialogTitle className="uploadFilesForQuestionPopupContainer">
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={handleQuestionImage}
            ref={questionImageReference}
          />
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={handleOptionOneImage}
            ref={optionOneImageReference}
          />
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={handleOptionTwoImage}
            ref={optionTwoImageReference}
          />
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={handleOptionThreeImage}
            ref={optionThreeImageReference}
          />
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={handleOptionFourImage}
            ref={optionFourImageReference}
          />
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={handleDescriptionImage}
            ref={descriptionImageReference}
          />
        </DialogTitle>
        <DialogContent>
          <div className="file-type-container">
            <div className="upload-file-type-container">
              {questionImageListFromApi.map((val, key) => {
                return (
                  <a
                    key={key}
                    className="image-item"
                    href={val.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <span className="text-style-image-url">
                        Uploaded Question image : {key + 1}
                      </span>
                      <IconButton
                        aria-label="delete"
                        onClick={(event) =>
                          handleDeleteImagesFromServer(event, val.id)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </a>
                );
              })}
              {questionImage.map((val, key) => {
                return (
                  <div className="file-item" key={key}>
                    <span>{val.name}</span>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleQuestionImageDelete(key)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );
              })}

              <Tooltip
                title="Question Image"
                arrow
                className="tooltip-button-container"
              >
                <Fab
                  className="button-focus"
                  color="primary"
                  size="small"
                  aria-label="question image"
                  onClick={() => {
                    return questionImageReference.current.click();
                  }}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </div>
            <div className="upload-file-type-container">
              {optionOneImageListFromApi.map((val, key) => {
                return (
                  <a
                    key={key}
                    className="image-item"
                    href={val.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <span className="text-style-image-url">
                        Uploaded Option One image : {key + 1}
                      </span>
                      <IconButton
                        aria-label="delete"
                        onClick={(event) =>
                          handleDeleteImagesFromServer(event, val.id)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </a>
                );
              })}
              {optionOneImage.map((val, key) => {
                return (
                  <div className="file-item" key={key}>
                    <span>{val.name}</span>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleOptionOneImageDelete(key)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );
              })}

              <Tooltip
                title="Option One Image"
                arrow
                className="tooltip-button-container"
              >
                <Fab
                  className="button-focus"
                  color="primary"
                  size="small"
                  aria-label="option one image"
                  onClick={() => {
                    return optionOneImageReference.current.click();
                  }}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </div>
            <div className="upload-file-type-container">
              {optionTwoImageListFromApi.map((val, key) => {
                return (
                  <a
                    key={key}
                    className="image-item"
                    href={val.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <span className="text-style-image-url">
                        Uploaded option two image : {key + 1}
                      </span>
                      <IconButton
                        aria-label="delete"
                        onClick={(event) =>
                          handleDeleteImagesFromServer(event, val.id)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </a>
                );
              })}
              {optionTwoImage.map((val, key) => {
                return (
                  <div className="file-item" key={key}>
                    <span>{val.name}</span>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleOptionTwoImageDelete(key)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );
              })}

              <Tooltip
                title="Option Two Image"
                arrow
                className="tooltip-button-container"
              >
                <Fab
                  className="button-focus"
                  color="primary"
                  size="small"
                  aria-label="option two image"
                  onClick={() => {
                    return optionTwoImageReference.current.click();
                  }}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </div>
            <div className="upload-file-type-container">
              {optionThreeImageListFromApi.map((val, key) => {
                return (
                  <a
                    key={key}
                    className="image-item"
                    href={val.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <span className="text-style-image-url">
                        Uploaded option three image : {key + 1}
                      </span>
                      <IconButton
                        aria-label="delete"
                        onClick={(event) =>
                          handleDeleteImagesFromServer(event, val.id)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </a>
                );
              })}
              {optionThreeImage.map((val, key) => {
                return (
                  <div className="file-item" key={key}>
                    <span>{val.name}</span>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleOptionThreeImageDelete(key)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );
              })}

              <Tooltip
                title="Option Three Image"
                arrow
                className="tooltip-button-container"
              >
                <Fab
                  className="button-focus"
                  color="primary"
                  size="small"
                  aria-label="option three image"
                  onClick={() => {
                    return optionThreeImageReference.current.click();
                  }}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </div>
            <div className="upload-file-type-container">
              {optionFourImageListFromApi.map((val, key) => {
                return (
                  <a
                    key={key}
                    className="image-item"
                    href={val.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <span className="text-style-image-url">
                        Uploaded option four image : {key + 1}
                      </span>
                      <IconButton
                        aria-label="delete"
                        onClick={(event) =>
                          handleDeleteImagesFromServer(event, val.id)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </a>
                );
              })}
              {optionFourImage.map((val, key) => {
                return (
                  <div className="file-item" key={key}>
                    <span>{val.name}</span>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleOptionFourImageDelete(key)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );
              })}

              <Tooltip
                title="Option Four Image"
                arrow
                className="tooltip-button-container"
              >
                <Fab
                  className="button-focus"
                  color="primary"
                  size="small"
                  aria-label="option four image"
                  onClick={() => {
                    return optionFourImageReference.current.click();
                  }}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </div>
            <div className="upload-file-type-container">
              {descriptionImageListFromApi.map((val, key) => {
                return (
                  <a
                    key={key}
                    className="image-item"
                    href={val.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <span className="text-style-image-url">
                        Uploaded description image : {key + 1}
                      </span>
                      <IconButton
                        aria-label="delete"
                        onClick={(event) =>
                          handleDeleteImagesFromServer(event, val.id)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </a>
                );
              })}
              {descriptionImage.map((val, key) => {
                return (
                  <div className="file-item" key={key}>
                    <span>{val.name}</span>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDescriptionImageDelete(key)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );
              })}

              <Tooltip
                title="Description Image"
                arrow
                className="tooltip-button-container"
              >
                <Fab
                  className="button-focus"
                  color="primary"
                  size="small"
                  aria-label="description image"
                  onClick={() => {
                    return descriptionImageReference.current.click();
                  }}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </div>
            <div className="upload-files-for-question-popup-button-container">
              <LoadingButton
                style={{
                  marginRight: "10px",
                }}
                onClick={handleCancelClick}
                loadingPosition="end"
                variant="contained"
              >
                <span>Cancel</span>
              </LoadingButton>
              <LoadingButton
                onClick={handleUploadClick}
                endIcon={<SendIcon />}
                loading={showLoading}
                loadingPosition="end"
                variant="contained"
              >
                <span>Upload</span>
              </LoadingButton>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};

export { EditQuestionUploadFilesPopup };
