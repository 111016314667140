import { TextField } from "@mui/material";
import "./EmailForAllUsers.css";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { RequestType } from "../../network/RequestType";
import { NetworkConstant } from "../../network/NetworkConstant";
import { useEffect } from "react";
import { ActionType } from "../../redux/action/actionType";
import { ShowSnackBar } from "../../snackbar/SnackBar";
import { Constant } from "../../constants/Constant";
import {
  makeNetworkCallForGetEmailForAllUser,
  makeNetworkCallForSendEmailToAllUser,
  makeNetworkCallGetAllPublishedCourse,
  makeNetworkCallToSendEmailToAllUser,
} from "../../redux/action/NotificationAction";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import IconButton from "@mui/material/IconButton";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  getParsedCurrentTime,
  parseDateIntoWeekDaysDateMonthYear,
} from "../../util/CommonUtils";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

export const EmailForAllUsers = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [showTitleError, setShowTitleError] = useState(false);
  const [description, setDescription] = useState("");
  const [showDescriptionError, setShowDescriptionError] = useState(false);
  //   const [date, setDate] = useState(new Date());
  const [date, setDate] = useState("");
  const [showDateError, setShowDateError] = useState(false);
  //   const [time, setTime] = useState(getParsedCurrentTime());
  const [time, setTime] = useState("");
  const [showTimeError, setShowTimeError] = useState(false);
  const [meetLink, setMeetLink] = useState("");
  const [showMeetLinkError, setShowMeetLinkError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [courseResponse, setCourseResponse] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [sendToAllUser, setSendToAllUser] = useState(false);
  const notificationState = useSelector((state) => state.notificationReducer);
  const apiState = useSelector((state) => state.apiStateReducer);
  const [courseId, setCourseId] = useState("");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const [emailBatchIndex, setEmailBatchIndex] = useState(0);

  useEffect(() => {
    dispatch(
      makeNetworkCallGetAllPublishedCourse(
        RequestType.GET,
        NetworkConstant.getAllCourse,
        {},
        {}
      )
    );

    return () => {
      dispatch({
        type: ActionType.RESET_GET_ALL_PUBLISHED_COURSE,
        payload: {},
      });
      dispatch({
        type: ActionType.RESET_GET_ALL_EMAIL,
        payload: {},
      });
    };
  }, []);

  //? iOS type switch
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  //? handle send click for email to all user
  const handleSendClick = () => {
    if (isValid()) {
      setShowLoading(true);
      let endPoint = "";
      // if (sendToAllUser) {
      //   endPoint = `${NetworkConstant.getEnrolledUserEmail}?isAllUser=${sendToAllUser}&isEnrolleduser=true`;
      // } else {
      //   endPoint = `${NetworkConstant.getEnrolledUserEmail}?courseId=${courseId}&isAllUser=${sendToAllUser}&isEnrolleduser=true`;
      // }
      // dispatch(
      //   makeNetworkCallForGetEmailForAllUser(RequestType.POST, endPoint, {}, {})
      // );

      if (sendToAllUser) {
        endPoint = `${NetworkConstant.notification}?isAllUser=${sendToAllUser}`;
      } else {
        endPoint = `${NetworkConstant.notification}?courseId=${courseId}&isAllUser=${sendToAllUser}`;
      }

      if (title !== "" && title !== undefined) {
        endPoint += `&subject=${title}`;
      }
      if (description !== "" && description !== undefined) {
        endPoint += `&description=${description}`;
      }
      if (date) {
        endPoint += `&classDate=${parseDateIntoWeekDaysDateMonthYear(date)}`;
      }
      if (time) {
        endPoint += `&classTime=${time}`;
      }
      if (meetLink) {
        endPoint += `&classLink=${meetLink}`;
      }

      // This API call is the updated with updated URL.
      dispatch(
        makeNetworkCallForSendEmailToAllUser(RequestType.POST, endPoint, {}, {})
      );
    }
  };

  //? handle change course
  const handleChangeCourse = (event) => {
    setCourseId(event.target.value);
  };

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: { showSnackBar: false, snackBarMessage: "" },
      });
    }, 5000);
  }

  useEffect(() => {
    if (title !== "" && title !== undefined) {
      setShowTitleError(false);
    }
    if (description !== "" && description !== undefined) {
      setShowDescriptionError(false);
    }
    if (date !== "" && date !== undefined) {
      setShowDateError(false);
    }
    if (time !== "" && time !== undefined) {
      setShowTimeError(false);
    }
    if (meetLink !== "" && meetLink !== undefined) {
      setShowMeetLinkError(false);
    }
  }, [title, description, date, time, meetLink]);

  useEffect(() => {
    if (notificationState?.getAllPublishedCourse?.statusCode === 200) {
      setCourseResponse(notificationState?.getAllPublishedCourse?.responseData);
    }
  }, [notificationState?.getAllPublishedCourse?.statusCode]);

  useEffect(() => {
    if (notificationState?.sendEmailForAllUser?.statusCode === 200) {
      setShowLoading(false);
      dispatch({
        type: ActionType.RESET_SEND_EMAIL_TO_ALL_USER,
        payload: {},
      });
    }
  }, [notificationState?.sendEmailForAllUser?.statusCode]);

  useEffect(() => {
    if (notificationState?.sendEmailToUser?.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_SEND_EMAIL_TO_USER,
        payload: {},
      });
      console.log("data is : ", notificationState?.getAllEmail?.responseData);
      if (
        getEmailBatchSubArray(notificationState?.getAllEmail?.responseData)
          .length > emailBatchIndex
      ) {
        sendEmailToUsersByEmail();
        console.log("comes here in if");
      } else {
        setShowLoading(false);
        console.log("comes here in else");
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: Constant.EMAIL_SENT_SUCCESSFULLY,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
          },
        });
        dispatch({
          type: ActionType.RESET_GET_ALL_EMAIL,
          payload: {},
        });
        setEmailBatchIndex(0);
      }
    }
  }, [notificationState?.sendEmailToUser?.statusCode]);

  // function to get the email batch of size 20 in subarray
  const getEmailBatchSubArray = (listOfEmail) => {
    const subarrayLength = 50;
    const numSubarrays = Math.ceil(listOfEmail.length / subarrayLength);
    const subarrays = Array.from({ length: numSubarrays }, (_, index) =>
      listOfEmail.slice(index * subarrayLength, (index + 1) * subarrayLength)
    );
    return subarrays;
  };

  // function to call api for send email
  const sendEmailToUsersByEmail = () => {
    const emailSubArray = getEmailBatchSubArray(
      notificationState?.getAllEmail?.responseData
    );
    console.log("This is user's email ", emailSubArray);
    const emailBody = {
      subject: title,
      classTopic: description,
      recepientAddress: emailSubArray[emailBatchIndex],
    };
    if (date) {
      emailBody["classDate"] = parseDateIntoWeekDaysDateMonthYear(date);
    }
    if (time) {
      emailBody["classTime"] = time;
    }
    if (meetLink) {
      emailBody["classLink"] = meetLink;
    }
    setEmailBatchIndex(emailBatchIndex + 1);
    dispatch(makeNetworkCallToSendEmailToAllUser(emailBody, {}));
  };

  useEffect(() => {
    if (notificationState?.getAllEmail?.statusCode === 200) {
      sendEmailToUsersByEmail();
      // const emailBody = {
      //   subject: title,
      //   classTopic: description,
      //   recepientAddress: notificationState?.getAllEmail?.responseData
      // };

      // if (date) {
      //   emailBody["classDate"] = parseDateIntoWeekDaysDateMonthYear(date);
      // }
      // if (time) {
      //   emailBody["classTime"] = time;
      // }
      // if (meetLink) {
      //   emailBody["classLink"] = meetLink;
      // }

      // dispatch(makeNetworkCallToSendEmailToAllUser(emailBody, {}));
    }
  }, [notificationState?.getAllEmail?.statusCode]);

  const isValid = () => {
    var isAllValid = true;
    if (title === undefined || title === "") {
      setShowTitleError(true);
      isAllValid = false;
    }
    if (description === undefined || description === "") {
      setShowDescriptionError(true);
      isAllValid = false;
    }
    // if (date === "" || date === undefined) {
    //   setShowDateError(true);
    //   isAllValid = false;
    // }
    // if (time === "" || time === undefined) {
    //   setShowTimeError(true);
    //   isAllValid = false;
    // }
    // if (meetLink === "" || meetLink === undefined) {
    //   setShowMeetLinkError(true);
    //   isAllValid = false;
    // }
    if (sendToAllUser === false && courseId === "") {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: Constant.PLEASE_SELECT_COURSE,
          snackBarSeverity: Constant.SEVERITY_ERROR,
        },
      });
      isAllValid = false;
    }
    return isAllValid;
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //? style for input props

  const inputPropsStyle = {
    inputProp: {
      style: { fontFamily: Constant.FUTURA_CONDENSED },
    },
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel
          style={{
            marginLeft: "5%",
          }}
          onChange={() => {
            setSendToAllUser(!sendToAllUser);
          }}
          checked={sendToAllUser}
          control={<IOSSwitch sx={{ m: 1 }} />}
          label={<span>Send to all user</span>}
        />
      </FormGroup>
      <FormControl
        style={{
          marginBottom: 20,
          width: "90%",
          marginLeft: "5%",
          marginRight: "5%",
        }}
        disabled={sendToAllUser}
      >
        <InputLabel>
          {<span className="font-change-style">Select Course</span>}
        </InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={courseId}
          label={<span className="font-change-style">Select Section</span>}
          MenuProps={MenuProps}
          onChange={handleChangeCourse}
        >
          {courseResponse.map((val, key) => {
            return (
              <MenuItem value={val.courseId} key={key}>
                {<span className="font-change-style">{val.title}</span>}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <div className="email-area">
        <TextField
          className="text-area"
          id="outlined-error-helper-text"
          error={showTitleError}
          helperText={showTitleError ? <span>Please enter subject</span> : ""}
          onChange={(event) => setTitle(event.target.value)}
          inputProps={inputPropsStyle.inputProp}
          value={title}
          label={<span className="font-change-style">Subject</span>}
        />
        <TextField
          style={{
            marginTop: "20px",
          }}
          className="text-area"
          id="outlined-error-helper-text"
          multiline
          inputProps={inputPropsStyle.inputProp}
          error={showDescriptionError}
          helperText={
            showDescriptionError ? <span>Please enter description</span> : ""
          }
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          label={<span className="font-change-style">Description</span>}
          rows={10}
        />
        <TextField
          style={{
            marginTop: "20px",
          }}
          disabled={true}
          className="text-area"
          id="outlined-error-helper-text"
          error={showDateError}
          helperText={showDateError ? <span>Please choose Date</span> : ""}
          onChange={(event) => setDate(event.target.value)}
          inputProps={inputPropsStyle.inputProp}
          value={
            date !== "" && date !== undefined
              ? parseDateIntoWeekDaysDateMonthYear(date)
              : ""
          }
          label={<span className="font-change-style">Choose Date</span>}
          InputProps={{
            endAdornment: (
              <IconButton
                edge="end"
                onClick={() => setShowCalendar(!showCalendar)}
              >
                <CalendarMonthIcon />
              </IconButton>
            ),
          }}
        />
        {showCalendar && <Calendar value={date} onChange={setDate} />}
        <TextField
          style={{
            marginTop: "20px",
          }}
          disabled={true}
          className="text-area"
          id="outlined-error-helper-text"
          error={showTimeError}
          helperText={showTimeError ? <span>Please select Time</span> : ""}
          onChange={(event) => setTime(event.target.value)}
          inputProps={inputPropsStyle.inputProp}
          value={time}
          label={<span className="font-change-style">Select Time</span>}
          InputProps={{
            endAdornment: (
              <IconButton
                edge="end"
                onClick={() => setShowTimePicker(!showTimePicker)}
              >
                <AccessAlarmIcon />
              </IconButton>
            ),
          }}
        />
        {showTimePicker && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["StaticTimePicker"]}>
              <DemoItem>
                <StaticTimePicker
                  value={time}
                  onChange={(newValue) => {
                    const date = new Date(newValue);
                    const hours24 = date.getHours();
                    const minutes = date.getMinutes();
                    let hours12 = hours24;
                    const amOrPm = hours12 >= 12 ? " PM" : " AM";
                    if (hours12 > 12) {
                      hours12 -= 12;
                    }
                    const formattedTime = `${hours12}:${minutes
                      .toString()
                      .padStart(2, "0")}${amOrPm}`;
                    setTime(formattedTime);
                  }}
                  defaultValue={dayjs("2022-04-17T15:30")}
                  onAccept={() => setShowTimePicker(false)}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        )}
        <TextField
          style={{
            marginTop: "20px",
          }}
          className="text-area"
          id="outlined-error-helper-text"
          error={showMeetLinkError}
          helperText={
            showMeetLinkError ? <span>Please enter meeting link</span> : ""
          }
          onChange={(event) => setMeetLink(event.target.value)}
          inputProps={inputPropsStyle.inputProp}
          value={meetLink}
          label={<span className="font-change-style">Enter meet link</span>}
        />

        <Stack
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
          direction="row"
          spacing={1}
        >
          <LoadingButton
            loading={showLoading}
            loadingPosition="start"
            startIcon={<SendIcon />}
            variant="contained"
            onClick={handleSendClick}
          >
            <span>Send</span>
          </LoadingButton>
        </Stack>
      </div>
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};
