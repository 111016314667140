import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import "./ManageCarousel.css";
import { DialogTitle, DialogContent, Button } from "@mui/material";
import { TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useEffect, useRef, useState } from "react";
import { ShowSnackBar } from "../../snackbar/SnackBar";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "../../redux/action/actionType";
import {
  makeNetworkCallCreateCarousel,
  makeNetworkCallDeleteCarousel,
  makeNetworkCallGetAllCarousel,
} from "../../redux/action/action";
import { RequestType } from "../../network/RequestType";
import { NetworkConstant } from "../../network/NetworkConstant";
import LoadingButton from "@mui/lab/LoadingButton";
import PreviewIcon from "@mui/icons-material/Preview";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CancelIcon from "@mui/icons-material/Cancel";

export const ManageCarousel = () => {
  const [showCreateCarousel, setShowCreateCarousel] = useState(false);
  const [titleErrorVisibleCarousel, setTitleErrorVisibleCarousel] =
    useState(false);
  const [descriptionErrorVisibleCarousel, setDescriptionErrorVisibleCarousel] =
    useState(false);
  const [titleCarousel, setTitleCarousel] = useState("");
  const [descriptionCarousel, setDescriptionCarousel] = useState("");
  const [imageUploaded, setImageUploaded] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showCarouselPreview, setShowCarouselPreview] = useState(false);
  const imageUpload = useRef(null);
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.apiStateReducer);
  const manageAppState = useSelector((state) => state.manageAppReducer);

  const handleAddCarousel = () => {
    setShowCreateCarousel(true);
  };

  const handleOnChangeTitleCreateCarousel = (event) => {
    setTitleCarousel(event.target.value);
  };

  const handleOnChangeDescriptionCarousel = (event) => {
    setDescriptionCarousel(event.target.value);
  };

  useEffect(() => {
    if (manageAppState.createCarousel.statusCode === 200) {
      setShowLoading(false);
      dispatch({
        type: ActionType.RESET_CREATE_CAROUSEL,
        payload: {},
      });
      handleCancelCarousel();
      dispatch({
        type: ActionType.RESET_GET_ALL_CAROUSEL,
        payload: {},
      });
      dispatch(
        makeNetworkCallGetAllCarousel(
          RequestType.GET,
          NetworkConstant.getAllCarousel,
          {},
          {}
        )
      );
    }
  }, [manageAppState.createCarousel.statusCode]);

  useEffect(() => {
    if (manageAppState.deleteCarousel.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_DELETE_CAROUSEL,
        payload: {},
      });
      dispatch({
        type: ActionType.RESET_GET_ALL_CAROUSEL,
        payload: {},
      });
      dispatch(
        makeNetworkCallGetAllCarousel(
          RequestType.GET,
          NetworkConstant.getAllCarousel,
          {},
          {}
        )
      );
    }
  }, [manageAppState.deleteCarousel.statusCode]);

  useEffect(() => {
    dispatch(
      makeNetworkCallGetAllCarousel(
        RequestType.GET,
        NetworkConstant.getAllCarousel,
        {},
        {}
      )
    );

    return () => {
      dispatch({
        type: ActionType.RESET_GET_ALL_CAROUSEL,
        payload: {},
      });
    };
  }, []);

  useEffect(() => {
    if (apiState.apiError) {
      setShowLoading(false);
    }
  }, [apiState.apiError]);

  const handleOnChangeImageUpload = (event) => {
    setImageUploaded(event.target.files[0]);
    const imageSrc = URL.createObjectURL(event.target.files[0]);
    const imagePreviewElement = document.querySelector("#image-upload-preview");
    imagePreviewElement.src = imageSrc;
  };

  const handleCancelCarousel = () => {
    setImageUploaded("");
    setTitleCarousel("");
    setDescriptionCarousel("");
    setTitleErrorVisibleCarousel(false);
    setDescriptionErrorVisibleCarousel(false);
    setImageUploaded("");
    setShowCreateCarousel(false);
  };

  const handleDeleteClick = (carouselId) => {
    dispatch(
      makeNetworkCallDeleteCarousel(
        RequestType.DELETE,
        NetworkConstant.deleteCarousel,
        carouselId,
        {},
        {}
      )
    );
  };

  const handleClosePreview = () => {
    setShowCarouselPreview(false)
  }

  const handleSaveCarousel = () => {
    if (isAllDataFilled()) {
      setShowLoading(true);
      let formData = new FormData();
      formData.append("thumbnail", imageUploaded);
      const endpoint = `${NetworkConstant.createCarousel}?title=${titleCarousel}&description=${descriptionCarousel}`;
      dispatch(
        makeNetworkCallCreateCarousel(RequestType.POST, endpoint, formData, {})
      );
    }
  };

  const handleShowPreview = () => {
    setShowCarouselPreview(true);
  };

  const isAllDataFilled = () => {
    var isValid = true;
    if (
      titleCarousel == null ||
      titleCarousel === "" ||
      titleCarousel == undefined
    ) {
      setTitleErrorVisibleCarousel(true);
      isValid = false;
    }
    if (
      descriptionCarousel == null ||
      descriptionCarousel === "" ||
      descriptionCarousel == undefined
    ) {
      setDescriptionErrorVisibleCarousel(true);
      isValid = false;
    }
    if (
      imageUploaded == "" ||
      imageUploaded == null ||
      imageUploaded == undefined
    ) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Please upload image",
          snackBarSeverity: "error",
          apiError: false,
        },
      });
      isValid = false;
    }
    return isValid;
  };

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  const customStyles = {
    content: {
      width: "60%",
      height: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "auto",
      marginBottom: "auto",
      borderRadius: "10px",
      border: "none",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      display: "flex",
      zIndex: "999",
      flexDirection: "column",
      paddingLeft: "5%",
      paddingRight: "5%",
    },
    input: {
      border: "none",
      outline: "none",
    },
  };

  return (
    <>
      <div className="carousel-list-container">
        {manageAppState.getAllCarousel.responseData.map((val, key) => {
          return (
            <div className="carousel-list" key={key}>
              <img
                className="image-container"
                src={val.thumbnail}
              />
              <div className="carousel-title-description">
                <h3>{val.title}</h3>
                <p>{val.description}</p>
              </div>
              <div className="delete-icon-box">
                <Tooltip title="Delete" arrow>
                  <Fab
                    size="small"
                    className="button-delete"
                    color="error"
                    aria-label="delete"
                    onClick={() => handleDeleteClick(val.carouselId)}
                  >
                    <DeleteIcon />
                  </Fab>
                </Tooltip>
              </div>
            </div>
          );
        })}
      </div>

      <div>
        {/* //?dialog for preview carousel */}

        <Dialog open={showCarouselPreview} style={customStyles}>
          <DialogTitle>
            <div className="close-icon" onClick={handleClosePreview}>
              <CancelIcon />
            </div>
            <Carousel
              showArrows={true}
              useKeyboardArrows={true}
              autoPlay={true}
              infiniteLoop={true}
              showIndicators={true}
              showThumbs={false}
            >
              {manageAppState.getAllCarousel.responseData.map((val, key) => {
                return (
                  <div>
                    <img
                      className="carousel-image-preview-container"
                      src={val.thumbnail}
                    />
                    <div>
                      <h3>{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </DialogTitle>
          <DialogContent></DialogContent>
        </Dialog>

        {/* //?dialog for create carousel */}

        <Dialog style={customStyles} open={showCreateCarousel}>
          <DialogTitle className="create-carousel-dialog">
            <TextField
              error={titleErrorVisibleCarousel}
              id="outlined-error-helper-text"
              label="Title"
              style={{
                marginTop: "5%",
              }}
              helperText={titleErrorVisibleCarousel ? "Please enter title" : ""}
              onChange={handleOnChangeTitleCreateCarousel}
              value={titleCarousel}
            />
            <TextField
              error={descriptionErrorVisibleCarousel}
              id="outlined-error-helper-text"
              label="Description"
              multiline
              rows={5}
              style={{
                marginTop: "5%",
              }}
              helperText={
                descriptionErrorVisibleCarousel
                  ? "Please enter description"
                  : ""
              }
              onChange={handleOnChangeDescriptionCarousel}
              value={descriptionCarousel}
            />
            <div className="add-image">
              <img
                id="image-upload-preview"
                src={require("../../assets/image/thumbnail-background.png")}
                height={100}
                width={100}
                style={{
                  borderRadius: "10px",
                }}
              />

              <Fab
                onClick={() => imageUpload.current.click()}
                style={{
                  width: "20%",
                  height: "40px",
                  borderRadius: "5px",
                  marginTop: "3%",
                  backgroundColor: "#ffbd03",
                  alignSelf: "center",
                  marginLeft: "10px",
                  fontFamily: "FuturaCondensed",
                  textAlignment: "center",
                  letterSpacing: "1px",
                }}
              >
                <AddIcon
                  style={{
                    marginRight: "5px",
                  }}
                />
                Add Image
              </Fab>
              <input
                hidden
                className="file-thumbnail"
                type="file"
                onChange={handleOnChangeImageUpload}
                ref={imageUpload}
              />
            </div>
          </DialogTitle>
          <DialogContent className="carousel-dialog-content">
            <Button variant="contained" onClick={handleCancelCarousel}>
              Cancel
            </Button>

            <LoadingButton
              style={{
                marginLeft: "10px",
              }}
              loading={showLoading}
              loadingPosition="start"
              startIcon={<CloudUploadIcon />}
              variant="contained"
              onClick={handleSaveCarousel}
            >
              Create
            </LoadingButton>
          </DialogContent>
        </Dialog>
        <div className="manage-carousel-button-container">
          <Tooltip title="Preview" arrow>
            <Fab
              style={{
                marginRight: "10px",
                backgroundColor: "#733de4",
              }}
              aria-label="preview"
              onClick={handleShowPreview}
              className="button-focus"
              size="xx-large"
            >
              <PreviewIcon
                style={{
                  color: "white",
                }}
              />
            </Fab>
          </Tooltip>
          <Tooltip title="Add" arrow>
            <Fab
              style={{
                backgroundColor: "#733de4",
              }}
              aria-label="add"
              onClick={handleAddCarousel}
              className="button-focus"
              size="xx-large"
            >
              <AddIcon
                style={{
                  color: "white",
                }}
              />
            </Fab>
          </Tooltip>
        </div>
      </div>
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};
