import { combineReducers } from "redux";
import { ApiStateReducer } from "./ApiStateReducer";
import { CourseReducer } from "./CourseReducer";
import { SideNavigationReducer } from "./SideNavigationReducer";
import { UserReducer } from "./UserReducer";
import { NotificationReducer } from "./NotificationReducer";
import { ManageAppReducer } from "./ManageAppReducer";
import { EnrollmentReducer } from "./EnrollmentReducer";
import { PaymentReducer } from "./PaymentReducer";
import { SettingsReducer } from "./SettingsReducer.";

const appReducer = combineReducers({
  userReducer: UserReducer,
  sideNavigationReducer: SideNavigationReducer,
  apiStateReducer: ApiStateReducer,
  courseReducer: CourseReducer,
  notificationReducer: NotificationReducer,
  manageAppReducer: ManageAppReducer,
  enrollmentReducer: EnrollmentReducer,
  paymentReducer: PaymentReducer,
  settingsReducer: SettingsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
