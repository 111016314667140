import { ActionType } from "../action/actionType";

const initialState = {
  getAllUser: {
    statusCode: 0,
    responseData: [],
  },
};

export const EnrollmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_ALL_USER: {
      return {
        ...state,
        getAllUser: {
          statusCode: action.payload.getAllUser.statusCode,
          responseData: [...action.payload.getAllUser.responseData],
        },
      };
    }
    case ActionType.RESET_GET_ALL_USER: {
      return {
        ...state,
        getAllUser: {
          statusCode: 0,
          responseData: [],
        },
      };
    }
    default:
      return state;
  }
};
