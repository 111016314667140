import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NetworkConstant } from "../../network/NetworkConstant";
import { RequestType } from "../../network/RequestType";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  createQuestionRequest,
  makeNetworkCallDeleteQuestionById,
  makeNetworkCallDeleteQuestionByIdFromHere,
  makeNetworkCallDeleteTypeById,
  makeNetworkCallForCreateQuestionMapping,
  makeNetworkCallForGetChapterById,
  makeNetworkCallForGetChapterBySectionId,
  makeNetworkCallForGetQuestionsByChapterId,
  makeNetworkCallForGetSectionByCourseId,
  makeNetworkCallMapDurationWithVideo,
  makeNetworkCallMapOrderWithQuestion,
  makeNetworkCallUploadFilesByChapterId,
} from "../../redux/action/action";
import "./ManageChapters.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import { TextField } from "@mui/material";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Alert, Snackbar } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ActionType } from "../../redux/action/actionType";
import { styled } from "@mui/material/styles";
import {
  BlueLoader,
  inputPropsStyle,
  validateEmail,
} from "../../util/AppUtils";
import LinearProgress from "@mui/material/LinearProgress";
import planeLoading from "../../assets/lottie/plane_loading.json";
import doneAnimation from "../../assets/lottie/done_animation.json";
import Lottie from "react-lottie-player";
import ReactPlayer from "react-player";
import filesAnimation from "../../assets/lottie/files_animation.json";
import CancelIcon from "@mui/icons-material/Cancel";
import PreviewIcon from "@mui/icons-material/Preview";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  DataStoreKey,
  getDataStoreItem,
  setDataStoreItem,
} from "../../persistence/PersistenceStorage";
import { DeleteChapterPopup } from "./chapter-popup/delete-chapter-popup/DeleteChapterPopup";
import { EditChapterPopup } from "./chapter-popup/edit-chapter-popup/EditChapterPopup";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { UploadFilesForQuestionPopup } from "./chapter-popup/upload-files-for-question-popup/UploadFilesForQuestionPopup";
import { Constant } from "../../constants/Constant";
import { PreviewFilesForQuestionPopup } from "./chapter-popup/preview-files-for-question-popup/PreviewFilesForQuestionPopup";
import { EditQuestionPopup } from "./chapter-popup/EditQuestionPopup/EditQuestionPopup";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SaveIcon from "@mui/icons-material/Save";
import { CopyFilesAndUploadPopup } from "./chapter-popup/copy-files-and-upload/CopyFilesAndUploadPopup";
import { CopyQuestionsAndUploadPopup } from "./chapter-popup/copy-questions-and-upload/CopyQuestionsAndUploadPopup";
import { CopyChapterPopup } from "./chapter-popup/copy-chapter-popup/CopyChapterPopup";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { SendResultPopup } from "./chapter-popup/send-result-popup/SendResultPopup";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useNavigate } from "react-router-dom";

const ManageChapters = ({ sectionId, courseId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const courseReducer = useSelector((state) => state.courseReducer);
  const apiState = useSelector((state) => state.apiStateReducer);
  const [showAddQuestionPopup, setShowAddQuestionPopup] = useState(false);
  const [questionTitle, setQuestionTitle] = useState("");
  const [order, setOrder] = useState("");
  const [optionOne, setOptionOne] = useState("");
  const [optionTwo, setOptionTwo] = useState("");
  const [optionThree, setOptionThree] = useState("");
  const [optionFour, setOptionFour] = useState("");
  const [errorQuestionTitle, setErrorQuestionTitle] = useState(false);
  const [errorOptionOne, setErrorOptionOne] = useState(false);
  const [errorOptionTwo, setErrorOptionTwo] = useState(false);
  const [errorOptionThree, setErrorOptionThree] = useState(false);
  const [errorOptionFour, setErrorOptionFour] = useState(false);
  const [isCorrectOptionOne, setIsCorrectOptionOne] = useState(false);
  const [isCorrectOptionTwo, setIsCorrectOptionTwo] = useState(false);
  const [isCorrectOptionThree, setIsCorrectOptionThree] = useState(false);
  const [isCorrectOptionFour, setIsCorrectOptionFour] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [sectionValue, setSectionValue] = useState("");
  const [correctAnswerDescription, setCorrectAnswerDescription] = useState("");
  const [errorCorrectAnswerDescription, setErrorCorrectAnswerDescription] =
    useState(false);
  const [errorOrderNumber, setErrorOrderNumber] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [chapterId, setChapterId] = useState("");
  const [showUploadFilesPopup, setShowUploadFilesPopup] = useState(false);
  const [showUploadProgressBarPopup, setShowUploadProgressBarPopup] =
    useState(false);
  const addVideoReference = useRef(null);
  const addFileReference = useRef(null);
  const [addedVideoList, setAddedVideoList] = useState([]);
  const [addedFilesList, setAddedFilesList] = useState([]);
  const [uploadedPercent, setUploadedPercent] = useState(0);
  const [showChapterDetails, setShowChapterDetails] = useState(false);
  const [deleteFileType, setDeleteFileType] = useState("");
  const [deleteFileId, setDeleteFileId] = useState("");
  const [fileResponseList, setFileResponseList] = useState([]);
  const [videoResponseList, setVideoResponseList] = useState([]);
  const [questionResponseList, setQuestionResponseList] = useState([]);
  const [showFilesPreviewPopup, setShowFilePreviewPopup] = useState(false);
  const [isForQuestionsPreview, setIsForQuestionsPreview] = useState(false);
  const [isForFilesPreview, setIsForFilesPreview] = useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [chapterIdForQuestion, setChapterIdForQuestion] = useState("");
  const [questionImageResponseList, setQuestionImageResponseList] = useState(
    []
  );
  const questionsRef = useRef(null);
  const [questionScrollPosition, setQuestionScrollPosition] = useState(0);

  const [
    previewFilesForQuestionPopupProps,
    setPreviewFilesForQuestionPopupProps,
  ] = useState({
    show: false,
    questionData: {},
  });
  const [deleteChapterPopupProps, setDeleteChapterPopupProps] = useState({
    show: false,
    sectionId: "",
    chapterId: "",
    chapterName: "",
  });
  const [sendResultPopupProps, setSendResultPopupProps] = useState({
    show: false,
    chapterId: "",
    chapterName: "",
  });
  const [uploadFileForQuestionPopupProps, setUploadFilesForQuestionPopupProps] =
    useState({
      show: false,
    });
  const [editChapterPopupProps, setEditChapterPopupProps] = useState({
    show: false,
    titleProps: "",
    descriptionProps: "",
    requiredTrophiesProps: "",
    imageUrlProps: "",
    chapterId: "",
    sectionId: "",
  });
  const [editQuestionPopupProps, setEditQuestionPopupProps] = useState({
    show: false,
    questionId: "",
  });
  const [copyFilesAndUploadPopupProps, setCopyFilesAndUploadPopupProps] =
    useState({
      show: false,
      fileId: "",
      fileName: "",
    });
  const [copyChaptersAndUploadPopupProps, setCopyChaptersAndUploadPopupProps] =
    useState({
      show: false,
      chapterId: "",
      chapterName: "",
      copyCourseId: "",
    });
  const [
    copyQuestionsAndUploadPopupProps,
    setCopyQuestionsAndUploadPopupProps,
  ] = useState({
    show: false,
    questionIdList: [],
    copyQuestionCourseId: "",
  });
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const questionOrderChangeArray = [];

  let config = {
    onUploadProgress: (data) => {
      setUploadedPercent(Math.round((data.loaded / data.total) * 100));
    },
  };

  //? handle close add question popup

  const handleCloseAddQuestionPopup = () => {
    setQuestionImageResponseList([]);
    setShowAddQuestionPopup(false);
  };

  //? handle upload files for question popup

  const handleOnClickUploadQuestionFiles = () => {
    setUploadFilesForQuestionPopupProps({
      show: true,
      onCancelClick: () => setUploadFilesForQuestionPopupProps({ show: false }),
      onResponse: (response) => {
        setUploadFilesForQuestionPopupProps({ show: false });
        setQuestionImageResponseList(response);
        dispatch({
          type: ActionType.RESET_QUESTION_IMAGE,
          payload: {},
        });
      },
    });
  };

  // handle change order of questions
  const handleChangeOrderOfQuestions = (index, event) => {
    const newInputValues = [...questionResponseList];
    newInputValues[index].order = event.target.value;
    setQuestionResponseList(newInputValues);
    console.log("request is : : ", questionResponseList);
  };

  // handle save changes for question
  const handleSaveChangesForQuestions = () => {
    const mapOrderWithQuestionRequest = [];
    questionResponseList.map((questionValue, key) => {
      if (questionValue.order !== null && questionValue.order !== undefined) {
        const request = {
          questionId: questionValue.questionId,
          order: questionValue.order,
        };
        mapOrderWithQuestionRequest.push(request);
      }
    });
    dispatch(
      makeNetworkCallMapOrderWithQuestion(
        chapterId,
        NetworkConstant.mapOrderWithQuestion,
        mapOrderWithQuestionRequest,
        {}
      )
    );
  };

  // handle callback of api request when map order with question get successful.
  useEffect(() => {
    if (courseReducer.mapOrderWithQuestion.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_MAP_ORDER_WITH_QUESTION,
        payload: {},
      });
      setShowChapterDetails(false);
    }
  }, [courseReducer.mapOrderWithQuestion.statusCode]);

  //? handle preview files click for popup
  const handlePreviewFilesClickForQuestion = (questionData) => {
    setPreviewFilesForQuestionPopupProps({
      show: true,
      questionData: questionData,
      onCancelClick: () =>
        setPreviewFilesForQuestionPopupProps({ show: false }),
    });
  };

  //? handle edit chapter popup

  const handleEditChapterPopup = (
    event,
    chapterId,
    title,
    description,
    requiredTrophies,
    imageUrl,
    sectionId
  ) => {
    event.stopPropagation();
    setEditChapterPopupProps({
      show: true,
      titleProps: title,
      descriptionProps: description,
      requiredTrophiesProps: requiredTrophies,
      imageUrlProps: imageUrl,
      chapterId: chapterId,
      sectionId: sectionId,
      onCancelClick: () => setEditChapterPopupProps({ show: false }),
      onResponseUpdateChapter: (response) => {
        setEditChapterPopupProps({ show: false });
        dispatch({
          type: ActionType.RESET_GET_ALL_CHAPTER_BY_SECTION_ID,
          payload: {},
        });
        dispatch(
          makeNetworkCallForGetChapterBySectionId(
            RequestType.GET,
            NetworkConstant.getAllChapterBySectionId,
            {},
            {},
            sectionId
          )
        );
      },
    });
  };

  //? handle copy files click
  const handleCopyFilesClick = (event, fileId, fileName) => {
    setCopyFilesAndUploadPopupProps({
      show: true,
      fileId: fileId,
      fileName: fileName,
      onCancelCopyFilesAndUploadPopup: () =>
        setCopyFilesAndUploadPopupProps({
          show: false,
          fileId: "",
          fileName: "",
        }),
      onResponseOfCancelCopyFilesAndUploadPopup: (response) =>
        setCopyFilesAndUploadPopupProps({
          show: false,
          fileId: "",
          fileName: "",
        }),
    });
  };

  //? handle copy chapter click
  const handleCopyChapterClick = (event, chapterId, chapterName) => {
    event.stopPropagation();
    setCopyChaptersAndUploadPopupProps({
      show: true,
      chapterId: chapterId,
      chapterName: chapterName,
      copyCourseId: courseId,
      onCancelCopyChapterPopup: () =>
        setCopyChaptersAndUploadPopupProps({
          show: false,
          chapterId: "",
          chapterName: "",
          copyCourseId: courseId,
        }),
      onResponseCopyChapterPopup: (response) =>
        setCopyChaptersAndUploadPopupProps({
          show: false,
          chapterId: "",
          chapterName: "",
          copyCourseId: courseId,
        }),
    });
  };

  //? handle copy questions click
  const handleCopyQuestionsClick = (event, questionId) => {
    let questionIdList = [];
    questionIdList.push(questionId);
    setCopyQuestionsAndUploadPopupProps({
      show: true,
      questionIdList: questionIdList,
      copyQuestionCourseId: courseId,
      onCancelCopyQuestionsAndUploadPopup: () =>
        setCopyQuestionsAndUploadPopupProps({
          show: false,
          questionIdList: [],
          copyQuestionCourseId: "",
        }),
      onResponseOfCancelCopyQuestionsAndUploadPopup: (response) =>
        setCopyQuestionsAndUploadPopupProps({
          show: false,
          questionIdList: [],
          copyQuestionCourseId: "",
        }),
    });
  };

  //? handle copy questions click for bulk upload
  const handleCopyQuestionsClickForEntireQuestions = () => {
    let questionIdList = [];
    for (var i = 0; i < questionResponseList.length; i++) {
      questionIdList.push(questionResponseList[i].questionId);
    }
    setCopyQuestionsAndUploadPopupProps({
      show: true,
      questionIdList: questionIdList,
      copyQuestionCourseId: courseId,
      onCancelCopyQuestionsAndUploadPopup: () =>
        setCopyQuestionsAndUploadPopupProps({
          show: false,
          questionIdList: [],
          copyQuestionCourseId: "",
        }),
      onResponseOfCancelCopyQuestionsAndUploadPopup: (response) =>
        setCopyQuestionsAndUploadPopupProps({
          show: false,
          questionIdList: [],
          copyQuestionCourseId: "",
        }),
    });
  };

  //? handle delete chapter popup props
  const handleDeleteChapterPopup = (
    event,
    chapterId,
    chapterName,
    sectionId
  ) => {
    event.stopPropagation();
    setDeleteChapterPopupProps({
      show: true,
      sectionId: sectionId,
      chapterId: chapterId,
      chapterName: chapterName,
      onCancelClick: () => setDeleteChapterPopupProps({ show: false }),
      onResponse: (response) => {
        setDeleteChapterPopupProps({ show: false });
        dispatch({
          type: ActionType.RESET_GET_ALL_CHAPTER_BY_SECTION_ID,
          payload: {},
        });
        dispatch(
          makeNetworkCallForGetChapterBySectionId(
            RequestType.GET,
            NetworkConstant.getAllChapterBySectionId,
            {},
            {},
            sectionId
          )
        );
      },
    });
  };

  // handle send result popup
  const handleSendResultPopup = (event, chapterId, chapterName) => {
    event.stopPropagation();
    setSendResultPopupProps({
      show: true,
      chapterId: chapterId,
      chapterName: chapterName,
      onCancelClick: () => setSendResultPopupProps({ show: false }),
      onResponse: (response) => {
        setSendResultPopupProps({ show: false });
      },
    });
  };

  // handle quick add question
  const handleQuickAddQuestion = (event, chapterId, chapterName) => {
    event.stopPropagation();
    const dataToSend = {
      chapterId: chapterId,
      chapterName: chapterName,
    };
    navigate("/quickaddquestion", { state: dataToSend });
  };

  const handleSaveFileResponseList = () => {
    let mapDurationWIthVideoRequest = [];
    let isVideoAvailable = false;
    for (var i = 0; i < fileResponseList.length; i++) {
      isVideoAvailable = true;
      const jsonRequest = {
        id: fileResponseList[i].id,
        duration: "",
        order: (i + 1).toString(),
      };
      mapDurationWIthVideoRequest.push(jsonRequest);
    }
    dispatch(
      makeNetworkCallMapDurationWithVideo(
        RequestType.POST,
        NetworkConstant.mapDurationWithVideo,
        mapDurationWIthVideoRequest,
        {}
      )
    );
  };

  const handleDeleteFilePdfOrImageOrVideo = (deleteId) => {
    dispatch(
      makeNetworkCallDeleteTypeById(
        RequestType.DELETE,
        NetworkConstant.deleteTypeById,
        deleteId,
        {},
        {}
      )
    );
  };

  const handleDeleteQuestion = (questionId) => {
    dispatch(
      makeNetworkCallDeleteQuestionById(
        RequestType.DELETE,
        NetworkConstant.deleteQuestionById,
        questionId,
        {},
        {}
      )
    );
  };

  const handleDeleteQuestionFromHere = (questionId) => {
    dispatch(
      makeNetworkCallDeleteQuestionByIdFromHere(
        RequestType.DELETE,
        NetworkConstant.deleteChapterQuestionMapping,
        questionId,
        chapterIdForQuestion,
        {},
        {}
      )
    );
  };

  //? handle edit question
  const handleEditQuestion = (questionData) => {
    setEditQuestionPopupProps({
      show: true,
      questionData: questionData,
      onCancelClick: () => setEditQuestionPopupProps({ show: false }),
      onUpdateQuestionResponse: (response) => {
        setEditQuestionPopupProps({ show: false });
        setQuestionResponseList([]);
        dispatch(
          makeNetworkCallForGetQuestionsByChapterId(
            RequestType.GET,
            NetworkConstant.getQuestionsByChapterId,
            chapterId,
            {},
            {}
          )
        );
      },
    });
  };

  //? handle delete question by id response

  useEffect(() => {
    if (courseReducer.deleteQuestionById.statusCode === 200) {
      setQuestionResponseList([]);
      dispatch({
        type: ActionType.RESET_DELETE_QUESTION_BY_ID,
        payload: {},
      });
      dispatch(
        makeNetworkCallForGetQuestionsByChapterId(
          RequestType.GET,
          NetworkConstant.getQuestionsByChapterId,
          chapterId,
          {},
          {}
        )
      );
    }
  }, [courseReducer.deleteQuestionById.statusCode]);

  //? handle delete question by id response

  useEffect(() => {
    if (courseReducer.deleteQuestionByIdFromHere.statusCode === 200) {
      setQuestionResponseList([]);
      dispatch({
        type: ActionType.RESET_DELETE_QUESTION_BY_ID_FROM_HERE,
        payload: {},
      });
      dispatch(
        makeNetworkCallForGetQuestionsByChapterId(
          RequestType.GET,
          NetworkConstant.getQuestionsByChapterId,
          chapterId,
          {},
          {}
        )
      );
    }
  }, [courseReducer.deleteQuestionByIdFromHere.statusCode]);

  //? handle sort of video by drag and drop

  const handleSort = () => {
    let sortedFileResponseList = [...fileResponseList];
    const draggedItemContent = sortedFileResponseList.splice(
      dragItem.current,
      1
    )[0];
    sortedFileResponseList.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setFileResponseList(sortedFileResponseList);
  };

  const handleFileDelete = () => {
    let filteredFileList = fileResponseList.filter(
      (item, key) => deleteFileId !== item.id
    );
    setDeleteFileId("");
    setFileResponseList(filteredFileList);
  };

  useEffect(() => {
    if (courseReducer.deleteTypeById.statusCode === 200) {
      handleFileDelete();
      setDeleteFileType("");
      dispatch({
        type: ActionType.RESET_DELETE_TYPE_BY_ID,
        payload: {},
      });
    }
  }, [courseReducer.deleteTypeById.statusCode]);

  const handleCloseChapterDetails = () => {
    dispatch({
      type: ActionType.RESET_GET_CHAPTER_BY_ID,
      payload: {},
    });
    dispatch({
      type: ActionType.RESET_GET_QUESTIONS_BY_CHAPTER_ID,
      payload: {},
    });
    dispatch({
      type: ActionType.RESET_DELETE_QUESTION_BY_ID,
      payload: {},
    });
    setQuestionResponseList([]);
    setIsForFilesPreview(false);
    setIsForQuestionsPreview(false);
    setShowChapterDetails(false);
  };

  const handleOnClickUpload = () => {
    let formData = new FormData();

    for (let i = 0; i < addedVideoList.length; i++) {
      formData.append("video", addedVideoList[i]);
    }

    for (let i = 0; i < addedFilesList.length; i++) {
      formData.append("files", addedFilesList[i]);
    }

    formData.append("chapterId", chapterId);

    setShowUploadFilesPopup(false);
    setShowUploadProgressBarPopup(true);
    //?dispatch api hit action
    dispatch(
      makeNetworkCallUploadFilesByChapterId(
        RequestType.POST,
        NetworkConstant.uploadFilesByChapterId,
        formData,
        config
      )
    );
  };

  const handleOnChangeAddVideo = (event) => {
    setAddedVideoList((previousData) => [
      ...previousData,
      event.target.files[0],
    ]);
  };

  const handleDeleteVideo = (deleteKey) => {
    const filteredList = addedVideoList.filter((val, key) => {
      return deleteKey !== key;
    });
    setAddedVideoList(filteredList);
  };

  const handleOnChangeAddFiles = (event) => {
    setAddedFilesList((previousData) => [
      ...previousData,
      event.target.files[0],
    ]);
  };

  const handleDeleteFile = (deleteKey) => {
    const filteredList = addedFilesList.filter((val, key) => {
      return deleteKey !== key;
    });
    setAddedFilesList(filteredList);
  };

  // const handleClickOnChapter = (chapterId) => {
  //   setChapterId(chapterId);
  //   dispatch(
  //     makeNetworkCallForGetChapterById(
  //       RequestType.GET,
  //       NetworkConstant.getChapterById,
  //       chapterId,
  //       {},
  //       {}
  //     )
  //   );
  // };

  //? make network call for get questions by chapter id
  const handleClickOnChapter = (chapterId) => {
    setChapterId(chapterId);
    dispatch(
      makeNetworkCallForGetQuestionsByChapterId(
        RequestType.GET,
        NetworkConstant.getQuestionsByChapterId,
        chapterId,
        {},
        {}
      )
    );
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCloseFilesUpload = () => {
    setAddedVideoList([]);
    setAddedFilesList([]);
    setUploadedPercent(0);
    setShowUploadFilesPopup(false);
  };

  const handleChangeSection = (event) => {
    setSectionValue(event.target.value);
    dispatch({
      type: ActionType.RESET_GET_ALL_CHAPTER_BY_SECTION_ID,
      payload: {},
    });
    dispatch(
      makeNetworkCallForGetChapterBySectionId(
        RequestType.GET,
        NetworkConstant.getAllChapterBySectionId,
        {},
        {},
        event.target.value
      )
    );
  };

  useEffect(() => {
    if (
      courseReducer.getAllChapterBySectionId.statusCode === 200 &&
      courseReducer.getAllChapterBySectionId.responseData.length > 0
    ) {
      if (
        courseReducer.getAllChapterBySectionId.responseData[0].sectionDetails
          .length > 0
      ) {
        setDataStoreItem(
          DataStoreKey.SECTION_NAME,
          courseReducer.getAllChapterBySectionId.responseData[0]
            .sectionDetails[0].sectionName
        );
      }
    }
  }, [courseReducer.getAllChapterBySectionId.statusCode]);

  useEffect(() => {
    if (sectionId !== null && sectionId !== "") {
      dispatch(
        makeNetworkCallForGetChapterBySectionId(
          RequestType.GET,
          NetworkConstant.getAllChapterBySectionId,
          {},
          {},
          sectionId
        )
      );
    }

    dispatch(
      makeNetworkCallForGetSectionByCourseId(
        courseId,
        RequestType.GET,
        NetworkConstant.getSectionByCourseId,
        {},
        {}
      )
    );

    return () => {
      dispatch({
        type: ActionType.RESET_SECTION_BY_COURSE_ID,
        payload: {},
      });
      dispatch({
        type: ActionType.RESET_GET_ALL_CHAPTER_BY_SECTION_ID,
        payload: {},
      });
    };
  }, []);

  const onClickAddQuestions = (event, chapterResponse) => {
    event.stopPropagation();
    setShowAddQuestionPopup(true);
    setChapterId(chapterResponse.chapterId);
  };

  const onClickOfPreviewFiles = (event, chapterId) => {
    event.stopPropagation();
    setIsForQuestionsPreview(false);
    setIsForFilesPreview(true);
    setChapterId(chapterId);
    dispatch(
      makeNetworkCallForGetChapterById(
        RequestType.GET,
        NetworkConstant.getChapterById,
        chapterId,
        {},
        {}
      )
    );
  };

  const customStyles = {
    content: {},
    input: {
      border: "none",
      outline: "none",
    },
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const emptyQuestionsField = () => {
    setQuestionImageResponseList([]);
    setQuestionTitle("");
    setOptionOne("");
    setOptionTwo("");
    setOptionThree("");
    setOptionFour("");
    setErrorQuestionTitle(false);
    setErrorOptionOne(false);
    setErrorOptionTwo(false);
    setErrorOptionThree(false);
    setErrorOptionFour(false);
    setIsCorrectOptionOne(false);
    setIsCorrectOptionTwo(false);
    setIsCorrectOptionThree(false);
    setIsCorrectOptionFour(false);
    setShowSnackBar(false);
    setSectionValue("");
    setCorrectAnswerDescription("");
    setErrorCorrectAnswerDescription(false);
    setErrorOrderNumber(false);
    setExpanded(false);
  };

  useEffect(() => {
    if (courseReducer.chapterQuestionMapping.statusCode === 200) {
      setShowAddQuestionPopup(false);
      dispatch({
        type: ActionType.RESET_CREATE_QUESTION_REQUEST,
        payload: {},
      });
      dispatch({
        type: ActionType.RESET_CHAPTER_QUESTION_MAPPING,
        payload: {},
      });
      emptyQuestionsField();
    }
  }, [courseReducer.chapterQuestionMapping.statusCode]);

  useEffect(() => {
    if (courseReducer.getChapterById.statusCode === 200) {
      if (isForFilesPreview) {
        setFileResponseList(
          courseReducer.getChapterById.responseData.fileResponse
        );
        setShowFilePreviewPopup(true);
      } else if (isForQuestionsPreview) {
        setQuestionResponseList(
          courseReducer.getChapterById.responseData.questionRequest
        );
        setShowChapterDetails(true);
      }
      dispatch({
        type: ActionType.RESET_GET_CHAPTER_BY_ID,
        payload: {},
      });
    }
  }, [courseReducer.getChapterById.statusCode]);

  useEffect(() => {
    if (courseReducer.getQuestionsByChapterId.statusCode === 200) {
      if (isForFilesPreview) {
        setFileResponseList(
          courseReducer.getChapterById.responseData.fileResponse
        );
        setShowFilePreviewPopup(true);
      } else if (isForQuestionsPreview) {
        setQuestionResponseList(
          courseReducer.getQuestionsByChapterId.responseData.questionRequest
        );
        setChapterIdForQuestion(
          courseReducer.getQuestionsByChapterId.responseData.chapterId
        );
        setShowChapterDetails(true);
      }

      dispatch({
        type: ActionType.RESET_GET_QUESTIONS_BY_CHAPTER_ID,
        payload: {},
      });
    }
  }, [courseReducer.getQuestionsByChapterId.statusCode]);

  useEffect(() => {
    scrollQuestionToSpecificPosition();
  }, [questionResponseList]);

  //? scroll question to specific position
  const scrollQuestionToSpecificPosition = () => {
    if (questionsRef.current) {
      const listItem = questionsRef.current.childNodes[questionScrollPosition];
      if (listItem) {
        listItem.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (showSnackBar === true) {
      setTimeout(() => {
        setShowSnackBar(false);
      }, 6000);
    }
  }, [showSnackBar]);

  const handleAddFilesClick = (event, chapterResponse) => {
    event.stopPropagation();
    setShowUploadFilesPopup(true);
    setChapterId(chapterResponse.chapterId);
    setDataStoreItem(DataStoreKey.CHAPTER_NAME, chapterResponse.name);
  };

  const handleClosePreviewFiles = () => {
    dispatch({
      type: ActionType.RESET_GET_CHAPTER_BY_ID,
      payload: {},
    });
    setIsForFilesPreview(false);
    setIsForQuestionsPreview(false);
    setDeleteFileType("");
    setDeleteFileId("");
    setFileResponseList([]);
    setShowFilePreviewPopup(false);
  };

  useEffect(() => {
    if (courseReducer.mapDurationVideos.statusCode === 200) {
      clearDataAndHideProgressBarPopup();
      dispatch({
        type: ActionType.RESET_MAP_DURATION_WITH_VIDEOS,
        payload: {},
      });
    }
  }, [courseReducer.mapDurationVideos.statusCode]);

  const handleOnClickSave = () => {
    let createQuestionMappingRequest = {
      chapterId: chapterId,
      questionRequest: courseReducer.createQuestionRequest.questionRequest,
    };
    dispatch(
      makeNetworkCallForCreateQuestionMapping(
        RequestType.POST,
        NetworkConstant.chapterQuestionMapping,
        createQuestionMappingRequest,
        {}
      )
    );
  };

  //?===================================================================================================

  // Function to load video metadata and calculate duration
  const loadMetadataAndCalculateDuration = async (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.addEventListener("loadedmetadata", () => {
        const duration = video.duration;
        resolve(duration);
      });
      video.addEventListener("error", (error) => {
        reject(error);
      });
      video.src = URL.createObjectURL(file);
      video.load();
    });
  };

  //? Function to make API request with all durations
  const makeApiRequestWithDurations = (durations) => {
    // Make API request with the durations
    // ...

    const mapDurationWithVideoJson = [];
    for (let i = 0; i < durations.length; i++) {
      var jsonObject = {
        id: courseReducer.uploadFilesByChapterId?.responseData[i]?.id,
        duration: Math.floor(durations[i]).toString(),
        order: (i + 1).toString(),
      };
      mapDurationWithVideoJson.push(jsonObject);
      if (i == durations.length - 1) {
        dispatch(
          makeNetworkCallMapDurationWithVideo(
            RequestType.POST,
            NetworkConstant.mapDurationWithVideo,
            mapDurationWithVideoJson,
            {}
          )
        );
      }
    }
  };

  //? Function to handle loading metadata and calculating durations for all videos
  const handleVideoProcessing = async () => {
    try {
      const durationPromises = addedVideoList.map(async (video) => {
        const duration = await loadMetadataAndCalculateDuration(video);
        return duration;
      });

      const durations = await Promise.all(durationPromises);
      makeApiRequestWithDurations(durations);
    } catch (error) {
      // Handle any errors that occurred during the process
      console.error(error);
    }
  };

  //?====================================================================================================

  const handleOnClickDone = () => {
    if (
      courseReducer.uploadFilesByChapterId.responseData !== undefined &&
      courseReducer.uploadFilesByChapterId.responseData !== null &&
      courseReducer.uploadFilesByChapterId.responseData.length !== 0
    ) {
      handleVideoProcessing();
    } else {
      clearDataAndHideProgressBarPopup();
    }
  };

  const clearDataAndHideProgressBarPopup = () => {
    setAddedVideoList([]);
    setAddedFilesList([]);
    setUploadedPercent(0);
    dispatch({
      type: ActionType.RESET_UPLOAD_FILES_BY_CHAPTER_ID,
      payload: {},
    });
    setShowUploadProgressBarPopup(false);
  };

  const checkForAllValues = () => {
    let isValid = true;
    if (questionTitle === "") {
      isValid = false;
      setErrorQuestionTitle(true);
    }
    if (optionOne === "") {
      isValid = false;
      setErrorOptionOne(true);
    }
    if (optionTwo === "") {
      isValid = false;
      setErrorOptionTwo(true);
    }
    if (optionThree === "") {
      isValid = false;
      setErrorOptionThree(true);
    }
    if (optionFour === "") {
      isValid = false;
      setErrorOptionFour(true);
    }
    if (correctAnswerDescription === "") {
      isValid = false;
      setErrorCorrectAnswerDescription(true);
    }
    if (order === "") {
      isValid = false;
      setErrorOrderNumber(true);
    }
    if (
      isCorrectOptionOne === false &&
      isCorrectOptionTwo === false &&
      isCorrectOptionThree === false &&
      isCorrectOptionFour === false
    ) {
      setShowSnackBar(true);
      isValid = false;
    }
    return isValid;
  };

  const handleOnClickAdd = () => {
    dispatch({
      type: ActionType.RESET_QUESTION_IMAGE,
      payload: {},
    });
    if (checkForAllValues()) {
      //? variables to handle the file id
      var questionFileId = "";
      var optionOneFileId = "";
      var optionTwoFileId = "";
      var optionThreeFileId = "";
      var optionFourFileId = "";
      var descriptionFileId = "";
      var questionImageUrl = [];
      var optionOneImageUrl = [];
      var optionTwoImageUrl = [];
      var optionThreeImageUrl = [];
      var optionFourImageUrl = [];
      var descriptionImageUrl = [];

      for (var i = 0; i < questionImageResponseList.length; i++) {
        if (questionImageResponseList[i].type === "QuestionImage") {
          questionFileId =
            questionFileId + questionImageResponseList[i].id + ",";
          let questionFileUrl = {
            url: questionImageResponseList[i].imageUrl,
          };
          questionImageUrl.push(questionFileUrl);
        } else if (questionImageResponseList[i].type === "Option1Image") {
          optionOneFileId = optionOneFileId + questionImageResponseList[i].id;
          let optionOneFileUrl = {
            url: questionImageResponseList[i].imageUrl,
          };
          optionOneImageUrl.push(optionOneFileUrl);
        } else if (questionImageResponseList[i].type === "Option2Image") {
          optionTwoFileId = optionTwoFileId + questionImageResponseList[i].id;
          let optionTwoFileUrl = {
            url: questionImageResponseList[i].imageUrl,
          };
          optionTwoImageUrl.push(optionTwoFileUrl);
        } else if (questionImageResponseList[i].type === "Option3Image") {
          optionThreeFileId =
            optionThreeFileId + questionImageResponseList[i].id;
          let optionThreeFileUrl = {
            url: questionImageResponseList[i].imageUrl,
          };
          optionThreeImageUrl.push(optionThreeFileUrl);
        } else if (questionImageResponseList[i].type === "Option4Image") {
          optionFourFileId = optionFourFileId + questionImageResponseList[i].id;
          let optionFourFileUrl = {
            url: questionImageResponseList[i].imageUrl,
          };
          optionFourImageUrl.push(optionFourFileUrl);
        } else if (questionImageResponseList[i].type === "DescriptionImage") {
          descriptionFileId =
            descriptionFileId + questionImageResponseList[i].id + ",";
          let descriptionFileUrl = {
            url: questionImageResponseList[i].imageUrl,
          };
          descriptionImageUrl.push(descriptionFileUrl);
        }
      }

      questionFileId = questionFileId.slice(0, -1);
      descriptionFileId = descriptionFileId.slice(0, -1);
      let questionRequest = [
        {
          optionRequest: [
            {
              optionName: optionOne,
              description: isCorrectOptionOne ? correctAnswerDescription : "",
              correct: isCorrectOptionOne,
              optionFileId: optionOneFileId,
              //optionImageUrl: optionOneImageUrl,
            },
            {
              optionName: optionTwo,
              description: isCorrectOptionTwo ? correctAnswerDescription : "",
              correct: isCorrectOptionTwo,
              optionFileId: optionTwoFileId,
              //optionImageUrl: optionTwoImageUrl,
            },
            {
              optionName: optionThree,
              description: isCorrectOptionThree ? correctAnswerDescription : "",
              correct: isCorrectOptionThree,
              optionFileId: optionThreeFileId,
              // optionImageUrl: optionThreeImageUrl,
            },
            {
              optionName: optionFour,
              description: isCorrectOptionFour ? correctAnswerDescription : "",
              correct: isCorrectOptionFour,
              optionFileId: optionFourFileId,
              //optionImageUrl: optionFourImageUrl,
            },
          ],
          questionName: questionTitle,
          questionFileId: questionFileId,
          descriptionId: descriptionFileId,
          order: order,
          // questionImageUrl: questionImageUrl,
          //descriptionImageUrl: descriptionImageUrl,
        },
      ];
      console.log("new question erquest :: ", questionRequest);
      dispatch(createQuestionRequest(questionRequest));
      emptyQuestionsField();
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //? actions for speed dial
  //? actions for speed dial
  const actions = [
    { icon: <FileCopyIcon />, name: "Copy", color: "white" },
    { icon: <EditIcon />, name: "Edit", color: "blue" },
    { icon: <PreviewIcon />, name: "Preview Files", color: "blue" },
    { icon: <AddIcon />, name: "Add Questions", color: "blue" },
    { icon: <AddIcon />, name: "Add Files", color: "blue" },
    { icon: <DeleteIcon />, name: "Delete", color: "red" },
    { icon: <SendIcon />, name: "Send", color: "blue" },
    { icon: <QuestionMarkIcon />, name: "Quick question", color: "blue" },
  ];

  return (
    <>
      <FormControl
        style={{
          width: "90%",
          alignSelf: "center",
          marginLeft: "5%",
          marginRight: "5%",
        }}
      >
        <InputLabel>
          {<span className="font-change-style">Select Section</span>}
        </InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={sectionValue}
          label={<span className="font-change-style">Select Section</span>}
          MenuProps={MenuProps}
          onChange={handleChangeSection}
        >
          {courseReducer.getSectionByCourseId.responseData.map((val, key) => {
            return (
              <MenuItem key={key} value={val.sectionId}>
                {<span className="font-change-style">{val.sectionName}</span>}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {courseReducer.getAllChapterBySectionId.responseData.map((val, key) => {
        return (
          <div
            className="chapter-container"
            key={key}
            onClick={() => {
              setIsForQuestionsPreview(true);
              setIsForFilesPreview(false);
              setDataStoreItem(DataStoreKey.CHAPTER_NAME, val.name);
              return handleClickOnChapter(val.chapterId);
            }}
          >
            <div className="chapter-title-container">
              <h2
                className="heading-container"
                style={{
                  fontSize: "x-large",
                }}
              >
                {val.name}
              </h2>
              <h3
                className="heading-container"
                style={{
                  fontSize: "large",
                }}
              >
                {val.briefDescription}
              </h3>
              <div className="manage-section-button-container">
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  icon={<SpeedDialIcon />}
                  FabProps={{
                    size: "small",
                  }}
                  direction="left"
                  style={{
                    marginRight: "20px",
                  }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      // sx={{
                      //   backgroundColor: action.color,
                      // }}
                      onClick={(event) => {
                        if (action.name === "Copy") {
                          return handleCopyChapterClick(
                            event,
                            val.chapterId,
                            val.name
                          );
                        } else if (action.name === "Edit") {
                          handleEditChapterPopup(
                            event,
                            val.chapterId,
                            val.name,
                            val.briefDescription,
                            val.requiredTrophies,
                            val.image,
                            val.sectionDetails[0].sectionId
                          );
                        } else if (action.name === "Preview Files") {
                          setDataStoreItem(DataStoreKey.CHAPTER_NAME, val.name);
                          return onClickOfPreviewFiles(event, val.chapterId);
                        } else if (action.name === "Add Questions") {
                          setDataStoreItem(DataStoreKey.CHAPTER_NAME, val.name);
                          return onClickAddQuestions(event, val);
                        } else if (action.name === "Add Files") {
                          return handleAddFilesClick(event, val);
                        } else if (action.name === "Delete") {
                          return handleDeleteChapterPopup(
                            event,
                            val.chapterId,
                            val.name,
                            val.sectionDetails[0].sectionId
                          );
                        } else if (action.name === "Send") {
                          return handleSendResultPopup(
                            event,
                            val.chapterId,
                            val.name
                          );
                        } else if (action.name === "Quick question") {
                          return handleQuickAddQuestion(
                            event,
                            val.chapterId,
                            val.name
                          );
                        }
                      }}
                    />
                  ))}
                </SpeedDial>
              </div>
            </div>
          </div>
        );
      })}
      <Dialog
        style={customStyles}
        open={showAddQuestionPopup}
        fullScreen={true}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseAddQuestionPopup}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              <span>Close</span>
            </Typography>
            <Typography sx={{ ml: 2, flex: 2 }} variant="h6" component="div">
              <span>
                Chapter : {getDataStoreItem(DataStoreKey.CHAPTER_NAME)}
              </span>
            </Typography>
            <Button autoFocus color="inherit" onClick={handleOnClickSave}>
              <span>save</span>
            </Button>
          </Toolbar>
        </AppBar>
        {/* dialog to show add question  */}
        <DialogTitle className="add-question-dialog">
          <div className="add-question-container">
            <TextField
              error={errorQuestionTitle}
              id="outlined-error-helper-text"
              label={<span>Title</span>}
              inputProps={{
                style: { fontFamily: Constant.FUTURA_CONDENSED },
              }}
              multiline
              rows={3}
              style={{
                marginTop: "5%",
                width: "90%",
              }}
              helperText={
                errorQuestionTitle ? <span>Please enter title</span> : ""
              }
              onChange={(event) => setQuestionTitle(event.target.value)}
              value={questionTitle}
            />
            <div className="options-container">
              <TextField
                error={errorOptionOne}
                id="outlined-error-helper-text"
                label={<span>Option 1</span>}
                inputProps={{
                  style: { fontFamily: Constant.FUTURA_CONDENSED },
                }}
                multiline
                rows={3}
                style={{
                  marginRight: "3%",
                  width: "70%",
                }}
                helperText={
                  errorOptionOne ? <span>Please enter Option 1</span> : ""
                }
                onChange={(event) => setOptionOne(event.target.value)}
                value={optionOne}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCorrectOptionOne}
                    onClick={() => setIsCorrectOptionOne(!isCorrectOptionOne)}
                  />
                }
                label={<span>Correct</span>}
              />
            </div>
            <div className="options-container">
              <TextField
                error={errorOptionTwo}
                id="outlined-error-helper-text"
                label={<span>Option 2</span>}
                inputProps={{
                  style: { fontFamily: Constant.FUTURA_CONDENSED },
                }}
                multiline
                rows={3}
                style={{
                  marginRight: "3%",
                  width: "70%",
                }}
                helperText={
                  errorOptionTwo ? <span>Please enter Option 2</span> : ""
                }
                onChange={(event) => setOptionTwo(event.target.value)}
                value={optionTwo}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCorrectOptionTwo}
                    onClick={() => setIsCorrectOptionTwo(!isCorrectOptionTwo)}
                  />
                }
                label={<span>Correct</span>}
              />
            </div>
            <div className="options-container">
              <TextField
                error={errorOptionThree}
                id="outlined-error-helper-text"
                label={<span>Option 3</span>}
                inputProps={{
                  style: { fontFamily: Constant.FUTURA_CONDENSED },
                }}
                multiline
                rows={3}
                style={{
                  marginRight: "3%",
                  width: "70%",
                }}
                helperText={
                  errorOptionThree ? <span>Please enter Option 3</span> : ""
                }
                onChange={(event) => setOptionThree(event.target.value)}
                value={optionThree}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCorrectOptionThree}
                    onClick={() =>
                      setIsCorrectOptionThree(!isCorrectOptionThree)
                    }
                  />
                }
                label={<span>Correct</span>}
              />
            </div>
            <div className="options-container">
              <TextField
                error={errorOptionFour}
                id="outlined-error-helper-text"
                label={<span>Option 4</span>}
                inputProps={{
                  style: { fontFamily: Constant.FUTURA_CONDENSED },
                }}
                multiline
                rows={3}
                style={{
                  marginRight: "3%",
                  width: "70%",
                }}
                helperText={
                  errorOptionFour ? <span>Please enter Option 4</span> : ""
                }
                onChange={(event) => setOptionFour(event.target.value)}
                value={optionFour}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCorrectOptionFour}
                    onClick={() => setIsCorrectOptionFour(!isCorrectOptionFour)}
                  />
                }
                label={<span>Correct</span>}
              />
            </div>

            <TextField
              error={errorCorrectAnswerDescription}
              id="outlined-error-helper-text"
              label={<span>Description (correct answer)</span>}
              inputProps={{
                style: { fontFamily: Constant.FUTURA_CONDENSED },
              }}
              multiline
              rows={3}
              style={{
                marginTop: "5%",
                width: "90%",
              }}
              helperText={
                errorCorrectAnswerDescription ? (
                  <span>Please enter description for correct answer</span>
                ) : (
                  ""
                )
              }
              onChange={(event) =>
                setCorrectAnswerDescription(event.target.value)
              }
              value={correctAnswerDescription}
            />
            <TextField
              error={errorOrderNumber}
              id="outlined-error-helper-text"
              label={<span>Question order no</span>}
              inputProps={{
                style: { fontFamily: Constant.FUTURA_CONDENSED },
              }}
              rows={1}
              style={{
                marginTop: "5%",
                width: "90%",
              }}
              helperText={
                errorOrderNumber ? (
                  <span>Please enter order no for question.</span>
                ) : (
                  ""
                )
              }
              onChange={(event) => setOrder(event.target.value)}
              value={order}
            />
            {/* handle react quill */}
            {/* <ReactQuill
              theme="snow"
              onChange={(state) => setCorrectAnswerDescription(state.content)}
              value={correctAnswerDescription}
            />
            {errorCorrectAnswerDescription && (
              <div>
                <span className="input-error-container">
                  Please enter description for correct answer
                </span>
              </div>
            )} */}
            <button
              onClick={handleOnClickAdd}
              className="button-save-add-question"
            >
              Add
            </button>
            <button
              style={{
                marginLeft: "10px",
              }}
              onClick={handleOnClickUploadQuestionFiles}
              className="button-save-add-question"
            >
              Upload Files
            </button>
          </div>
          <div className="line-container"></div>
          <div className="added-questions-container">
            {courseReducer.createQuestionRequest.questionRequest !==
              undefined &&
              courseReducer.createQuestionRequest.questionRequest.length !==
                0 &&
              courseReducer.createQuestionRequest.questionRequest.map(
                (val, key) => {
                  return (
                    <div className="question-content" key={key}>
                      <h3 className="question-style">{`${key + 1}. ${
                        val.questionName
                      }`}</h3>
                      {val.optionRequest !== undefined &&
                        val.optionRequest.length !== 0 &&
                        val.optionRequest.map((data, index) => {
                          return (
                            <div>
                              <div className="options-style">
                                <p className="question-style">{`${(index + 10)
                                  .toString(36)
                                  .toLowerCase()}). ${data.optionName}`}</p>
                                {data.correct && (
                                  <img
                                    src={require("../../assets/image/check-mark.png")}
                                    height={20}
                                    width={20}
                                    style={{
                                      alignSelf: "center",
                                    }}
                                  />
                                )}
                              </div>
                              {data.correct && (
                                <p className="question-style">
                                  {`Description: ${data.description}`}
                                </p>
                              )}
                            </div>
                          );
                        })}
                      <div>
                        <Tooltip
                          title="Preview files"
                          arrow
                          style={{
                            alignSelf: "flex-end",
                          }}
                        >
                          <Fab
                            size="small"
                            color="primary"
                            aria-label="preview files"
                            onClick={() =>
                              handlePreviewFilesClickForQuestion(val)
                            }
                          >
                            <VisibilityIcon />
                          </Fab>
                        </Tooltip>
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        </DialogTitle>
        <DialogContent className="add-question-content"></DialogContent>
      </Dialog>
      <Dialog open={showUploadFilesPopup}>
        <DialogTitle
          style={{
            width: "600px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="close-icon" onClick={handleCloseFilesUpload}>
            <CancelIcon />
          </div>
          <p>Section : {getDataStoreItem(DataStoreKey.SECTION_NAME)}</p>
          <p>Chapter : {getDataStoreItem(DataStoreKey.CHAPTER_NAME)}</p>
          <div className="video-container">
            {addedVideoList.map((val, key) => {
              return (
                <div className="video-item" key={key}>
                  <p
                    style={{
                      fontSize: "15px",
                      width: "200px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontFamily: "FuturaCondensed",
                    }}
                  >
                    {val.name}
                  </p>
                  <p
                    style={{
                      fontSize: "15px",
                      width: "200px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontFamily: "FuturaCondensed",
                    }}
                  >
                    Position: {key + 1}
                  </p>
                  <div className="delete-icon-box">
                    <Tooltip title="Delete" arrow>
                      <Fab
                        size="small"
                        className="button-delete"
                        color="error"
                        aria-label="delete"
                        onClick={() => handleDeleteVideo(key)}
                      >
                        <DeleteIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
          </div>
          <button
            className="button-style-attach"
            onClick={() => addVideoReference.current.click()}
          >
            Add Video
          </button>

          <div className="file-container">
            {addedFilesList.map((val, key) => {
              return (
                <div className="file-item" key={key}>
                  <p
                    style={{
                      fontSize: "15px",
                      width: "200px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontFamily: "FuturaCondensed",
                    }}
                  >
                    {val.name}
                  </p>
                  <p
                    style={{
                      fontSize: "15px",
                      width: "200px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontFamily: "FuturaCondensed",
                    }}
                  >
                    Position: {key + 1}
                  </p>
                  <div className="delete-icon-box">
                    <Tooltip title="Delete" arrow>
                      <Fab
                        size="small"
                        className="button-delete"
                        color="error"
                        aria-label="delete"
                        onClick={() => handleDeleteFile(key)}
                      >
                        <DeleteIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
          </div>
          <input
            hidden
            type="file"
            accept="video/*"
            onChange={handleOnChangeAddVideo}
            ref={addVideoReference}
          />
          <button
            className="button-style-attach"
            onClick={() => addFileReference.current.click()}
          >
            Add File
          </button>
          <input
            hidden
            type="file"
            accept=".jpg,.doc,.pdf,.jpeg,.png"
            onChange={handleOnChangeAddFiles}
            ref={addFileReference}
          />
        </DialogTitle>
        <DialogContent>
          <button className="button-style-upload" onClick={handleOnClickUpload}>
            Upload
          </button>
        </DialogContent>
      </Dialog>
      {/* dialog for handle the show question list */}
      <Dialog style={customStyles} open={showChapterDetails} fullScreen={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseChapterDetails}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Close
            </Typography>
            <Typography sx={{ ml: 2, flex: 2 }} variant="h6" component="div">
              <span>
                Chapter : {getDataStoreItem(DataStoreKey.CHAPTER_NAME)}
              </span>
            </Typography>
            <Button
              color="error"
              variant="contained"
              endIcon={<FileCopyIcon />}
              onClick={handleCopyQuestionsClickForEntireQuestions}
            >
              <span>Upload Entire Question</span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              endIcon={<SaveIcon />}
              style={{
                marginLeft: "10px",
              }}
              onClick={handleSaveChangesForQuestions}
            >
              <span>Save changes</span>
            </Button>
          </Toolbar>
        </AppBar>
        <DialogTitle className="chapter-content-preview">
          <div className="questions-list-container" ref={questionsRef}>
            {/* {questionResponseList !== undefined &&
              questionResponseList.length !== 0 && (
                <TextField
                  id="outlined-error-helper-text"
                  label={<span>Search</span>}
                  inputProps={{
                    style: { fontFamily: Constant.FUTURA_CONDENSED },
                  }}
                  style={{
                    marginLeft: "5%",
                    marginRight: "5%",
                    width: "90%",
                  }}
                  onChange={(event) => {
                    questionResponseList?.filter((item) =>
                      item?.questionName.includes(event.target.value)
                    );
                  }}
                />
              )} */}

            {questionResponseList !== undefined &&
              questionResponseList.length !== 0 && (
                <span>
                  Total No of Questions : {questionResponseList.length}
                </span>
              )}
            {questionResponseList !== undefined &&
              questionResponseList.length !== 0 &&
              questionResponseList.map((val, key) => {
                return (
                  <div className="question-content" key={key}>
                    <h3 className="question-style">{`${key + 1}. ${
                      val.questionName
                    }`}</h3>
                    {val.questionImageUrl !== undefined &&
                      val.questionImageUrl.length !== 0 &&
                      val.questionImageUrl.map(
                        (questionUrl, keyQuestionUrl) => {
                          return (
                            <div key={keyQuestionUrl}>
                              <img
                                src={questionUrl.url}
                                style={{
                                  width: "500px",
                                  height: "500px",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          );
                        }
                      )}
                    {val.optionRequest !== undefined &&
                      val.optionRequest.length !== 0 &&
                      val.optionRequest.map((data, index) => {
                        return (
                          <div key={index}>
                            <div className="options-style">
                              <p
                                style={{
                                  marginTop: "10px",
                                }}
                              >{`${(index + 10).toString(36).toLowerCase()}). ${
                                data.optionName
                              }`}</p>
                              {data.correct && (
                                <img
                                  src={require("../../assets/image/check-mark.png")}
                                  height={30}
                                  width={30}
                                />
                              )}
                            </div>
                            {data.optionImageUrl !== undefined &&
                              data.optionImageUrl.length !== 0 &&
                              data.optionImageUrl.map(
                                (dataOptionImage, keyOptionImage) => {
                                  return (
                                    <div key={keyOptionImage}>
                                      <img
                                        src={dataOptionImage.url}
                                        style={{
                                          width: "500px",
                                          height: "500px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        );
                      })}
                    {val.optionRequest !== undefined &&
                      val.optionRequest.length !== 0 &&
                      val.optionRequest.map((value, keyIndex) => {
                        return (
                          value.correct && (
                            <p key={keyIndex} className="question-style">
                              {`Description: ${value.description}`}
                            </p>
                          )
                        );
                      })}
                    {val.descriptionImageUrl !== undefined &&
                      val.descriptionImageUrl.length !== 0 &&
                      val.descriptionImageUrl.map(
                        (dataDescriptionImageUrl, keyDescriptionImageUrl) => {
                          return (
                            <div key={keyDescriptionImageUrl}>
                              <img
                                src={dataDescriptionImageUrl.url}
                                style={{
                                  width: "500px",
                                  height: "500px",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          );
                        }
                      )}
                    <div>
                      <TextField
                        className="text-area"
                        id="outlined-error-helper-text"
                        helperText={<span>Please enter order no</span>}
                        onChange={(event) =>
                          handleChangeOrderOfQuestions(key, event)
                        }
                        inputProps={inputPropsStyle.inputProp}
                        value={val.order ?? ""}
                        label={<span className="font-change-style">Order</span>}
                      />
                    </div>
                    <div className="deleteIconQuestions">
                      <Tooltip title="Copy" arrow className="copy-button">
                        <Fab
                          style={{
                            backgroundColor: "white",
                            marginRight: "20px",
                          }}
                          aria-label="Copy"
                          size="small"
                          onClick={(event) =>
                            handleCopyQuestionsClick(event, val.questionId)
                          }
                          className="button-focus"
                        >
                          <FileCopyIcon />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Edit" arrow>
                        <Fab
                          className="button-focus"
                          color="primary"
                          size="small"
                          aria-label="Edit"
                          onClick={() => {
                            setQuestionScrollPosition(key);
                            return handleEditQuestion(val);
                          }}
                          style={{
                            marginRight: "10px",
                          }}
                        >
                          <EditIcon />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Delete from here" arrow>
                        <Fab
                          className="button-focus"
                          color="error"
                          size="small"
                          aria-label="delete from here"
                          onClick={() => {
                            return handleDeleteQuestionFromHere(val.questionId);
                          }}
                          style={{
                            marginRight: "10px",
                          }}
                        >
                          <DeleteIcon />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Delete From everywhere" arrow>
                        <Fab
                          className="button-focus"
                          color="error"
                          size="small"
                          aria-label="delete from everywhere"
                          onClick={() => {
                            return handleDeleteQuestion(val.questionId);
                          }}
                        >
                          <DeleteSweepIcon />
                        </Fab>
                      </Tooltip>
                    </div>
                  </div>
                );
              })}
          </div>

          {/* <div className="line-container"></div>

          <div className="file-list-container">
            <h2 className="file-title-style">VIDEO</h2>
            <div className="file-list-for-video">
              {fileResponseList !== undefined &&
                fileResponseList.length !== 0 &&
                fileResponseList.map((val, key) => {
                  return (
                    val.type === "video" && (
                      <div className="files-container" key={key}>
                        <ReactPlayer
                          url={val.url}
                          controls={true}
                          width={250}
                          height={200}
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginRight: "20px",
                          }}
                        >
                          <Tooltip title="Delete" arrow>
                            <Fab
                              className="button-focus"
                              color="error"
                              size="small"
                              aria-label="delete"
                              onClick={() => {
                                setDeleteFileType(val.type);
                                setDeleteFileId(val.id);
                                return handleDeleteFilePdfOrImageOrVideo(
                                  val.id
                                );
                              }}
                            >
                              <DeleteIcon />
                            </Fab>
                          </Tooltip>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>

            <h2 className="file-title-style">FILES</h2>
            <div className="file-list">
              {fileResponseList !== undefined &&
                fileResponseList.length !== 0 &&
                fileResponseList.map((val, key) => {
                  return (
                    val.type !== "video" && (
                      <div className="files-container-pdf-image" key={key}>
                        {/* <Document
                            file={{
                              url: `${val.url}`,
                            }}
                          >
                            <Page pageNumber={0} />
                          </Document> 
                        <a
                          href={val.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Lottie
                            loop
                            animationData={filesAnimation}
                            play
                            style={{ width: 200, height: 150 }}
                          />
                        </a>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginRight: "20px",
                          }}
                        >
                          <Tooltip title="Delete" arrow>
                            <Fab
                              className="button-focus"
                              color="error"
                              size="small"
                              aria-label="delete"
                              onClick={() => {
                                setDeleteFileType(val.type);
                                setDeleteFileId(val.id);
                                return handleDeleteFilePdfOrImageOrVideo(
                                  val.id
                                );
                              }}
                            >
                              <DeleteIcon />
                            </Fab>
                          </Tooltip>
                        </div>
                      </div>
                    )
                  );
                })}
            </div> 
          </div>*/}
        </DialogTitle>
        <DialogContent className="add-question-content"></DialogContent>
      </Dialog>

      {/* //?popup for show files */}

      <Dialog
        style={customStyles}
        open={showFilesPreviewPopup}
        fullScreen={true}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClosePreviewFiles}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Close
            </Typography>
            <Typography sx={{ ml: 2, flex: 2 }} variant="h6" component="div">
              Chapter : {getDataStoreItem(DataStoreKey.CHAPTER_NAME)}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleSaveFileResponseList}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogTitle className="chapter-content-preview">
          <div className="file-list-container">
            <h2 className="file-title-style">VIDEO</h2>
            <div className="file-list-for-video">
              {fileResponseList !== undefined &&
                fileResponseList.length !== 0 &&
                fileResponseList.map((val, key) => {
                  return (
                    val.type === "video" && (
                      <div
                        className="files-card-container"
                        draggable
                        onDragStart={(e) => (dragItem.current = key)}
                        onDragEnter={(e) => (dragOverItem.current = key)}
                        onDragEnd={handleSort}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <i class="fa-solid fa-bars"></i>
                        <div className="files-container" key={key}>
                          <ReactPlayer
                            url={val.url}
                            controls={true}
                            width={250}
                            height={200}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginRight: "20px",
                            }}
                          >
                            <Tooltip title="Delete" arrow>
                              <Fab
                                className="button-focus"
                                color="error"
                                size="small"
                                aria-label="delete"
                                onClick={() => {
                                  setDeleteFileType(val.type);
                                  setDeleteFileId(val.id);
                                  return handleDeleteFilePdfOrImageOrVideo(
                                    val.id
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </Fab>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="files-description">
                          <span>
                            {key + 1}. {val?.fileName}
                          </span>
                        </div>
                        <Tooltip title="Copy" arrow className="copy-button">
                          <Fab
                            style={{
                              backgroundColor: "white",
                              marginRight: "20px",
                            }}
                            aria-label="Copy"
                            size="small"
                            onClick={(event) =>
                              handleCopyFilesClick(event, val.id, val.fileName)
                            }
                            className="button-focus"
                          >
                            <FileCopyIcon />
                          </Fab>
                        </Tooltip>
                      </div>
                    )
                  );
                })}
            </div>
          </div>
          <div className="line-container"></div>
          <div className="file-list-container">
            <h2 className="file-title-style">FILES</h2>
            <div className="file-list">
              {fileResponseList !== undefined &&
                fileResponseList.length !== 0 &&
                fileResponseList.map((val, key) => {
                  return (
                    val.type !== "video" && (
                      <div
                        className="files-card-container"
                        draggable
                        onDragStart={(e) => (dragItem.current = key)}
                        onDragEnter={(e) => (dragOverItem.current = key)}
                        onDragEnd={handleSort}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <i class="fa-solid fa-bars"></i>
                        <div className="files-container-pdf-image" key={key}>
                          <a
                            href={val.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Lottie
                              loop
                              animationData={filesAnimation}
                              play
                              style={{ width: 200, height: 150 }}
                            />
                          </a>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginRight: "20px",
                            }}
                          >
                            <Tooltip title="Delete" arrow>
                              <Fab
                                className="button-focus"
                                color="error"
                                size="small"
                                aria-label="delete"
                                onClick={() => {
                                  setDeleteFileType(val.type);
                                  setDeleteFileId(val.id);
                                  return handleDeleteFilePdfOrImageOrVideo(
                                    val.id
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </Fab>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="files-description">
                          <span>
                            {key + 1}. {val?.fileName}
                          </span>
                        </div>
                        <Tooltip title="Copy" arrow className="copy-button">
                          <Fab
                            style={{
                              backgroundColor: "white",
                              marginRight: "20px",
                            }}
                            aria-label="Copy"
                            size="small"
                            onClick={(event) =>
                              handleCopyFilesClick(event, val.id, val.fileName)
                            }
                            className="button-focus"
                          >
                            <FileCopyIcon />
                          </Fab>
                        </Tooltip>
                      </div>
                    )
                  );
                })}
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="add-question-content"></DialogContent>
      </Dialog>

      <Dialog open={showUploadProgressBarPopup}>
        <DialogTitle
          style={{
            width: "600px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p>Section : {getDataStoreItem(DataStoreKey.SECTION_NAME)}</p>
          <p>Chapter : {getDataStoreItem(DataStoreKey.CHAPTER_NAME)}</p>
          <div className="lottie-style">
            {uploadedPercent !== 100 && (
              <Lottie
                loop
                animationData={planeLoading}
                play
                style={{ width: 200, height: 200 }}
              />
            )}
            {uploadedPercent === 100 && (
              <Lottie
                loop
                animationData={doneAnimation}
                play
                style={{ width: 200, height: 200 }}
              />
            )}
          </div>
          <div className="progress-bar-container">
            <LinearProgress
              variant="determinate"
              value={uploadedPercent}
              style={{
                height: "10px",
                width: "85%",
                borderRadius: "10px",
              }}
            />

            <p
              style={{
                fontSize: "25px",
                marginTop: "15px",
              }}
            >
              {uploadedPercent + "%"}
            </p>
          </div>
        </DialogTitle>
        <DialogContent className="dialog-content-container">
          <div>
            {courseReducer.uploadFilesByChapterId.statusCode !== 200 && (
              <p>Processing...</p>
            )}
            {courseReducer.uploadFilesByChapterId.statusCode === 200 && (
              <button className="button-style-done" onClick={handleOnClickDone}>
                Done
              </button>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        open={showSnackBar}
        autoHideDuration={6000}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Please select one correct answer
        </Alert>
      </Snackbar>
      {apiState.showLoader && <BlueLoader />}
      <DeleteChapterPopup {...deleteChapterPopupProps} />
      <EditChapterPopup {...editChapterPopupProps} />
      <UploadFilesForQuestionPopup {...uploadFileForQuestionPopupProps} />
      <PreviewFilesForQuestionPopup {...previewFilesForQuestionPopupProps} />
      <EditQuestionPopup {...editQuestionPopupProps} />
      <CopyFilesAndUploadPopup {...copyFilesAndUploadPopupProps} />
      <CopyQuestionsAndUploadPopup {...copyQuestionsAndUploadPopupProps} />
      <CopyChapterPopup {...copyChaptersAndUploadPopupProps} />
      <SendResultPopup {...sendResultPopupProps} />
    </>
  );
};

export default ManageChapters;
