import { useEffect, useState } from "react";
import "./Home.css";
import { getDatabase, ref, onValue } from "firebase/database";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import {
  DataStoreKey,
  getDataStoreItem,
} from "../persistence/PersistenceStorage";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler
);
export const Home = () => {
  const [showInterval, setShowInterval] = useState("");
  const [activeUserIOS, setActiveUserIOS] = useState(0);
  const [activeUserWeb, setActiveUserWeb] = useState(0);
  const [activeUserAndroid, setActiveUserAndroid] = useState(0);
  const userState = useSelector((state) => state.userReducer);
  let message;
  const data = {
    labels: ["Web", "Android", "iOS"],
    datasets: [
      {
        label: "Active users ",
        data: [activeUserWeb, activeUserAndroid, activeUserIOS],
        backgroundColor: [
          "rgba(223, 137, 62, 1)",
          "rgba(38, 181, 76, 1)",
          "rgba(42, 107,213, 1)",
        ],
        borderColor: [
          "rgba(243, 128, 27, 1)",
          "rgba(38, 181, 76, 1)",
          "rgba(42, 107,213, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const dbRefUser = ref(getDatabase(), "users");

    onValue(dbRefUser, (snapshot) => {
      const data = snapshot.val();
      setActiveUserIOS(Object.keys(data.ios).length);
      setActiveUserAndroid(Object.keys(data.android).length);
      setActiveUserWeb(Object.keys(data.web).length);
    });

    const interval = setInterval(() => {
      setShowInterval((prevCounter) => prevCounter + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (new Date().getHours() >= 1 && new Date().getHours() < 12) {
    message = <h4>Good Morning</h4>;
  } else if (new Date().getHours() >= 12 && new Date().getHours() < 18) {
    message = <h4>Good Afternoon</h4>;
  } else {
    message = <h4>Good Evening</h4>;
  }
  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July"];
  const dataRevenueLastYear = {
    labels: labels,
    datasets: [
      {
        label: "My First Dataset",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: true,
        borderColor: "#FFB72B",
        fill: {
          target: "origin", // 3. Set the fill options
          above: "rgb(25, 54, 83, 0.7)",
        },
        data: [5500, 2500, 10000, 6000, 14000, 1500, 7000, 20000],
        pointBorderColor: "rgba(255,255,255,0)",
        pointBackgroundColor: "rgba(255,255,255,0)",
        pointBorderWidth: 0,
        pointHoverRadius: 8,
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 4,
        pointRadius: 1,
        borderWidth: 3,
        pointHitRadius: 16,
      },
    ],
  };




  
  return (
    <div className="home-container">
      <div className="home">
        <div>
          <h2>Hey {getDataStoreItem(DataStoreKey.USER_FIRST_NAME)} !</h2>
          {message}
          {showInterval && <p>{new Date().toLocaleTimeString()}</p>}
        </div>
        <div className="doughtnut-style">
          <h1>
            Active Users : {activeUserAndroid + activeUserIOS + activeUserWeb}
          </h1>
          <div
            style={{
              width: "250px",
              height: "250px",
              marginTop: "20px",
            }}
          >
            <Doughnut
              data={data}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </div>
        </div>
      </div>

      <div className="revenue-title">
        <h2>Revenue</h2>
      </div>

      <div className="revenue">
        <div className="revenue-item">
          <Line
            height={200}
            data={dataRevenueLastYear}
            options={{
              elements: {
                line: {
                  tension: 0.3
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div className="revenue-item"></div>
      </div>
    </div>
  );
};
