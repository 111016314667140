import underConstruction from "../assets/lottie/under_contruction.json";
import Lottie from "react-lottie-player";
import "./Settings.css";
import { TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import { ShowSnackBar } from "../snackbar/SnackBar";
import { Constant } from "../constants/Constant";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "../redux/action/actionType";
import { makeNetworkCallAddCourseMappingDrNeeraj } from "../redux/action/SettingsAction";
import { NetworkConstant } from "../network/NetworkConstant";

const Settings = () => {
  const [showEmailError, setShowEmailError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [email, setEmail] = useState("");
  const apiState = useSelector((state) => state.apiStateReducer);
  const settingsReducer = useSelector((state) => state.settingsReducer);
  const dispatch = useDispatch();

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: { showSnackBar: false, snackBarMessage: "" },
      });
    }, 5000);
  }

  //? style for input props
  const inputPropsStyle = {
    inputProp: {
      style: { fontFamily: Constant.FUTURA_CONDENSED },
    },
  };

  //? handle assign click
  const handleAssignClick = () => {
    if (email === "" || email === undefined) {
      setShowEmailError(true);
    } else {
      setShowLoading(true);
      dispatch(
        makeNetworkCallAddCourseMappingDrNeeraj(
          NetworkConstant.addCourseMappingDrNeeraj,
          email,
          {},
          {}
        )
      );
    }
  };

  useEffect(() => {
    setShowEmailError(false);
  }, [email]);

  useEffect(() => {
    if (settingsReducer?.addCourseMapping?.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_ADD_COURSE_MAPPING_DR_NEERAJ,
        payload: {},
      });
    }
  }, [settingsReducer?.addCourseMapping?.statusCode]);

  useEffect(() => {
    if (apiState.showSnackBar) {
      setShowLoading(false);
    }
  }, [apiState.showSnackBar]);

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.RESET_ADD_COURSE_MAPPING_DR_NEERAJ,
        payload: {},
      });
    };
  }, []);

  return (
    <>
      <div className="container">
        <TextField
          className="text-area"
          id="outlined-error-helper-text"
          error={showEmailError}
          helperText={showEmailError ? <span>Please enter email</span> : ""}
          onChange={(event) => setEmail(event.target.value)}
          inputProps={inputPropsStyle.inputProp}
          value={email}
          label={<span className="font-change-style">Email Id</span>}
        />

        <Stack
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
          direction="row"
          spacing={1}
        >
          <LoadingButton
            loading={showLoading}
            loadingPosition="start"
            startIcon={<SendIcon />}
            variant="contained"
            onClick={() => handleAssignClick()}
          >
            <span>Assign</span>
          </LoadingButton>
        </Stack>
      </div>
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};

export default Settings;
