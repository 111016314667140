import { Constant } from "../../constants/Constant";
import { MakeNewtworkCall } from "../../network/BaseAxios";
import { RequestType } from "../../network/RequestType";
import { ActionType } from "./actionType";

const makeNetworkCallAddCourseMappingDrNeeraj =
  (url, emailId, body, header) => async (dispatch) => {
    try {
      const updatedUrl = `${url}?emailId=${emailId}`;
      const { status, data } = await MakeNewtworkCall(
        RequestType.POST,
        updatedUrl,
        body,
        header
      );
      if (status === 200) {
        dispatch({
          type: ActionType.ADD_COURSE_MAPPING_DR_NEERAJ,
          payload: {
            addCourseMapping: {
              statusCode: 200,
              responseData: data.responseData,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: error?.response?.data?.responseData?.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

const makeNetworkCallCreateEvent = (url, body, header) => async (dispatch) => {
  try {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    const { status, data } = await MakeNewtworkCall(
      RequestType.POST,
      url,
      body,
      header
    );
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: false },
    });
    if (status === 200) {
      dispatch({
        type: ActionType.CREATE_EVENT,
        payload: {
          createEvent: {
            statusCode: 200,
            responseData: data.responseData,
          },
        },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Event created successfully",
          snackBarSeverity: Constant.SEVERITY_SUCCESS,
          apiError: true,
        },
      });
    } else {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: data.responseData.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.SHOW_SNACKBAR,
      payload: {
        showSnackBar: true,
        snackBarMessage: error?.response?.data?.responseData?.message,
        snackBarSeverity: Constant.SEVERITY_ERROR,
        apiError: true,
      },
    });
  }
};

const makeNetworkCallEditEvent = (url, body, header) => async (dispatch) => {
  try {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    const { status, data } = await MakeNewtworkCall(
      RequestType.POST,
      url,
      body,
      header
    );
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: false },
    });
    if (status === 200) {
      dispatch({
        type: ActionType.EDIT_EVENT,
        payload: {
          editEvent: {
            statusCode: 200,
            responseData: data.responseData,
          },
        },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Event edited successfully",
          snackBarSeverity: Constant.SEVERITY_SUCCESS,
          apiError: true,
        },
      });
    } else {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: data.responseData.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.SHOW_SNACKBAR,
      payload: {
        showSnackBar: true,
        snackBarMessage: error?.response?.data?.responseData?.message,
        snackBarSeverity: Constant.SEVERITY_ERROR,
        apiError: true,
      },
    });
  }
};

const makeNetworkCallDeleteEvent = (url, body, header) => async (dispatch) => {
  try {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    const { status, data } = await MakeNewtworkCall(
      RequestType.DELETE,
      url,
      body,
      header
    );
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: false },
    });
    if (status === 200) {
      dispatch({
        type: ActionType.DELETE_EVENT,
        payload: {
          deleteEvent: {
            statusCode: 200,
            responseData: data.responseData,
          },
        },
      });
    } else {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: data.responseData.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.SHOW_SNACKBAR,
      payload: {
        showSnackBar: true,
        snackBarMessage: error?.response?.data?.responseData?.message,
        snackBarSeverity: Constant.SEVERITY_ERROR,
        apiError: true,
      },
    });
  }
};

const makeNetworkCallGetAllEvent = (url, body, header) => async (dispatch) => {
  try {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    const { status, data } = await MakeNewtworkCall(
      RequestType.GET,
      url,
      body,
      header
    );
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: false },
    });
    if (status === 200) {
      dispatch({
        type: ActionType.GET_EVENT,
        payload: {
          getEvent: {
            statusCode: 200,
            responseData: data.responseData,
          },
        },
      });
    } else {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: data.responseData.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.SHOW_SNACKBAR,
      payload: {
        showSnackBar: true,
        snackBarMessage: error?.response?.data?.responseData?.message,
        snackBarSeverity: Constant.SEVERITY_ERROR,
        apiError: true,
      },
    });
  }
};

// for testimonial
const makeNetworkCallCreateTestimonial = (url, body, header) => async (dispatch) => {
  try {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    const { status, data } = await MakeNewtworkCall(
      RequestType.POST,
      url,
      body,
      header
    );
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: false },
    });
    if (status === 200) {
      dispatch({
        type: ActionType.CREATE_TESTIMONIAL,
        payload: {
          createTestimonial: {
            statusCode: 200,
            responseData: data.responseData,
          },
        },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Testimonial created successfully",
          snackBarSeverity: Constant.SEVERITY_SUCCESS,
          apiError: true,
        },
      });
    } else {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: data.responseData.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.SHOW_SNACKBAR,
      payload: {
        showSnackBar: true,
        snackBarMessage: error?.response?.data?.responseData?.message,
        snackBarSeverity: Constant.SEVERITY_ERROR,
        apiError: true,
      },
    });
  }
};


const makeNetworkCallEditTestimonial = (url, body, header) => async (dispatch) => {
  try {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    const { status, data } = await MakeNewtworkCall(
      RequestType.POST,
      url,
      body,
      header
    );
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: false },
    });
    if (status === 200) {
      dispatch({
        type: ActionType.EDIT_TESTIMONIAL,
        payload: {
          editTestimonial: {
            statusCode: 200,
            responseData: data.responseData,
          },
        },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Testimonial edited successfully",
          snackBarSeverity: Constant.SEVERITY_SUCCESS,
          apiError: true,
        },
      });
    } else {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: data.responseData.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.SHOW_SNACKBAR,
      payload: {
        showSnackBar: true,
        snackBarMessage: error?.response?.data?.responseData?.message,
        snackBarSeverity: Constant.SEVERITY_ERROR,
        apiError: true,
      },
    });
  }
};

const makeNetworkCallDeleteTestimonial = (url, body, header) => async (dispatch) => {
  try {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    const { status, data } = await MakeNewtworkCall(
      RequestType.DELETE,
      url,
      body,
      header
    );
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: false },
    });
    if (status === 200) {
      dispatch({
        type: ActionType.DELETE_TESTIMONIAL,
        payload: {
          deleteTestimonial: {
            statusCode: 200,
            responseData: data.responseData,
          },
        },
      });
    } else {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: data.responseData.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.SHOW_SNACKBAR,
      payload: {
        showSnackBar: true,
        snackBarMessage: error?.response?.data?.responseData?.message,
        snackBarSeverity: Constant.SEVERITY_ERROR,
        apiError: true,
      },
    });
  }
};

const makeNetworkCallGetAllTestimonial = (url, body, header) => async (dispatch) => {
  try {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    const { status, data } = await MakeNewtworkCall(
      RequestType.GET,
      url,
      body,
      header
    );
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: false },
    });
    if (status === 200) {
      dispatch({
        type: ActionType.GET_TESTIMONIAL,
        payload: {
          getTestimonial: {
            statusCode: 200,
            responseData: data.responseData,
          },
        },
      });
    } else {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: data.responseData.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.SHOW_SNACKBAR,
      payload: {
        showSnackBar: true,
        snackBarMessage: error?.response?.data?.responseData?.message,
        snackBarSeverity: Constant.SEVERITY_ERROR,
        apiError: true,
      },
    });
  }
};

export {
  makeNetworkCallAddCourseMappingDrNeeraj,
  makeNetworkCallCreateEvent,
  makeNetworkCallDeleteEvent,
  makeNetworkCallGetAllEvent,
  makeNetworkCallCreateTestimonial,
  makeNetworkCallDeleteTestimonial,
  makeNetworkCallGetAllTestimonial,
  makeNetworkCallEditTestimonial,
  makeNetworkCallEditEvent
};
