import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updatedSideNavigationModel } from "../../redux/action/action";
import store from "../../redux/Store";
import "../side-navigation-model/SideNavigationModel";
import "./Sidebar.css";

export const SideNavigation = () => {
  const navBarItems = store.getState().sideNavigationReducer.navItems;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleOnClick = (link, key) => {
    dispatch(updatedSideNavigationModel(key));
    if (link === "/dashboard/logout") {
      localStorage.clear();
      dispatch({
        type: "LOGOUT"
      })
      navigate("/login");
    } else {
      navigate(link);
    }
  };

  return (
    <div className="sidebar">
      <div className="logo-name">
        <img
          src={require("../../assets/image/reproneet_learning_logo.png")}
          alt=""
          height={50}
          width={50}
        />
        <h2 className="logo-title">Reproneet</h2>
      </div>
      <div className="divider"></div>
      <div className="navbar-child">
        <ul className="sidebar-list">
          {navBarItems.map((val, key) => {
            return (
              <li
                onClick={() => handleOnClick(val.link, key)}
                key={key}
                className={val.active ? "list-item-active" : "list-item"}
              >
                <div className={val.active ? "icon-active" : "icon"}>
                  {val.icon}
                </div>
                <div className="title">{val.title}</div>
              </li>
            );
          })}
        </ul>
        <p
          style={{
            alignSelf: "center",
            fontFamily: "FuturaCondensed",
            opacity: "0.5",
            color: "white",
          }}
        ></p>
      </div>
      <span className="copyright-phynlabz-tech-pvt-ltd">&copy; PhynLabz Tech Pvt Ltd : 2023</span>
    </div>
  );
};
