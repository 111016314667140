import Lottie from "react-lottie-player";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent, Button } from "@mui/material";
import "./PublishUnpublishSuccessfulPopup.css";
import happyFaceAnimation from "../../../../assets/lottie/happy-face-animation.json";
import sadFaceAnimation from "../../../../assets/lottie/sad-face-animation.json";
const PublishUnPublishSuccessfulPopup = ({
  show,
  message,
  isPublished,
  onDoneClick,
}) => {
  return (
    <>
      <Dialog open={show}>
        <DialogTitle>
          <div className="publish-successful-container">
            {isPublished && (
              <Lottie
                loop
                animationData={happyFaceAnimation}
                play
                style={{ width: 150, height: 150 }}
              />
            )}

            {!isPublished && (
              <Lottie
                loop
                animationData={sadFaceAnimation}
                play
                style={{ width: 150, height: 150 }}
              />
            )}

            <p className="published-message">{message}</p>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="button-published-container">
            <Button
              variant="contained"
              size="small"
              color="success"
              onClick={onDoneClick}
            >
              Done
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { PublishUnPublishSuccessfulPopup };
