import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import CreateEventPopup from "./create-event-popup/CreateEventPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  makeNetworkCallDeleteEvent,
  makeNetworkCallGetAllEvent,
} from "../../redux/action/SettingsAction";
import { NetworkConstant } from "../../network/NetworkConstant";
import { ActionType } from "../../redux/action/actionType";
import { BlueLoader } from "../../util/AppUtils";
import { ShowSnackBar } from "../../snackbar/SnackBar";
import EditEventPopup from "./edit-event-popup/EditEventPopup";
import { Edit } from "@mui/icons-material";
const Events = () => {
  const dispatch = useDispatch();
  const getEventReducer = useSelector((state) => state.settingsReducer);
  const apiState = useSelector((state) => state.apiStateReducer);
  const [createEventPopupProps, setCreateEventPopupProps] = useState({
    show: false,
  });
  const [editEventPopupProps, setEditEventPopupProps] = useState({
    show: false,
  });

  const handleCreateEvent = () => {
    setCreateEventPopupProps({
      show: true,
      onCancelCreateEventPopup: () =>
        setCreateEventPopupProps({
          show: false,
        }),
      onResponseOfCreateEventPopup: (response) => {
        setCreateEventPopupProps({
          show: false,
        });
        dispatch({
          type: ActionType.RESET_GET_EVENT,
          payload: {},
        });
        dispatch(
          makeNetworkCallGetAllEvent(NetworkConstant.getAllEvents, {}, {})
        );
      },
    });
  };

  useEffect(() => {
    dispatch(makeNetworkCallGetAllEvent(NetworkConstant.getAllEvents, {}, {}));
    return () => {
      dispatch({
        type: ActionType.RESET_GET_EVENT,
        payload: {},
      });
    };
  }, []);

  useEffect(() => {
    if (getEventReducer?.deleteEvent?.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_DELETE_EVENT,
        payload: {},
      });
      dispatch({
        type: ActionType.RESET_GET_EVENT,
        payload: {},
      });
      dispatch(
        makeNetworkCallGetAllEvent(NetworkConstant.getAllEvents, {}, {})
      );
    }
  }, [getEventReducer?.deleteEvent?.statusCode]);

  const deleteEvent = (eventId) => {
    const updatedUrlWithEventId = `${NetworkConstant.deleteEvent}${eventId}`;
    dispatch(makeNetworkCallDeleteEvent(updatedUrlWithEventId, {}, {}));
  };

  const editEvent = (eventId, title, thumbnail) => {
    setEditEventPopupProps({
      show: true,
      eventId: eventId,
      title: title,
      thumbnail: thumbnail,
      onCancelEditEventPopup: () =>
        setEditEventPopupProps({
          show: false,
        }),
      onResponseOfEditEventPopup: (response) => {
        setEditEventPopupProps({
          show: false,
        });
        dispatch({
          type: ActionType.RESET_GET_EVENT,
          payload: {},
        });
        dispatch(
          makeNetworkCallGetAllEvent(NetworkConstant.getAllEvents, {}, {})
        );
      },
    });
  };

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-2">
        {getEventReducer?.getEvent?.responseData?.map((data, key) => {
          return (
            <div
              key={key}
              className="relative rounded-lg bg-gray-200 p-2 m-3"
              style={{ height: "300px", width: "100%", maxWidth: "500px" }}
            >
              {/* Image */}
              <img
                src={data?.thumbnail}
                alt={data?.title}
                className="w-full h-full object-fill rounded-lg"
              />

              {/* Event Title */}
              <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-60 text-white p-3 rounded-b-lg">
                <p className="text-lg font-semibold truncate">{data?.title}</p>
              </div>

              {/* Tooltip Button Over the Image */}
              <button
                className="absolute top-2 right-2 bg-gray-300 text-white p-2 rounded-full hover:bg-red-600"
                title="Delete event"
                onClick={() => {
                  deleteEvent(data.id);
                }}
              >
                <DeleteIcon />
              </button>
              <button
                className="absolute top-2 right-14 bg-gray-300 text-white p-2 rounded-full hover:bg-blue-600"
                title="Edit event"
                onClick={() => {
                  editEvent(data.id, data.title, data.thumbnail);
                }}
              >
                <Edit />
              </button>
            </div>
          );
        })}
      </div>
      <div className="plus-icon" onClick={handleCreateEvent}>
        <img src={require("../../assets/image/plus-icon.png")} />
      </div>
      <CreateEventPopup {...createEventPopupProps} />
      <EditEventPopup {...editEventPopupProps} />
      {apiState.showLoader && <BlueLoader />}
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};
export default Events;
