import Tab from "@mui/material/Tab";
import { useState } from "react";
import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Notification } from "../NotificationForAllUsers/Notification";
import { NotificationForCartUsers } from "../NotificationForCartUsers/NotificationForCartUsers";
import { EmailForAllUsers } from "../EmailForAllUsers/EmailForAllUsers";

const NotificationManager = () => {
  const [value, setValue] = useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext
      value={value}
      textColor="secondary"
      indicatorColor="secondary"
      aria-label="secondary tabs example"
    >
      <TabList onChange={handleChange} variant="fullWidth" className="tab-list">
        <Tab
          value="one"
          label={<span className="font-change-style">All Users</span>}
          className="tab-style"
        />
        <Tab
          value="two"
          label={<span className="font-change-style">Cart Pending</span>}
          className="tab-style"
        />
        <Tab
          value="three"
          label={<span className="font-change-style">Email</span>}
          className="tab-style"
        />
      </TabList>
      <TabPanel value="one">
        <Notification />
      </TabPanel>
      <TabPanel value="two">
        <NotificationForCartUsers />
      </TabPanel>
      <TabPanel value="three">
        <EmailForAllUsers />
      </TabPanel>
    </TabContext>
  );
};

export default NotificationManager;
