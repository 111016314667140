import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent, FormControlLabel } from "@mui/material";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import "./EditQuestionPopup.css";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import {
  DataStoreKey,
  getDataStoreItem,
} from "../../../../persistence/PersistenceStorage";
import { Constant } from "../../../../constants/Constant";
import { EditQuestionUploadFilesPopup } from "./edit-question-upload-files-popup/EditQuestionUploadFilesPopup";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "../../../../redux/action/actionType";
import { ShowSnackBar } from "../../../../snackbar/SnackBar";
import { BlueLoader } from "../../../../util/AppUtils";
import { makeNetworkCallForEditQuestion } from "../../../../redux/action/action";
import { RequestType } from "../../../../network/RequestType";
import { NetworkConstant } from "../../../../network/NetworkConstant";
const EditQuestionPopup = ({
  show,
  questionData,
  onCancelClick,
  onUpdateQuestionResponse,
}) => {
  const dispatch = useDispatch();
  const [questionImageResponseList, setQuestionImageResponseList] = useState(
    []
  );
  const [questionTitle, setQuestionTitle] = useState(null);
  const [optionOne, setOptionOne] = useState(null);
  const [optionTwo, setOptionTwo] = useState(null);
  const [optionThree, setOptionThree] = useState(null);
  const [optionFour, setOptionFour] = useState(null);
  const [description, setDescription] = useState(null);
  const [questionTitleErrorVisible, setQuestionTitleErrorVisible] =
    useState(false);
  const [optionOneErrorVisible, setOptionOneErrorVisible] = useState(false);
  const [optionTwoErrorVisible, setOptionTwoErrorVisible] = useState(false);
  const [optionThreeErrorVisible, setOptionThreeErrorVisible] = useState(false);
  const [optionFourErrorVisible, setOptionFourErrorVisible] = useState(false);
  const [descriptionErrorVisible, setDescriptionErrorVisible] = useState(false);
  const [isCorrectOptionOne, setIsCorrectOptionOne] = useState(false);
  const [isCorrectOptionTwo, setIsCorrectOptionTwo] = useState(false);
  const [isCorrectOptionThree, setIsCorrectOptionThree] = useState(false);
  const [isCorrectOptionFour, setIsCorrectOptionFour] = useState(false);
  const [
    editQuestionUploadFilesPopupProps,
    setEditQuestionUploadFilesPopupProps,
  ] = useState({
    show: false,
    questionImageList: [],
    optionOneImageList: [],
    optionTwoImageList: [],
    optionThreeImageList: [],
    optionFourImageList: [],
    descriptionImageList: [],
  });
  const apiState = useSelector((state) => state.apiStateReducer);
  const courseReducer = useSelector((state) => state.courseReducer);

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  //? handle change question title
  const handleOnChangeQuestionTitle = (event) => {
    setQuestionTitle(event.target.value);
  };

  //? handle change option one
  const handleOnChangeOptionOne = (event) => {
    setOptionOne(event.target.value);
  };

  //? handle change option two
  const handleOnChangeOptionTwo = (event) => {
    setOptionTwo(event.target.value);
  };

  //? handle change option three
  const handleOnChangeOptionThree = (event) => {
    setOptionThree(event.target.value);
  };

  //? handle change option four
  const handleOnChangeOptionFour = (event) => {
    setOptionFour(event.target.value);
  };

  //? handle change description
  const handleOnChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  //? handle close edit question popup

  const handleOnCloseUpdateQuestion = () => {
    onCancelClick();
  };

  //? handle response of edit question

  useEffect(() => {
    if (courseReducer.editQuestion.statusCode === 200) {
      onUpdateQuestionResponse(true);
      dispatch({
        type: ActionType.RESET_EDIT_QUESTION,
        payload: {},
      });
    }
  }, [courseReducer.editQuestion.statusCode]);

  //? handle upload image popup
  const handleUploadImagePopup = () => {
    var questionImageList = [];
    var optionOneImageList = [];
    var optionTwoImageList = [];
    var optionThreeImageList = [];
    var optionFourImageList = [];
    var descriptionImageList = [];
    if (questionData != undefined) {
      for (var i = 0; i < questionData?.questionImageUrl.length; i++) {
        questionImageList.push(questionData?.questionImageUrl[i]);
      }
      for (
        var i = 0;
        i < questionData.optionRequest[0]?.optionImageUrl.length;
        i++
      ) {
        optionOneImageList.push(
          questionData.optionRequest[0]?.optionImageUrl[i]
        );
      }
      for (
        var i = 0;
        i < questionData.optionRequest[1]?.optionImageUrl.length;
        i++
      ) {
        optionTwoImageList.push(
          questionData.optionRequest[1]?.optionImageUrl[i]
        );
      }
      for (
        var i = 0;
        i < questionData.optionRequest[2]?.optionImageUrl.length;
        i++
      ) {
        optionThreeImageList.push(
          questionData.optionRequest[2]?.optionImageUrl[i]
        );
      }
      for (
        var i = 0;
        i < questionData.optionRequest[3]?.optionImageUrl.length;
        i++
      ) {
        optionFourImageList.push(
          questionData.optionRequest[3]?.optionImageUrl[i]
        );
      }
      for (var i = 0; i < questionData.descriptionImageUrl.length; i++) {
        descriptionImageList.push(questionData.descriptionImageUrl[i]);
      }
      setEditQuestionUploadFilesPopupProps({
        show: true,
        questionImageList: questionImageList,
        optionOneImageList: optionOneImageList,
        optionTwoImageList: optionTwoImageList,
        optionThreeImageList: optionThreeImageList,
        optionFourImageList: optionFourImageList,
        descriptionImageList: descriptionImageList,
        onCancelImageUploadClick: () =>
          setEditQuestionUploadFilesPopupProps({
            show: false,
            questionImageList: [],
            optionOneImageList: [],
            optionTwoImageList: [],
            optionThreeImageList: [],
            optionFourImageList: [],
            descriptionImageList: [],
          }),
        onUploadImageResponse: (response) => {
          setEditQuestionUploadFilesPopupProps({
            show: false,
            questionImageList: [],
            optionOneImageList: [],
            optionTwoImageList: [],
            optionThreeImageList: [],
            optionFourImageList: [],
            descriptionImageList: [],
          });
          setQuestionImageResponseList(response);
          dispatch({
            type: ActionType.RESET_EDIT_QUESTION_IMAGE,
            payload: {},
          });
        },
      });
    }
  };

  const handleSaveButton = () => {
    if (questionData !== null) {
      if (checkForAllValues()) {
        //? variables to handle the file id
        var descriptionValue = "";
        var questionFileId = "";
        var optionOneFileId = "";
        var optionTwoFileId = "";
        var optionThreeFileId = "";
        var optionFourFileId = "";
        var descriptionFileId = "";
        var questionImageUrl = [];
        var optionOneImageUrl = [];
        var optionTwoImageUrl = [];
        var optionThreeImageUrl = [];
        var optionFourImageUrl = [];
        var descriptionImageUrl = [];

        for (var i = 0; i < questionData.optionRequest.length; i++) {
          if (questionData.optionRequest[i]?.correct) {
            if (
              description === null ||
              description === undefined ||
              description === ""
            ) {
              descriptionValue = questionData?.optionRequest[i]?.description;
            }
          }
        }

        for (var i = 0; i < questionImageResponseList.length; i++) {
          if (questionImageResponseList[i].type === "QuestionImage") {
            questionFileId =
              questionFileId + questionImageResponseList[i].id + ",";
            let questionFileUrl = {
              url: questionImageResponseList[i].imageUrl,
            };
            questionImageUrl.push(questionFileUrl);
          } else if (questionImageResponseList[i].type === "Option1Image") {
            optionOneFileId = optionOneFileId + questionImageResponseList[i].id;
            let optionOneFileUrl = {
              url: questionImageResponseList[i].imageUrl,
            };
            optionOneImageUrl.push(optionOneFileUrl);
          } else if (questionImageResponseList[i].type === "Option2Image") {
            optionTwoFileId = optionTwoFileId + questionImageResponseList[i].id;
            let optionTwoFileUrl = {
              url: questionImageResponseList[i].imageUrl,
            };
            optionTwoImageUrl.push(optionTwoFileUrl);
          } else if (questionImageResponseList[i].type === "Option3Image") {
            optionThreeFileId =
              optionThreeFileId + questionImageResponseList[i].id;
            let optionThreeFileUrl = {
              url: questionImageResponseList[i].imageUrl,
            };
            optionThreeImageUrl.push(optionThreeFileUrl);
          } else if (questionImageResponseList[i].type === "Option4Image") {
            optionFourFileId =
              optionFourFileId + questionImageResponseList[i].id;
            let optionFourFileUrl = {
              url: questionImageResponseList[i].imageUrl,
            };
            optionFourImageUrl.push(optionFourFileUrl);
          } else if (questionImageResponseList[i].type === "DescriptionImage") {
            descriptionFileId =
              descriptionFileId + questionImageResponseList[i].id + ",";
            let descriptionFileUrl = {
              url: questionImageResponseList[i].imageUrl,
            };
            descriptionImageUrl.push(descriptionFileUrl);
          }
        }
        questionFileId = questionFileId.slice(0, -1);
        descriptionFileId = descriptionFileId.slice(0, -1);
        let questionRequest = {
          optionRequest: [
            {
              optionName:
                optionOne === null
                  ? (questionData.optionRequest[0] === undefined ? "" : questionData.optionRequest[0].optionName)
                  : optionOne,
              description: isCorrectOptionOne
                ? description === null
                  ? descriptionValue
                  : description
                : "",
              correct: isCorrectOptionOne,
              optionFileId: optionOneFileId,
              optionId: questionData.optionRequest[0] === undefined ? null : questionData.optionRequest[0].optionId,
              //optionImageUrl: optionOneImageUrl,
            },
            {
              optionName:
                optionTwo === null
                  ? (questionData.optionRequest[1] === undefined ? "" : questionData.optionRequest[1].optionName)
                  : optionTwo,
              description: isCorrectOptionTwo
                ? description === null
                  ? descriptionValue
                  : description
                : "",
              correct: isCorrectOptionTwo,
              optionFileId: optionTwoFileId,
              optionId: questionData.optionRequest[1] === undefined ? null : questionData.optionRequest[1].optionId,
              //optionImageUrl: optionTwoImageUrl,
            },
            {
              optionName:
                optionThree === null
                  ? (questionData.optionRequest[2] === undefined ? "" : questionData.optionRequest[2].optionName)
                  : optionThree,
              description: isCorrectOptionThree
                ? description === null
                  ? descriptionValue
                  : description
                : "",
              correct: isCorrectOptionThree,
              optionFileId: optionThreeFileId,
              optionId: questionData.optionRequest[2] === undefined ? null : questionData.optionRequest[2].optionId,
              // optionImageUrl: optionThreeImageUrl,
            },
            {
              optionName:
                optionFour === null
                  ? (questionData.optionRequest[3] === undefined ? "" : questionData.optionRequest[3].optionName)
                  : optionFour,
              description: isCorrectOptionFour
                ? description === null
                  ? descriptionValue
                  : description
                : "",
              correct: isCorrectOptionFour,
              optionFileId: optionFourFileId,
              optionId: questionData.optionRequest[3] === undefined ? null : questionData.optionRequest[3].optionId,
              //optionImageUrl: optionFourImageUrl,
            },
          ],
          questionName:
            questionTitle === null ? questionData.questionName : questionTitle,
          questionFileId: questionFileId,
          descriptionId: descriptionFileId,
          questionId: questionData.questionId,
          // questionImageUrl: questionImageUrl,
          //descriptionImageUrl: descriptionImageUrl,
        };

        console.log(questionRequest);

        dispatch(
          makeNetworkCallForEditQuestion(
            RequestType.POST,
            NetworkConstant.editQuestion,
            questionRequest,
            {}
          )
        );
      }
    }
  };

  //? check for all values

  const checkForAllValues = () => {
    let isValid = true;
    if (questionTitle === "") {
      isValid = false;
      setQuestionTitleErrorVisible(true);
    }
    if (optionOne === "") {
      isValid = false;
      setOptionOneErrorVisible(true);
    }
    if (optionTwo === "") {
      isValid = false;
      setOptionTwoErrorVisible(true);
    }
    if (optionThree === "") {
      isValid = false;
      setOptionThreeErrorVisible(true);
    }
    if (optionFour === "") {
      isValid = false;
      setOptionFourErrorVisible(true);
    }
    if (description === "") {
      isValid = false;
      setDescriptionErrorVisible(true);
    }
    if (
      isCorrectOptionOne === false &&
      isCorrectOptionTwo === false &&
      isCorrectOptionThree === false &&
      isCorrectOptionFour === false
    ) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Please choose one correct option",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: false,
        },
      });
      isValid = false;
    }
    return isValid;
  };

  const inputPropsStyle = {
    inputProp: {
      style: { fontFamily: Constant.FUTURA_CONDENSED },
    },
  };

  useEffect(() => {
    if (show) {
      setQuestionTitle(null);
      setOptionOne(null);
      setOptionTwo(null);
      setOptionThree(null);
      setOptionFour(null);
      setDescription(null);
      setQuestionTitleErrorVisible(false);
      setOptionOneErrorVisible(false);
      setOptionTwoErrorVisible(false);
      setOptionThreeErrorVisible(false);
      setOptionFourErrorVisible(false);
      setDescriptionErrorVisible(false);
      setIsCorrectOptionOne(false);
      setIsCorrectOptionTwo(false);
      setIsCorrectOptionThree(false);
      setIsCorrectOptionFour(false);
      setQuestionImageResponseList([]);
    }
  }, [show]);

  useEffect(() => {
    if (questionData !== undefined) {
      for (var i = 0; i < questionData.optionRequest.length; i++) {
        if (questionData.optionRequest[i].correct) {
          if (i === 0) {
            setIsCorrectOptionOne(true);
          } else if (i === 1) {
            setIsCorrectOptionTwo(true);
          } else if (i === 2) {
            setIsCorrectOptionThree(true);
          } else if (i === 3) {
            setIsCorrectOptionFour(true);
          }
        }
      }
    }
  }, [questionData]);

  return (
    <>
      <Dialog open={show} fullScreen={true}>
        <AppBar
          sx={{ position: "relative" }}
          style={{
            backgroundColor: "green",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOnCloseUpdateQuestion}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              <span>Close</span>
            </Typography>
            <Typography sx={{ ml: 2, flex: 2 }} variant="h6" component="div">
              <span>
                Chapter : {getDataStoreItem(DataStoreKey.CHAPTER_NAME)}
              </span>
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSaveButton}>
              <span>save</span>
            </Button>
            <Tooltip title="Upload Image" arrow>
              <Fab
                aria-label="add"
                size="small"
                style={{
                  backgroundColor: "orange",
                  marginLeft: "10px",
                }}
                onClick={() => handleUploadImagePopup()}
              >
                <AddIcon
                  style={{
                    color: "white",
                  }}
                />
              </Fab>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <DialogTitle>
          <TextField
            className="edit-question-popup-input-field-container"
            error={questionTitleErrorVisible}
            id="outlined-multiline-static"
            label={<span>Title</span>}
            inputProps={inputPropsStyle.inputProp}
            multiline
            rows={5}
            helperText={
              questionTitleErrorVisible ? (
                <span>Please enter question title</span>
              ) : (
                ""
              )
            }
            onChange={handleOnChangeQuestionTitle}
            value={
              questionTitle === null
                ? questionData !== undefined && questionData.questionName
                : questionTitle
            }
          />
          <TextField
            style={{
              marginTop: "15px",
            }}
            className="edit-question-popup-input-field-container"
            error={optionOneErrorVisible}
            id="outlined-error-helper-text"
            label={<span>Option 1</span>}
            inputProps={inputPropsStyle.inputProp}
            multiline
            rows={3}
            helperText={
              optionOneErrorVisible ? <span>Please enter option one</span> : ""
            }
            onChange={handleOnChangeOptionOne}
            value={
              optionOne === null
                ? questionData !== undefined &&
                  questionData?.optionRequest[0]?.optionName
                : optionOne
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isCorrectOptionOne}
                onClick={() => setIsCorrectOptionOne(!isCorrectOptionOne)}
              />
            }
            label={<span>Correct</span>}
          />
          <TextField
            className="edit-question-popup-input-field-container"
            error={optionTwoErrorVisible}
            id="outlined-error-helper-text"
            label={<span>Option 2</span>}
            inputProps={inputPropsStyle.inputProp}
            multiline
            rows={3}
            helperText={
              optionTwoErrorVisible ? <span>Please enter option two</span> : ""
            }
            onChange={handleOnChangeOptionTwo}
            value={
              optionTwo === null
                ? questionData !== undefined &&
                  questionData?.optionRequest[1]?.optionName
                : optionTwo
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isCorrectOptionTwo}
                onClick={() => setIsCorrectOptionTwo(!isCorrectOptionTwo)}
              />
            }
            label={<span>Correct</span>}
          />
          <TextField
            className="edit-question-popup-input-field-container"
            error={optionThreeErrorVisible}
            id="outlined-error-helper-text"
            label={<span>Option 3</span>}
            inputProps={inputPropsStyle.inputProp}
            multiline
            rows={3}
            helperText={
              optionThreeErrorVisible ? (
                <span>Please enter option three</span>
              ) : (
                ""
              )
            }
            onChange={handleOnChangeOptionThree}
            value={
              optionThree === null
                ? questionData !== undefined &&
                  questionData?.optionRequest[2]?.optionName
                : optionThree
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isCorrectOptionThree}
                onClick={() => setIsCorrectOptionThree(!isCorrectOptionThree)}
              />
            }
            label={<span>Correct</span>}
          />
          <TextField
            className="edit-question-popup-input-field-container"
            error={optionFourErrorVisible}
            id="outlined-error-helper-text"
            label={<span>Option 4</span>}
            inputProps={inputPropsStyle.inputProp}
            multiline
            rows={3}
            helperText={
              optionFourErrorVisible ? (
                <span>Please enter option four</span>
              ) : (
                ""
              )
            }
            onChange={handleOnChangeOptionFour}
            value={
              optionFour === null
                ? questionData !== undefined &&
                  questionData?.optionRequest[3]?.optionName
                : optionFour
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isCorrectOptionFour}
                onClick={() => setIsCorrectOptionFour(!isCorrectOptionFour)}
              />
            }
            label={<span>Correct</span>}
          />

          {questionData !== undefined &&
            questionData.optionRequest !== undefined &&
            questionData.optionRequest.length !== 0 &&
            questionData.optionRequest.map((val, key) => {
              return (
                <div>
                  {val.correct && (
                    <TextField
                      key={key}
                      className="edit-question-popup-input-field-container"
                      error={descriptionErrorVisible}
                      id="outlined-multiline-static"
                      label={<span>Description</span>}
                      inputProps={inputPropsStyle.inputProp}
                      multiline
                      rows={6}
                      helperText={
                        descriptionErrorVisible ? (
                          <span>Please enter description</span>
                        ) : (
                          ""
                        )
                      }
                      onChange={handleOnChangeDescription}
                      value={
                        description === null ? val.description : description
                      }
                    />
                  )}
                </div>
              );
            })}
        </DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
      <EditQuestionUploadFilesPopup {...editQuestionUploadFilesPopupProps} />
      {apiState.showLoader && <BlueLoader />}
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};

export { EditQuestionPopup };
