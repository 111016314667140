import React, { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import loginBackgroundAnimation from "../assets/lottie/login-background-animation.json";
import "./LoginScreen.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import { validateEmail } from "../util/AppUtils";
import { useDispatch, useSelector } from "react-redux";
import { makeNewtworkCallLogin } from "../redux/action/action";
import { RequestType } from "../network/RequestType";
import { NetworkConstant } from "../network/NetworkConstant";
import {
  DataStoreKey,
  getDataStoreItem,
} from "../persistence/PersistenceStorage";
import { useNavigate } from "react-router-dom";
import { ActionType } from "../redux/action/actionType";
import Alert from "@mui/material/Alert";
import { TypeAnimation } from "react-type-animation";
import LinearProgress from "@mui/material/LinearProgress";


const LoginScreen = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userResponse = useSelector((state) => state.userReducer);
  const errorVisible = useSelector(
    (state) => state.apiStateReducer.errorVisible
  );
  const errorMessage = useSelector(
    (state) => state.apiStateReducer.errorMessage
  );
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleOnChangePassword = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    if (getDataStoreItem(DataStoreKey.IS_LOGGED_IN)) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    setLoading(false);
    if (userResponse.isLoggedIn && userResponse.isAdmin) {
      dispatch({
        type: ActionType.ERROR_VISIBLE,
        payload: { errorVisible: false },
      });
      navigate("/dashboard");
    } else if (userResponse.isLoggedIn && !userResponse.isAdmin) {
      dispatch({
        type: ActionType.ERROR_VISIBLE,
        payload: { errorVisible: true },
      });
      dispatch({
        type: ActionType.ERROR_MESSAGE,
        payload: { errorMessage: "Please login with admin credentials." },
      });
      dispatch({
        type: ActionType.RESET_LOGIN,
        payload: {},
      });
    }
  }, [userResponse.isLoggedIn]);

  //? handle login button click
  const handleLoginClick = () => {
    if (!validateEmail(email)) {
      setEmailError(true);
    } else if (password === "") {
      setPasswordError(true);
    } else {
      hitLoginApi();
    }
  };

  //? login api call
  const hitLoginApi = () => {
    setLoading(true);
    dispatch({
      type: ActionType.ERROR_VISIBLE,
      payload: { errorVisible: false },
    });
    const body = {
      email: email,
      password: password,
      deviceType: "WEB"
    };
    dispatch(
      makeNewtworkCallLogin(RequestType.POST, NetworkConstant.signIn, body, {})
    );
  };

  //? handle value change for email and password { useEffect }
  useEffect(() => {
    setEmailError(false);
  }, [email]);

  useEffect(() => {
    setPasswordError(false);
  }, [password]);

  useEffect(() => {
    setLoading(false);
  }, [errorVisible]);

  return (
    <>
      <div className="content-container">
        <div className="logo-container">
          <img
            src={require("../assets/image/reproneet_learning_logo.png")}
            alt=""
            height={100}
            width={100}
          />
          <h1 className="login-logo-title">Reproneet Learning</h1>
        </div>
        <div className="content-container-title">
          <h1>
            Unlock your potential and achieve success <br></br>with our
            e-learning platform designed to help you
          </h1>
          <TypeAnimation
            sequence={[
              "Learn...", // Types 'One'
              1000, // Waits 1s
              "Grow...", // Deletes 'One' and types 'Two'
              1000, // Waits 2s
              "Thrive...",
              1000, // Types 'Three' without deleting 'Two'
            ]}
            wrapper="span"
            cursor={true}
            repeat={Infinity}
            style={{
              fontSize: "3em",
              display: "inline-block",
              color: "orange",
            }}
          />
          <LinearProgress
            color="success"
            style={{
              marginTop: "20px",
            }}
          />
        </div>
      </div>
      <div className="login-screen-container">
        <div className="login-background-lottie">
          <Lottie loop animationData={loginBackgroundAnimation} play />
        </div>
        <div className="login-form-container">
          {errorVisible && (
            <Alert variant="filled" severity="error">
              <span>{errorMessage}</span>
            </Alert>
          )}
          <TextField
            error={emailError}
            color="info"
            id="outlined-error-helper-text"
            label={<span className="font-text">Username</span>}
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            helperText={
              emailError ? (
                <span className="email-password-error">
                  Please enter valid email id
                </span>
              ) : (
                ""
              )
            }
            onChange={handleOnChangeEmail}
            value={email}
          />
          <FormControl
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            variant="outlined"
            color="info"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              <span className="font-text">Password</span>
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              error={passwordError}
              onChange={handleOnChangePassword}
              value={password}
            />
            <FormHelperText id="outlined-weight-helper-text">
              {passwordError ? (
                <span className="email-password-error">
                  Please enter password
                </span>
              ) : (
                ""
              )}
            </FormHelperText>
          </FormControl>
          <Stack
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
            direction="row"
            spacing={1}
          >
            <LoadingButton
              loading={loading}
              color="success"
              loadingPosition="start"
              startIcon={<LockOpenIcon />}
              variant="contained"
              onClick={handleLoginClick}
            >
              <span className="font-text">Login</span>
            </LoadingButton>
          </Stack>
        </div>
      </div>
    </>
  );
};

export { LoginScreen };
