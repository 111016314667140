import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent, Fab } from "@mui/material";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShowSnackBar } from "../../../snackbar/SnackBar";
import { makeNetworkCallUpdateSection } from "../../../redux/action/action";
import { RequestType } from "../../../network/RequestType";
import { NetworkConstant } from "../../../network/NetworkConstant";
import { ActionType } from "../../../redux/action/actionType";
import { BlueLoader, inputPropsStyle } from "../../../util/AppUtils";
import AddIcon from "@mui/icons-material/Add";
import { useRef } from "react";

const EditSectionPopup = ({
  show,
  sectionName,
  descriptionName,
  imageUrlProps,
  sectionId,
  courseId,
  onCancelClick,
  onResponse,
}) => {
  const [titleErrorVisible, setTitleErrorVisible] = useState(false);
  const [descriptionErrorVisible, setDescriptionErrorVisible] = useState(false);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.apiStateReducer);
  const courseReducer = useSelector((state) => state.courseReducer);
  const [image, setImage] = useState("");
  const imageUpload = useRef(null);

  //? handle on change image upload method
  const handleOnChangeImageUpload = (event) => {
    setImage(event.target.files[0]);
    const imageSrc = URL.createObjectURL(event.target.files[0]);
    const imagePreviewElement = document.querySelector("#image-upload-preview");
    imagePreviewElement.src = imageSrc;
  };

  useEffect(() => {
    if (show) {
      setTitle(null);
      setDescription(null);
    }
  }, [show]);

  const customStyles = {
    content: {
      width: "60%",
      height: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "auto",
      marginBottom: "auto",
      borderRadius: "10px",
      border: "none",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      display: "flex",
      flexDirection: "column",
      paddingLeft: "5%",
      paddingRight: "5%",
    },
    input: {
      border: "none",
      outline: "none",
    },
  };

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  useEffect(() => {
    setTitleErrorVisible(false);
  }, [title]);

  useEffect(() => {
    setDescriptionErrorVisible(false);
  }, [description]);

  const handleOnSave = () => {
    if (checkForValues()) {
      let createSectionRequest = {
        courseId: courseId,
        name: title === null ? sectionName : title,
        description: description === null ? descriptionName : description,
        sectionId: sectionId,
      };
      let formData = new FormData();
      formData.append("image", image === "" ? null : image);
      formData.append(
        "createSectionRequestJson",
        JSON.stringify(createSectionRequest)
      );
      dispatch(
        makeNetworkCallUpdateSection(
          RequestType.POST,
          NetworkConstant.updateSection,
          formData,
          {}
        )
      );
    }
  };

  useEffect(() => {
    if (courseReducer.updateSection.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_UPDATE_SECTION,
        payload: {},
      });
      onResponse(true);
    }
  }, [courseReducer.updateSection.statusCode]);

  const checkForValues = () => {
    var isAllValid = true;
    if (title === "" || title === undefined) {
      isAllValid = false;
      setTitleErrorVisible(true);
    }
    if (description === "" || description === undefined) {
      isAllValid = false;
      setDescriptionErrorVisible(true);
    }
    return isAllValid;
  };

  const handleOnChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleOnChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  return (
    <>
      <Dialog style={customStyles} open={show}>
        <DialogTitle className="section-dialog-title">
          <TextField
            error={titleErrorVisible}
            id="outlined-error-helper-text"
            label={<span>Title</span>}
            inputProps={inputPropsStyle.inputProp}
            style={{
              marginTop: "5%",
            }}
            helperText={
              titleErrorVisible ? <span>Please enter title</span> : ""
            }
            onChange={handleOnChangeTitle}
            value={title === null ? sectionName : title}
          />
          <TextField
            error={descriptionErrorVisible}
            id="outlined-multiline-static"
            label={<span>Description</span>}
            multiline
            inputProps={inputPropsStyle.inputProp}
            rows={5}
            style={{
              marginTop: "5%",
            }}
            helperText={
              descriptionErrorVisible ? (
                <span>Please enter description</span>
              ) : (
                ""
              )
            }
            onChange={handleOnChangeDescription}
            value={description === null ? descriptionName : description}
          />
          <div className="add-image">
            <img
              id="image-upload-preview"
              src={imageUrlProps}
              height={100}
              width={100}
              style={{
                borderRadius: "10px",
              }}
            />

            <Fab
              onClick={() => imageUpload.current.click()}
              style={{
                width: "20%",
                height: "40px",
                borderRadius: "5px",
                marginTop: "3%",
                backgroundColor: "#ffbd03",
                alignSelf: "center",
                marginLeft: "10px",
                fontFamily: "FuturaCondensed",
                textAlignment: "center",
                letterSpacing: "1px",
              }}
            >
              <AddIcon
                style={{
                  marginRight: "5px",
                }}
              />
              Add Image
            </Fab>
            <input
              hidden
              className="file-thumbnail"
              type="file"
              onChange={handleOnChangeImageUpload}
              ref={imageUpload}
            />
          </div>
        </DialogTitle>
        <DialogContent className="section-dialog-content">
          <button
            className="button-style-manage-section"
            onClick={onCancelClick}
          >
            Cancel
          </button>
          <button
            onClick={handleOnSave}
            className="button-style-manage-section"
          >
            Save
          </button>
        </DialogContent>
      </Dialog>
      {apiState.showLoader && <BlueLoader />}
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};

export { EditSectionPopup };
