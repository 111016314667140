import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { NetworkConstant } from "../../../network/NetworkConstant";
import { ActionType } from "../../../redux/action/actionType";
import { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { ShowSnackBar } from "../../../snackbar/SnackBar";
import { BlueLoader } from "../../../util/AppUtils";
import {
  makeNetworkCallCreateTestimonial,
} from "../../../redux/action/SettingsAction";
import { Constant } from "../../../constants/Constant";

const CreateTestimonialPopup = ({
  show,
  onCancelCreateTestimonialPopup,
  onResponseOfCreateTestimonialPopup,
}) => {
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.apiStateReducer);
  const settingsReducer = useSelector((state) => state.settingsReducer);
  const [testimonialCreateImage, setTestimonialCreateImage] = useState(null);
  const [testimonialUploadVideo, setTestimonialUploadVideo] = useState(null);
  const [testimonialTitle, setTestimonialTitle] = useState("");
  const [testimonialDescription, setTestimonialDescription] = useState("");
  const [titleErrorVisible, setTitleErrorVisible] = useState(false);
  const [descriptionErrorVisible, setDescriptionErrorVisible] = useState(false);
  const testimonialImageUpload = useRef(null);
  const testimonialVideoUpload = useRef(null);

  const handleOnChangeTestimonialImage = (event) => {
    setTestimonialCreateImage(event.target.files[0]);
    const imageSrc = URL.createObjectURL(event.target.files[0]);
    const imagePreviewElement = document.querySelector("#image-upload-preview");
    imagePreviewElement.src = imageSrc;
  };

  const handleOnChangeTestimonialVideo = (event) => {
    setTestimonialUploadVideo(event.target.files[0]);
  };

  const handleSaveCreateTestimonial = () => {
    if (checkForValues()) {
      let formData = new FormData();
      formData.append("thumbnail", testimonialCreateImage);
      formData.append("video", testimonialUploadVideo);
      dispatch(
        makeNetworkCallCreateTestimonial(
          `${NetworkConstant.createTestimonial}?title=${testimonialTitle}&description=${testimonialDescription}`,
          formData,
          {}
        )
      );
    }
  };

  useEffect(() => {
    setTitleErrorVisible(false);
  }, [testimonialTitle]);

  useEffect(() => {
    setDescriptionErrorVisible(false);
  }, [testimonialDescription]);

  const checkForValues = () => {
    if (testimonialTitle === "") {
      setTitleErrorVisible(true);
      return false;
    }
    if (testimonialDescription === "") {
      setDescriptionErrorVisible(true);
      return false;
    }
    if (testimonialCreateImage === null) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Please upload image",
          snackBarSeverity: Constant.SEVERITY_ERROR,
        },
      });
      return false;
    }
    if (testimonialUploadVideo === null) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Please upload video",
          snackBarSeverity: Constant.SEVERITY_ERROR,
        },
      });
      return false;
    }
    return true;
  };

  const handleCancelCreateTestimonial = () => {
    setTestimonialCreateImage(null);
    setTestimonialUploadVideo(null);
    setTestimonialTitle("");
    setTestimonialDescription("");
    setTitleErrorVisible(false);
    setDescriptionErrorVisible(false);
    onCancelCreateTestimonialPopup();
  };

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  useEffect(() => {
    if (settingsReducer.createTestimonial?.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_CREATE_TESTIMONIAL,
        payload: {},
      });
      setTestimonialCreateImage(null);
      setTestimonialUploadVideo(null);
      setTestimonialTitle("");
      setTestimonialDescription("");
      setTitleErrorVisible(false);
      setDescriptionErrorVisible(false);
      onResponseOfCreateTestimonialPopup(true);
    }
  }, [settingsReducer.createTestimonial?.statusCode]);

  const handleOnChangeTitle = (event) => {
    setTestimonialTitle(event.target.value);
  };

  const handleOnChangeDescription = (event) => {
    setTestimonialDescription(event.target.value);
  };

  return (
    <>
      <Dialog open={show}>
        <DialogTitle className="section-create-chapter-dialog">
          <TextField
            error={titleErrorVisible}
            id="outlined-error-helper-text"
            label="Title"
            style={{
              marginTop: "5%",
            }}
            helperText={titleErrorVisible ? "Please enter title" : ""}
            onChange={handleOnChangeTitle}
            value={testimonialTitle}
          />
          <TextField
            error={descriptionErrorVisible}
            id="outlined-error-helper-text"
            label="Description"
            style={{
              marginTop: "5%",
            }}
            helperText={
              descriptionErrorVisible ? "Please enter description" : ""
            }
            onChange={handleOnChangeDescription}
            value={testimonialDescription}
          />
          <div className="add-image">
            <img
              id="image-upload-preview"
              src={require("../../../assets/image/thumbnail-background.png")}
              height={100}
              width={100}
              style={{
                borderRadius: "10px",
              }}
            />

            <Fab
              onClick={() => testimonialImageUpload.current.click()}
              style={{
                width: "20%",
                height: "40px",
                borderRadius: "5px",
                marginTop: "3%",
                backgroundColor: "#ffbd03",
                alignSelf: "center",
                marginLeft: "10px",
                fontFamily: "FuturaCondensed",
                textAlignment: "center",
                letterSpacing: "1px",
              }}
            >
              <AddIcon
                style={{
                  marginRight: "5px",
                }}
              />
              Add Image
            </Fab>
            <input
              hidden
              className="file-thumbnail"
              type="file"
              onChange={handleOnChangeTestimonialImage}
              ref={testimonialImageUpload}
            />

            <Fab
              onClick={() => testimonialVideoUpload.current.click()}
              style={{
                width: "20%",
                height: "40px",
                borderRadius: "5px",
                marginTop: "3%",
                backgroundColor: "#ffbd03",
                alignSelf: "center",
                marginLeft: "10px",
                fontFamily: "FuturaCondensed",
                textAlignment: "center",
                letterSpacing: "1px",
              }}
            >
              <AddIcon
                style={{
                  marginRight: "5px",
                }}
              />
              Add Video
            </Fab>
            <input
              hidden
              className="file-thumbnail"
              type="file"
              onChange={handleOnChangeTestimonialVideo}
              ref={testimonialVideoUpload}
            />
          </div>
        </DialogTitle>
        <DialogContent className="section-dialog-content">
          <button
            className="button-style-manage-section"
            onClick={handleCancelCreateTestimonial}
          >
            Cancel
          </button>
          <button
            onClick={handleSaveCreateTestimonial}
            className="button-style-manage-section"
          >
            Save
          </button>
        </DialogContent>
      </Dialog>
      {apiState.showLoader && <BlueLoader />}
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};

export default CreateTestimonialPopup;
