import { ActionType } from "../action/actionType";

const initialState = {
  addCourseMapping: {
    statusCode: 0,
    responseData: "",
  },
  createEvent: {
    statusCode: 0,
    responseData: "",
  },
  editEvent: {
    statusCode: 0,
    responseData: "",
  },
  deleteEvent: {
    statusCode: 0,
    responseData: "",
  },
  getEvent: {
    statusCode: 0,
    responseData: [],
  },
  createTestimonial: {
    statusCode: 0,
    responseData: "",
  },
  editTestimonial: {
    statusCode: 0,
    responseData: "",
  },
  deleteTestimonial: {
    statusCode: 0,
    responseData: "",
  },
  getTestimonial: {
    statusCode: 0,
    responseData: [],
  },
};

export const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.ADD_COURSE_MAPPING_DR_NEERAJ: {
      return {
        ...state,
        addCourseMapping: {
          statusCode: action.payload.addCourseMapping.statusCode,
          responseData: [...action.payload.addCourseMapping.responseData],
        },
      };
    }
    case ActionType.RESET_ADD_COURSE_MAPPING_DR_NEERAJ: {
      return {
        ...state,
        addCourseMapping: {
          statusCode: 0,
          responseData: "",
        },
      };
    }
    case ActionType.CREATE_EVENT: {
      return {
        ...state,
        createEvent: {
          statusCode: action.payload.createEvent.statusCode,
          responseData: action.payload.createEvent.responseData,
        },
      };
    }
    case ActionType.RESET_CREATE_EVENT: {
      return {
        ...state,
        createEvent: {
          statusCode: 0,
          responseData: "",
        },
      };
    }
    case ActionType.EDIT_EVENT: {
      return {
        ...state,
        editEvent: {
          statusCode: action.payload.editEvent.statusCode,
          responseData: action.payload.editEvent.responseData,
        },
      };
    }
    case ActionType.RESET_EDIT_EVENT: {
      return {
        ...state,
        editEvent: {
          statusCode: 0,
          responseData: "",
        },
      };
    }
    case ActionType.GET_EVENT: {
      return {
        ...state,
        getEvent: {
          statusCode: action.payload.getEvent.statusCode,
          responseData: action.payload.getEvent.responseData,
        },
      };
    }
    case ActionType.RESET_GET_EVENT: {
      return {
        ...state,
        getEvent: {
          statusCode: 0,
          responseData: [],
        },
      };
    }
    case ActionType.DELETE_EVENT: {
      return {
        ...state,
        deleteEvent: {
          statusCode: action.payload.deleteEvent.statusCode,
          responseData: action.payload.deleteEvent.responseData,
        },
      };
    }
    case ActionType.RESET_DELETE_EVENT: {
      return {
        ...state,
        deleteEvent: {
          statusCode: 0,
          responseData: "",
        },
      };
    }
    case ActionType.CREATE_TESTIMONIAL: {
      return {
        ...state,
        createTestimonial: {
          statusCode: action.payload.createTestimonial.statusCode,
          responseData: action.payload.createTestimonial.responseData,
        },
      };
    }
    case ActionType.RESET_CREATE_TESTIMONIAL: {
      return {
        ...state,
        createTestimonial: {
          statusCode: 0,
          responseData: "",
        },
      };
    }
    case ActionType.EDIT_TESTIMONIAL: {
      return {
        ...state,
        editTestimonial: {
          statusCode: action.payload.editTestimonial.statusCode,
          responseData: action.payload.editTestimonial.responseData,
        },
      };
    }
    case ActionType.RESET_EDIT_TESTIMONIAL: {
      return {
        ...state,
        editTestimonial: {
          statusCode: 0,
          responseData: "",
        },
      };
    }
    case ActionType.GET_TESTIMONIAL: {
      return {
        ...state,
        getTestimonial: {
          statusCode: action.payload.getTestimonial.statusCode,
          responseData: action.payload.getTestimonial.responseData,
        },
      };
    }
    case ActionType.RESET_GET_TESTIMONIAL: {
      return {
        ...state,
        getTestimonial: {
          statusCode: 0,
          responseData: [],
        },
      };
    }
    case ActionType.DELETE_TESTIMONIAL: {
      return {
        ...state,
        deleteTestimonial: {
          statusCode: action.payload.deleteTestimonial.statusCode,
          responseData: action.payload.deleteTestimonial.responseData,
        },
      };
    }
    case ActionType.RESET_DELETE_TESTIMONIAL: {
      return {
        ...state,
        deleteTestimonial: {
          statusCode: 0,
          responseData: "",
        },
      };
    }
    default:
      return state;
  }
};
