import { SideNavigationModel } from "../../dashboard/side-navigation-model/SideNavigationModel";
import { ActionType } from "../action/actionType";

const initialState = {
  navItems: SideNavigationModel,
};

export const SideNavigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.UPDATED_SIDE_NAVIGATION_VALUE:
      return { ...state, navItems: action.payload };
    default:
      return state;
  }
};
