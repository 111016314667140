import React from "react";
import { TailSpin } from "react-loader-spinner";
import { Constant } from "../constants/Constant";

export function validateEmail(text) {
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (emailFormat.test(text)) {
    return true;
  } else {
    return false;
  }
}

 export const inputPropsStyle = {
   inputProp: {
     style: { fontFamily: Constant.FUTURA_CONDENSED },
   },
 };


export const BlueLoader = () => {
  const customStyle = {
    blueloader: {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "200px",
      height: "auto",
      zIndex: "9999"
    },
  };

  return (
    <div className="blueloader" style={customStyle.blueloader}>
      <TailSpin
        height="60"
        width="60"
        color="blue"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        
      />
    </div>
  );
};
