import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  makeNetworkCallGetAllQuestion,
  makeNetworkCallGetQuestionById,
} from "../../redux/action/action";
import { NetworkConstant } from "../../network/NetworkConstant";
import { ActionType } from "../../redux/action/actionType";
import { BlueLoader, inputPropsStyle } from "../../util/AppUtils";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import "./ManageResource.css";
import Checkbox from "@mui/material/Checkbox";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CopyQuestionsAndUploadPopupForAll } from "./copy-questions-and-upload-for-all/CopyQuestionsAndUploadPopupForAll";
import { Fab, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EditQuestionPopup } from "../manage-chapters/chapter-popup/EditQuestionPopup/EditQuestionPopup";
import {
  DataStoreKey,
  setDataStoreItem,
} from "../../persistence/PersistenceStorage";

const ManageResources = () => {
  const dispatch = useDispatch();
  const questionReducer = useSelector((state) => state.courseReducer);
  const [filterQuestion, setFilterQuestion] = useState("");
  const apiState = useSelector((state) => state.apiStateReducer);
  const [showLoadingEntireQuestion, setShowLoadingEntireQuestion] =
    useState(false);
  const [showLoadingSelectedQuestion, setShowLoadingSelectedQuestion] =
    useState(false);
  const navigate = useNavigate();
  const [
    copyQuestionsAndUploadPopupProps,
    setCopyQuestionsAndUploadPopupProps,
  ] = useState({
    show: false,
    questionIdList: [],
  });
  const [editQuestionPopupProps, setEditQuestionPopupProps] = useState({
    show: false,
    questionId: "",
  });

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.RESET_GET_ALL_QUESTION,
        payload: {},
      });
    };
  }, []);

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  //? handle search for question
  const handleQuestionSearch = () => {
    if (filterQuestion !== undefined && filterQuestion !== "") {
      dispatch({
        type: ActionType.RESET_GET_ALL_QUESTION,
        payload: {},
      });
      dispatch(
        makeNetworkCallGetAllQuestion(
          filterQuestion,
          "",
          "",
          NetworkConstant.getAllQuestions,
          {},
          {}
        )
      );
    }
  };

  //? handle copy questions click
  const handleCopyQuestionsClick = () => {
    let questionIdList = [];
    for (
      var i = 0;
      i <
      questionReducer?.getAllQuestion?.responseData?.questionResponse?.length;
      i++
    ) {
      if (
        questionReducer?.getAllQuestion?.responseData?.questionResponse?.[i]
          ?.isSelected !== undefined &&
        questionReducer?.getAllQuestion?.responseData?.questionResponse?.[i]
          ?.isSelected
      ) {
        questionIdList.push(
          questionReducer?.getAllQuestion?.responseData?.questionResponse?.[i]
            .questionId
        );
      }
    }
    setCopyQuestionsAndUploadPopupProps({
      show: true,
      questionIdList: questionIdList,
      onCancelCopyQuestionsAndUploadPopup: () =>
        setCopyQuestionsAndUploadPopupProps({
          show: false,
          questionIdList: [],
        }),
      onResponseOfCancelCopyQuestionsAndUploadPopup: (response) => {
        setCopyQuestionsAndUploadPopupProps({
          show: false,
          questionIdList: [],
        });
        setShowLoadingEntireQuestion(false);
        setShowLoadingSelectedQuestion(false);
      },
    });
  };

  //? handle copy questions click for bulk upload
  const handleCopyQuestionsClickForEntireQuestions = () => {
    let questionIdList = [];
    for (
      var i = 0;
      i <
      questionReducer?.getAllQuestion?.responseData?.questionResponse?.length;
      i++
    ) {
      questionIdList.push(
        questionReducer?.getAllQuestion?.responseData?.questionResponse?.[i]
          .questionId
      );
    }
    setCopyQuestionsAndUploadPopupProps({
      show: true,
      questionIdList: questionIdList,

      onCancelCopyQuestionsAndUploadPopup: () =>
        setCopyQuestionsAndUploadPopupProps({
          show: false,
          questionIdList: [],
        }),
      onResponseOfCancelCopyQuestionsAndUploadPopup: (response) => {
        setCopyQuestionsAndUploadPopupProps({
          show: false,
          questionIdList: [],
        });
        setShowLoadingEntireQuestion(false);
        setShowLoadingSelectedQuestion(false);
      },
    });
  };

  //? handle checkbox
  const handleToggle = (value) => () => {
    const questionReducerCopy = { ...questionReducer?.getAllQuestion };
    const updatedQuestionData =
      questionReducerCopy?.responseData?.questionResponse[value];
    if (updatedQuestionData) {
      if (updatedQuestionData.isSelected === undefined) {
        updatedQuestionData.isSelected = true;
      } else {
        updatedQuestionData.isSelected = !updatedQuestionData.isSelected;
      }
    }
  };

  // handle edit question click
  const handleEditQuestionClick = (questionId) => {
    dispatch(
      makeNetworkCallGetQuestionById(
        questionId,
        NetworkConstant.getQuestionByQuestionId,
        {},
        {}
      )
    );
  };

  // handle api response of get question by id
  useEffect(() => {
    if (questionReducer.getQuestionById?.statusCode === 200) {
      setDataStoreItem(DataStoreKey.CHAPTER_NAME, "");
      handleEditQuestion(questionReducer.getQuestionById?.responseData[0]);
      dispatch({
        type: ActionType.RESET_GET_QUESTION_BY_ID,
        payload: {},
      });
    }
  }, [questionReducer.getQuestionById?.statusCode]);

  //? handle edit question
  const handleEditQuestion = (questionData) => {
    setEditQuestionPopupProps({
      show: true,
      questionData: questionData,
      onCancelClick: () => setEditQuestionPopupProps({ show: false }),
      onUpdateQuestionResponse: (response) => {
        setEditQuestionPopupProps({ show: false });
      },
    });
  };

  return (
    <>
      <div className="manage-resource-container">
        <TextField
          error={false}
          inputProps={inputPropsStyle.inputProp}
          id="outlined-error-helper-text"
          label={<span>Search</span>}
          rows={20}
          value={filterQuestion}
          onChange={(event) => setFilterQuestion(event.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton edge="end" onClick={handleQuestionSearch}>
                <ManageSearchIcon />
              </IconButton>
            ),
          }}
        />
        {apiState.showLoader && <BlueLoader />}
        <div className="action-container">
          {
            <span className="total-no-of-question">
              Total no of questions :{" "}
              {
                questionReducer?.getAllQuestion?.responseData?.questionResponse
                  ?.length
              }
            </span>
          }
          <Stack
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
            direction="row"
            spacing={1}
          >
            <LoadingButton
              loading={showLoadingEntireQuestion}
              loadingPosition="start"
              startIcon={<CloudUploadIcon />}
              variant="contained"
              onClick={handleCopyQuestionsClickForEntireQuestions}
            >
              <span>Upload entire question</span>
            </LoadingButton>
          </Stack>
          <Stack
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
            direction="row"
            spacing={1}
          >
            <LoadingButton
              loading={showLoadingSelectedQuestion}
              loadingPosition="start"
              startIcon={<CloudUploadIcon />}
              variant="contained"
              onClick={handleCopyQuestionsClick}
            >
              <span>Upload selected question</span>
            </LoadingButton>
          </Stack>
        </div>
        {questionReducer?.getAllQuestion?.responseData?.questionResponse?.map(
          (questionData, key) => {
            return (
              <div key={key} className="question-container-column">
                <div className="question-container">
                  <span>
                    {key + 1}.) {questionData?.questionName}
                  </span>
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(key)}
                    checked={questionData?.isSelected}
                  />
                </div>
                <Tooltip
                  title="Edit Question"
                  arrow
                  style={{
                    alignSelf: "flex-end",
                  }}
                >
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="edit question"
                    onClick={() => {
                      handleEditQuestionClick(questionData?.questionId);
                    }}
                  >
                    <EditIcon />
                  </Fab>
                </Tooltip>
              </div>
            );
          }
        )}
      </div>
      <CopyQuestionsAndUploadPopupForAll
        {...copyQuestionsAndUploadPopupProps}
      />
      <EditQuestionPopup {...editQuestionPopupProps} />
    </>
  );
};

export default ManageResources;
