import { Constant } from "../../constants/Constant";
import { MakeNewtworkCall } from "../../network/BaseAxios";
import { RequestType } from "../../network/RequestType";
import { ActionType } from "./actionType";

const makeNetworkCallGetAllPayment = (url, body, header) => async (dispatch) => {
  dispatch({
    type: ActionType.SHOW_LOADER,
    payload: { showLoader: true },
  });
  try {
    const { status, data } = await MakeNewtworkCall(
      RequestType.GET,
      url,
      body,
      header
    );
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: false },
    });
    if (status === 200) {
      dispatch({
        type: ActionType.GET_ALL_PAYMENTS,
        payload: {
          getAllPayments: {
            statusCode: 200,
            responseData: data.responseData,
          },
        },
      });
    } else {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: data.responseData.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.SHOW_SNACKBAR,
      payload: {
        showSnackBar: true,
        snackBarMessage: error?.response?.data?.responseData?.message,
        snackBarSeverity: Constant.SEVERITY_ERROR,
        apiError: true,
      },
    });
  }
};

export { makeNetworkCallGetAllPayment };
