import deleteLottieJson from "../../../../assets/lottie/delete_animation.json";
import Lottie from "react-lottie-player";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import "./DeleteChapterPopup.css";
import { useDispatch, useSelector } from "react-redux";
import { makeNetworkCallDeleteChapterById } from "../../../../redux/action/action";
import { RequestType } from "../../../../network/RequestType";
import { NetworkConstant } from "../../../../network/NetworkConstant";
import { BlueLoader } from "../../../../util/AppUtils";
import { ShowSnackBar } from "../../../../snackbar/SnackBar";
import { useEffect } from "react";
import { ActionType } from "../../../../redux/action/actionType";

const DeleteChapterPopup = ({
  show,
  sectionId,
  chapterId,
  chapterName,
  onCancelClick,
  onResponse,
}) => {
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.apiStateReducer);
  const courseReducer = useSelector((state) => state.courseReducer);

  const handleOnClickOfYes = () => {
    dispatch(
      makeNetworkCallDeleteChapterById(
        RequestType.DELETE,
        NetworkConstant.deleteChapterSectionMapping,
        sectionId,
        chapterId,
        {},
        {}
      )
    );
  };

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  useEffect(() => {
    if (courseReducer.deleteChapterById.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_DELETE_CHAPTER_BY_ID,
        payload: {},
      });
      onResponse(true)
    }
  }, [courseReducer.deleteChapterById.statusCode]);

  return (
    <>
      <Dialog open={show}>
        <DialogTitle>
          <div className="delete-container">
            <Lottie
              loop
              animationData={deleteLottieJson}
              play
              style={{ width: 150, height: 150 }}
            />

            <p className="delete-message">
              Are you sure you want to delete ?
              {<p className="chapter-name-style">{chapterName}</p>}
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="button-container-delete">
            <button className="button-style-no" onClick={onCancelClick}>
              No
            </button>
            <button className="button-style-yes" onClick={handleOnClickOfYes}>
              Yes
            </button>
          </div>
        </DialogContent>
      </Dialog>
      {apiState.showLoader && <BlueLoader />}
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};

export { DeleteChapterPopup };
