import { Outlet, BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "../home/Home";
import { SideNavigation } from "./side-navigation/SideNaviation";
import "./Dashboard.css";
import { TestComponent } from "../test/TestComponent";

export const Dashboard = () => {
  return (
    <div className="main-dashboard">
      <div
        style={{
          width: "350px",
          height: "95%",
          marginTop: "auto",
          marginBottom: "auto",
          overflow: "hidden",
          marginLeft: "20px",
          backgroundImage:
            "url('https://w0.peakpx.com/wallpaper/401/930/HD-wallpaper-abstract-black-pink-texture.jpg')",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "10px",
        }}
      >
        <SideNavigation />
      </div>
      <div
        style={{
          width: "90%",
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <Outlet></Outlet>
      </div>
    </div>
  );
};
