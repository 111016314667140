import axios from "axios";
import { NetworkConstant } from "../../network/NetworkConstant";
import { ActionType } from "./actionType";
import { Constant } from "../../constants/Constant";
import { MakeNewtworkCall } from "../../network/BaseAxios";

const makeNetworkCallToSendExpoPushNotification =
  (body, header) => async (dispatch) => {
    try {
      const response = await axios.post(
        NetworkConstant.NOTIFICATION_URL,
        body,
        header
      );
      if (response !== null) {
        dispatch({
          type: ActionType.SEND_NOTIFICATION_THROUGH_EXPO_SERVER,
          payload: {
            sendNotificationToAllDeviceTypeForExpoApi: {
              statusCode: 200,
              responseMessage: Constant.NOTIFICATION_SENT_SUCCESSFULLY,
            },
          },
        });
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: Constant.NOTIFICATION_SENT_SUCCESSFULLY,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
        },
      });
    }
  };

//? make network call to send notification to cart pending user
const makeNetworkCallSendNotificationToCartPendingUser =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      if (status === 200) {
        dispatch({
          type: ActionType.SEND_NOTIFICATION_TO_USER_ON_CART_BASIS,
          payload: {
            sendNotificationToUserOnCartBasis: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
        },
      });
    }
  };

//? make network call to get all published courses
const makeNetworkCallGetAllPublishedCourse =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      const updatedUrl = `${endPoint}?size=100&page=0`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedUrl,
        body,
        header
      );
      if (status === 200) {
        dispatch({
          type: ActionType.GET_ALL_PUBLISHED_COURSE,
          payload: {
            getAllPublishedCourse: {
              responseData: data.responseData.courseResponse,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
        },
      });
    }
  };

//? make network call to send email to all user
const makeNetworkCallForSendEmailToAllUser =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      if (status === 200) {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
          },
        });
        dispatch({
          type: ActionType.SEND_EMAIL_TO_ALL_USER,
          payload: {
            sendEmailForAllUser: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
        },
      });
    }
  };

//? make network call to get email of all user
const makeNetworkCallForGetEmailForAllUser =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      if (status === 200) {
        dispatch({
          type: ActionType.GET_ALL_EMAIL,
          payload: {
            getAllEmail: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
        },
      });
    }
  };

//? make network call for send email to all user
const makeNetworkCallToSendEmailToAllUser =
  (body, header) => async (dispatch) => {
    try {
      const response = await axios.post(
        NetworkConstant.EMAIL_URL,
        body,
        header
      );
      if (response !== null) {
        dispatch({
          type: ActionType.SEND_EMAIL_TO_USER,
          payload: {
            sendEmailToUser: {
              statusCode: 200,
              responseMessage: Constant.EMAIL_SENT_SUCCESSFULLY,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
        },
      });
    }
  };

//? make network call to save notification
const makeNetworkCallForSaveNotification =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      if (status === 200) {
        dispatch({
          type: ActionType.SAVE_NOTIFICATION,
          payload: {
            saveNotification: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
        },
      });
    }
  };

export {
  makeNetworkCallToSendExpoPushNotification,
  makeNetworkCallSendNotificationToCartPendingUser,
  makeNetworkCallGetAllPublishedCourse,
  makeNetworkCallForSendEmailToAllUser,
  makeNetworkCallForGetEmailForAllUser,
  makeNetworkCallToSendEmailToAllUser,
  makeNetworkCallForSaveNotification,
};
