import { useEffect } from "react";

export const TestComponent = () => {





  return (
    <div className="home">
      <h2 className="home-title">Test.</h2>
    </div>
  );
};
