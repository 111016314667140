import { ActionType } from "../action/actionType";

const initialState = {
  isLoggedIn: false,
  firstName: "",
  lastName: "",
  isAdmin: false,
  authToken: ""
};
export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOGIN:
      return { ...state, isLoggedIn: true, ...action.payload };
    case ActionType.RESET_LOGIN:
      return initialState;
    default:
      return state;
  }
};
