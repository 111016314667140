import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent, Fab } from "@mui/material";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShowSnackBar } from "../../../../snackbar/SnackBar";
import { ActionType } from "../../../../redux/action/actionType";
import { BlueLoader } from "../../../../util/AppUtils";
import { useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import { makeNetworkCallCreateChapterBySectionId } from "../../../../redux/action/action";
import { RequestType } from "../../../../network/RequestType";
import { NetworkConstant } from "../../../../network/NetworkConstant";

const EditChapterPopup = ({
  show,
  titleProps,
  descriptionProps,
  requiredTrophiesProps,
  imageUrlProps,
  chapterId,
  sectionId,
  onCancelClick,
  onResponseUpdateChapter,
}) => {
  const [titleErrorVisible, setTitleErrorVisible] = useState(false);
  const [descriptionErrorVisible, setDescriptionErrorVisible] = useState(false);
  const [requiredTrophiesErrorVisible, setRequiredTrophiesErrorVisible] =
    useState(false);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [requiredTrophies, setRequiredTrophies] = useState(null);
  const [image, setImage] = useState("");
  const imageUpload = useRef(null);
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.apiStateReducer);
  const courseReducer = useSelector((state) => state.courseReducer);

  const customStyles = {
    content: {
      width: "60%",
      height: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "auto",
      marginBottom: "auto",
      borderRadius: "10px",
      border: "none",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      display: "flex",
      flexDirection: "column",
      paddingLeft: "5%",
      paddingRight: "5%",
    },
    input: {
      border: "none",
      outline: "none",
    },
  };

  useEffect(() => {
    if (show) {
      setTitle(null);
      setDescription(null);
      setRequiredTrophies(null);
    }
  }, [show]);

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  useEffect(() => {
    setTitleErrorVisible(false);
  }, [title]);

  useEffect(() => {
    setDescriptionErrorVisible(false);
  }, [description]);

  useEffect(() => {
    setRequiredTrophiesErrorVisible(false);
  }, [requiredTrophies]);

  const handleOnSave = () => {
    if (checkForValues()) {
      let createChapterRequest = {
        sectionId: sectionId,
        chapterId: chapterId,
        name: title === null ? titleProps : title,
        briefDescription: description === null ? descriptionProps : description,
        requiredTrophies:
          requiredTrophies === null ? requiredTrophiesProps : requiredTrophies,
      };
      let formData = new FormData();
      formData.append("image", image === "" ? null : image);
      formData.append(
        "createChapterRequest",
        JSON.stringify(createChapterRequest)
      );
      dispatch(
        makeNetworkCallCreateChapterBySectionId(
          RequestType.POST,
          NetworkConstant.createChapterBySectionId,
          formData,
          {}
        )
      );
    }
  };

  useEffect(() => {
    if (courseReducer.createChapterBySectionId.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_CREATE_CHAPTER_BY_SECTION_ID,
        payload: {},
      });
      onResponseUpdateChapter(true);
    }
  }, [courseReducer.createChapterBySectionId.statusCode]);

  const checkForValues = () => {
    var isAllValid = true;
    if (title === "" || title === undefined) {
      isAllValid = false;
      setTitleErrorVisible(true);
    }
    if (description === "" || description === undefined) {
      isAllValid = false;
      setDescriptionErrorVisible(true);
    }
    if (requiredTrophies === "" || requiredTrophies === undefined) {
      isAllValid = false;
      setRequiredTrophiesErrorVisible(true);
    }
    return isAllValid;
  };

  const handleOnChangeImageUpload = (event) => {
    setImage(event.target.files[0]);
    const imageSrc = URL.createObjectURL(event.target.files[0]);
    const imagePreviewElement = document.querySelector("#image-upload-preview");
    imagePreviewElement.src = imageSrc;
  };

  const handleOnChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleOnChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleOnChangeRequiredTrophies = (event) => {
    setRequiredTrophies(event.target.value);
  };

  return (
    <>
      <Dialog style={customStyles} open={show}>
        <DialogTitle className="section-create-chapter-dialog">
          <TextField
            error={titleErrorVisible}
            id="outlined-error-helper-text"
            label="Title"
            style={{
              marginTop: "5%",
            }}
            helperText={titleErrorVisible ? "Please enter title" : ""}
            onChange={handleOnChangeTitle}
            value={title === null ? titleProps : title}
          />
          <TextField
            error={descriptionErrorVisible}
            id="outlined-multiline-static"
            label="Description"
            multiline
            rows={5}
            style={{
              marginTop: "5%",
            }}
            helperText={
              descriptionErrorVisible ? "Please enter description" : ""
            }
            onChange={handleOnChangeDescription}
            value={description === null ? descriptionProps : description}
          />
          <TextField
            error={requiredTrophiesErrorVisible}
            id="outlined-error-helper-text"
            label="Required Trophies"
            style={{
              marginTop: "5%",
            }}
            helperText={
              requiredTrophiesErrorVisible
                ? "Please enter required trophies in number"
                : ""
            }
            onChange={handleOnChangeRequiredTrophies}
            value={
              requiredTrophies === null
                ? requiredTrophiesProps
                : requiredTrophies
            }
          />

          <div className="add-image">
            <img
              id="image-upload-preview"
              src={imageUrlProps}
              height={100}
              width={100}
              style={{
                borderRadius: "10px",
              }}
            />

            <Fab
              onClick={() => imageUpload.current.click()}
              style={{
                width: "20%",
                height: "40px",
                borderRadius: "5px",
                marginTop: "3%",
                backgroundColor: "#ffbd03",
                alignSelf: "center",
                marginLeft: "10px",
                fontFamily: "FuturaCondensed",
                textAlignment: "center",
                letterSpacing: "1px",
              }}
            >
              <AddIcon
                style={{
                  marginRight: "5px",
                }}
              />
              Add Image
            </Fab>
            <input
              hidden
              className="file-thumbnail"
              type="file"
              onChange={handleOnChangeImageUpload}
              ref={imageUpload}
            />
          </div>
        </DialogTitle>
        <DialogContent className="section-dialog-content">
          <button
            className="button-style-manage-section"
            onClick={onCancelClick}
          >
            Cancel
          </button>
          <button
            onClick={handleOnSave}
            className="button-style-manage-section"
          >
            Save
          </button>
        </DialogContent>
      </Dialog>
      {apiState.showLoader && <BlueLoader />}
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};

export { EditChapterPopup };
