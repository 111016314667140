import { ActionType } from "../action/actionType";

const initialState = {
  sendNotificationToAllDeviceType: {
    statusCode: 0,
    responseData: [],
  },
  sendNotificationToUserOnCartBasis: {
    statusCode: 0,
    responseData: [],
  },
  sendNotificationToAllDeviceTypeForExpoApi: {
    statusCode: 0,
    responseMessage: "",
  },
  getAllPublishedCourse: {
    statusCode: 0,
    responseData: [],
  },
  sendEmailForAllUser: {
    statusCode: 0,
    responseData: "",
  },
  getAllEmail: {
    statusCode: 0,
    responseData: [],
  },
  getAllEmail: {
    statusCode: 0,
    responseData: [],
  },
  sendEmailToUser: {
    statusCode: 0,
    responseData: "",
  },
  saveNotification: {
    statusCode: 0,
    responseData: "",
  },
};

export const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SEND_NOTIFICATION_TO_ALL_DEVICE_TYPE: {
      return {
        ...state,
        sendNotificationToAllDeviceType: {
          statusCode: action.payload.sendNotificationToAllDeviceType.statusCode,
          responseData: [
            ...action.payload.sendNotificationToAllDeviceType.responseData,
          ],
        },
      };
    }
    case ActionType.RESET_SEND_NOTIFICATION_TO_ALL_DEVICE_TYPE: {
      return {
        ...state,
        sendNotificationToAllDeviceType: {
          statusCode: 0,
          responseData: [],
        },
      };
    }
    case ActionType.SEND_NOTIFICATION_TO_USER_ON_CART_BASIS: {
      return {
        ...state,
        sendNotificationToUserOnCartBasis: {
          statusCode:
            action.payload.sendNotificationToUserOnCartBasis.statusCode,
          responseData: [
            ...action.payload.sendNotificationToUserOnCartBasis.responseData,
          ],
        },
      };
    }
    case ActionType.RESET_SEND_NOTIFICATION_TO_USER_ON_CART_BASIS: {
      return {
        ...state,
        sendNotificationToUserOnCartBasis: {
          statusCode: 0,
          responseData: [],
        },
      };
    }
    case ActionType.SEND_NOTIFICATION_THROUGH_EXPO_SERVER: {
      return {
        ...state,
        sendNotificationToAllDeviceTypeForExpoApi: {
          statusCode:
            action.payload.sendNotificationToAllDeviceTypeForExpoApi.statusCode,
          responseMessage:
            action.payload.sendNotificationToAllDeviceTypeForExpoApi
              .responseMessage,
        },
      };
    }
    case ActionType.RESET_SEND_NOTIFICATION_THROUGH_EXPO_SERVER: {
      return {
        ...state,
        sendNotificationToAllDeviceTypeForExpoApi: {
          statusCode: 0,
          responseMessage: "",
        },
      };
    }
    case ActionType.GET_ALL_PUBLISHED_COURSE: {
      return {
        ...state,
        getAllPublishedCourse: {
          statusCode: action.payload.getAllPublishedCourse.statusCode,
          responseData: action.payload.getAllPublishedCourse.responseData,
        },
      };
    }
    case ActionType.RESET_GET_ALL_PUBLISHED_COURSE: {
      return {
        ...state,
        getAllPublishedCourse: {
          statusCode: 0,
          responseData: [],
        },
      };
    }
    case ActionType.SEND_EMAIL_TO_ALL_USER: {
      return {
        ...state,
        sendEmailForAllUser: {
          statusCode: action.payload.sendEmailForAllUser.statusCode,
          responseData: action.payload.sendEmailForAllUser.responseData,
        },
      };
    }
    case ActionType.RESET_SEND_EMAIL_TO_ALL_USER: {
      return {
        ...state,
        sendEmailForAllUser: {
          statusCode: 0,
          responseData: "",
        },
      };
    }
    case ActionType.GET_ALL_EMAIL: {
      return {
        ...state,
        getAllEmail: {
          statusCode: action.payload.getAllEmail.statusCode,
          responseData: action.payload.getAllEmail.responseData,
        },
      };
    }
    case ActionType.RESET_GET_ALL_EMAIL: {
      return {
        ...state,
        getAllEmail: {
          statusCode: 0,
          responseData: [],
        },
      };
    }
    case ActionType.SEND_EMAIL_TO_USER: {
      return {
        ...state,
        sendEmailToUser: {
          statusCode: action.payload.sendEmailToUser.statusCode,
          responseData: action.payload.sendEmailToUser.responseData,
        },
      };
    }
    case ActionType.RESET_SEND_EMAIL_TO_USER: {
      return {
        ...state,
        sendEmailToUser: {
          statusCode: 0,
          responseData: "",
        },
      };
    }
    case ActionType.SAVE_NOTIFICATION: {
      return {
        ...state,
        saveNotification: {
          statusCode: action.payload.saveNotification.statusCode,
          responseData: action.payload.saveNotification.responseData,
        },
      };
    }
    case ActionType.RESET_SAVE_NOTIFICATION: {
      return {
        ...state,
        saveNotification: {
          statusCode: 0,
          responseData: "",
        },
      };
    }
    default:
      return state;
  }
};
