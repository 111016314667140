import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { NetworkConstant } from "../../../network/NetworkConstant";
import { ActionType } from "../../../redux/action/actionType";
import { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { ShowSnackBar } from "../../../snackbar/SnackBar";
import { BlueLoader } from "../../../util/AppUtils";
import { makeNetworkCallCreateEvent } from "../../../redux/action/SettingsAction";
import { Constant } from "../../../constants/Constant";

const CreateEventPopup = ({
  show,
  onCancelCreateEventPopup,
  onResponseOfCreateEventPopup,
}) => {
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.apiStateReducer);
  const settingsReducer = useSelector((state) => state.settingsReducer);
  const [eventCreateImage, setEventCreateImage] = useState(null);
  const [eventTitle, setEventTitle] = useState("");
  const [titleErrorVisible, setTitleErrorVisible] = useState(false);
  const eventImageUpload = useRef(null);

  const handleOnChangeEventImage = (event) => {
    setEventCreateImage(event.target.files[0]);
    const imageSrc = URL.createObjectURL(event.target.files[0]);
    const imagePreviewElement = document.querySelector("#image-upload-preview");
    imagePreviewElement.src = imageSrc;
  };

  const handleSaveCreateEvent = () => {
    if (checkForValues()) {
      let formData = new FormData();
      formData.append("thumbnail", eventCreateImage);
      dispatch(
        makeNetworkCallCreateEvent(
          `${NetworkConstant.createEvent}?title=${eventTitle}`,
          formData,
          {}
        )
      );
    }
  };

  useEffect(() => {
    setTitleErrorVisible(false);
  }, [eventTitle]);

  const checkForValues = () => {
    if (eventTitle === "") {
      setTitleErrorVisible(true);
      return false;
    }
    if (eventCreateImage === null) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Please upload image",
          snackBarSeverity: Constant.SEVERITY_ERROR,
        },
      });
      return false;
    }
    return true;
  };

  const handleCancelCreateEvent = () => {
    setEventCreateImage(null);
    setEventTitle("");
    setTitleErrorVisible(false);
    onCancelCreateEventPopup();
  };

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  useEffect(() => {
    if (settingsReducer.createEvent?.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_CREATE_EVENT,
        payload: {},
      });
      setEventCreateImage(null);
      setEventTitle("");
      setTitleErrorVisible(false);
      onResponseOfCreateEventPopup(true);
    }
  }, [settingsReducer.createEvent?.statusCode]);

  const handleOnChangeTitle = (event) => {
    setEventTitle(event.target.value);
  };

  return (
    <>
      <Dialog open={show}>
        <DialogTitle className="section-create-chapter-dialog">
          <TextField
            error={titleErrorVisible}
            id="outlined-error-helper-text"
            label="Title"
            style={{
              marginTop: "5%",
            }}
            helperText={titleErrorVisible ? "Please enter title" : ""}
            onChange={handleOnChangeTitle}
            value={eventTitle}
          />
          <div className="add-image">
            <img
              id="image-upload-preview"
              src={require("../../../assets/image/thumbnail-background.png")}
              height={100}
              width={100}
              style={{
                borderRadius: "10px",
              }}
            />

            <Fab
              onClick={() => eventImageUpload.current.click()}
              style={{
                width: "20%",
                height: "40px",
                borderRadius: "5px",
                marginTop: "3%",
                backgroundColor: "#ffbd03",
                alignSelf: "center",
                marginLeft: "10px",
                fontFamily: "FuturaCondensed",
                textAlignment: "center",
                letterSpacing: "1px",
              }}
            >
              <AddIcon
                style={{
                  marginRight: "5px",
                }}
              />
              Add Image
            </Fab>
            <input
              hidden
              className="file-thumbnail"
              type="file"
              onChange={handleOnChangeEventImage}
              ref={eventImageUpload}
            />
          </div>
        </DialogTitle>
        <DialogContent className="section-dialog-content">
          <button
            className="button-style-manage-section"
            onClick={handleCancelCreateEvent}
          >
            Cancel
          </button>
          <button
            onClick={handleSaveCreateEvent}
            className="button-style-manage-section"
          >
            Save
          </button>
        </DialogContent>
      </Dialog>
      {apiState.showLoader && <BlueLoader />}
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};

export default CreateEventPopup;
