import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ProtectedRoute = ({ render, authentication }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!authentication) {
      navigate("/login");
    }
  }, []);
  return render;
};
