import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { NetworkConstant } from "../../../network/NetworkConstant";
import { ActionType } from "../../../redux/action/actionType";
import { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { ShowSnackBar } from "../../../snackbar/SnackBar";
import { BlueLoader } from "../../../util/AppUtils";
import { makeNetworkCallEditTestimonial } from "../../../redux/action/SettingsAction";

const EditTestimonialPopup = ({
  show,
  title,
  description,
  imageUrl,
  videoUrl,
  testimonialId,
  onCancelEditTestimonialPopup,
  onResponseOfEditTestimonialPopup,
}) => {
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.apiStateReducer);
  const settingsReducer = useSelector((state) => state.settingsReducer);
  const [testimonialEditImage, setTestimonialEditImage] = useState(null);
  const [testimonialUploadVideo, setTestimonialUploadVideo] = useState(null);
  const [testimonialTitle, setTestimonialTitle] = useState(null);
  const [testimonialDescription, setTestimonialDescription] = useState(null);
  const [titleErrorVisible, setTitleErrorVisible] = useState(false);
  const [descriptionErrorVisible, setDescriptionErrorVisible] = useState(false);
  const testimonialImageUpload = useRef(null);
  const testimonialVideoUpload = useRef(null);

  const handleOnChangeTestimonialImage = (event) => {
    setTestimonialEditImage(event.target.files[0]);
    const imageSrc = URL.createObjectURL(event.target.files[0]);
    const imagePreviewElement = document.querySelector("#image-upload-preview");
    imagePreviewElement.src = imageSrc;
  };

  const handleOnChangeTestimonialVideo = (event) => {
    setTestimonialUploadVideo(event.target.files[0]);
  };

  const handleSaveEditTestimonial = () => {
    if (checkForValues()) {
      let formData = new FormData();
      formData.append("thumbnail", testimonialEditImage);
      formData.append("video", testimonialUploadVideo);
      var finalTitle = testimonialTitle === null ? title : testimonialTitle;
      var finalDescription =
        testimonialDescription === null ? description : testimonialDescription;
      dispatch(
        makeNetworkCallEditTestimonial(
          `${NetworkConstant.editTestimonial}?id=${testimonialId}&title=${finalTitle}&description=${finalDescription}`,
          formData,
          {}
        )
      );
    }
  };

  useEffect(() => {
    if (show) {
      setTestimonialEditImage(null);
      setTestimonialUploadVideo(null);
      setTestimonialTitle(null);
      setTestimonialDescription(null);
      setTitleErrorVisible(false);
      setDescriptionErrorVisible(false);
    }
  }, [show]);

  useEffect(() => {
    setTitleErrorVisible(false);
  }, [testimonialTitle]);

  useEffect(() => {
    setDescriptionErrorVisible(false);
  }, [testimonialDescription]);

  const checkForValues = () => {
    if (testimonialTitle === "" || testimonialTitle === undefined) {
      setTitleErrorVisible(true);
      return false;
    }
    if (testimonialDescription === "" || testimonialDescription === undefined) {
      setDescriptionErrorVisible(true);
      return false;
    }
    return true;
  };

  const handleCancelEditTestimonial = () => {
    setTestimonialEditImage(null);
    setTestimonialUploadVideo(null);
    setTestimonialTitle(null);
    setTestimonialDescription(null);
    setTitleErrorVisible(false);
    setDescriptionErrorVisible(false);
    onCancelEditTestimonialPopup();
  };

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  useEffect(() => {
    if (settingsReducer.editTestimonial?.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_EDIT_TESTIMONIAL,
        payload: {},
      });
      setTestimonialEditImage(null);
      setTestimonialUploadVideo(null);
      setTestimonialTitle("");
      setTestimonialDescription("");
      setTitleErrorVisible(false);
      setDescriptionErrorVisible(false);
      onResponseOfEditTestimonialPopup(true);
    }
  }, [settingsReducer.editTestimonial?.statusCode]);

  const handleOnChangeTitle = (event) => {
    setTestimonialTitle(event.target.value);
  };

  const handleOnChangeDescription = (event) => {
    setTestimonialDescription(event.target.value);
  };

  return (
    <>
      <Dialog open={show}>
        <DialogTitle className="section-create-chapter-dialog">
          <TextField
            error={titleErrorVisible}
            id="outlined-error-helper-text"
            label="Title"
            style={{
              marginTop: "5%",
            }}
            helperText={titleErrorVisible ? "Please enter title" : ""}
            onChange={handleOnChangeTitle}
            value={testimonialTitle === null ? title : testimonialTitle}
          />
          <TextField
            error={descriptionErrorVisible}
            id="outlined-error-helper-text"
            label="Description"
            style={{
              marginTop: "5%",
            }}
            helperText={
              descriptionErrorVisible ? "Please enter description" : ""
            }
            onChange={handleOnChangeDescription}
            value={
              testimonialDescription === null
                ? description
                : testimonialDescription
            }
          />
          <div className="add-image">
            <img
              id="image-upload-preview"
              src={
                imageUrl === null
                  ? require("../../../assets/image/thumbnail-background.png")
                  : imageUrl
              }
              height={100}
              width={100}
              style={{
                borderRadius: "10px",
              }}
            />

            <Fab
              onClick={() => testimonialImageUpload.current.click()}
              style={{
                width: "20%",
                height: "40px",
                borderRadius: "5px",
                marginTop: "3%",
                backgroundColor: "#ffbd03",
                alignSelf: "center",
                marginLeft: "10px",
                fontFamily: "FuturaCondensed",
                textAlignment: "center",
                letterSpacing: "1px",
              }}
            >
              <AddIcon
                style={{
                  marginRight: "5px",
                }}
              />
              Edit Image
            </Fab>
            <input
              hidden
              className="file-thumbnail"
              type="file"
              onChange={handleOnChangeTestimonialImage}
              ref={testimonialImageUpload}
            />

            <Fab
              onClick={() => testimonialVideoUpload.current.click()}
              style={{
                width: "20%",
                height: "40px",
                borderRadius: "5px",
                marginTop: "3%",
                backgroundColor: "#ffbd03",
                alignSelf: "center",
                marginLeft: "10px",
                fontFamily: "FuturaCondensed",
                textAlignment: "center",
                letterSpacing: "1px",
              }}
            >
              <AddIcon
                style={{
                  marginRight: "5px",
                }}
              />
              Edit Video
            </Fab>
            <input
              hidden
              className="file-thumbnail"
              type="file"
              onChange={handleOnChangeTestimonialVideo}
              ref={testimonialVideoUpload}
            />
          </div>
        </DialogTitle>
        <DialogContent className="section-dialog-content">
          <button
            className="button-style-manage-section"
            onClick={handleCancelEditTestimonial}
          >
            Cancel
          </button>
          <button
            onClick={handleSaveEditTestimonial}
            className="button-style-manage-section"
          >
            Edit
          </button>
        </DialogContent>
      </Dialog>
      {apiState.showLoader && <BlueLoader />}
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};

export default EditTestimonialPopup;
