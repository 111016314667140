import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeNetworkCallDeleteTestimonial,
  makeNetworkCallGetAllTestimonial,
} from "../../redux/action/SettingsAction";
import { NetworkConstant } from "../../network/NetworkConstant";
import { ActionType } from "../../redux/action/actionType";
import { BlueLoader } from "../../util/AppUtils";
import { ShowSnackBar } from "../../snackbar/SnackBar";
import CreateTestimonialPopup from "./create-testimonial-popup/CreateTestimonialPopup";
import { Edit } from "@mui/icons-material";
import EditTestimonialPopup from "./edit-testimonial-popup/EditTestimonialPopup";
const Testimonails = () => {
  const dispatch = useDispatch();
  const getTestimonialReducer = useSelector((state) => state.settingsReducer);
  const apiState = useSelector((state) => state.apiStateReducer);
  const [createTestimonialPopupProps, setCreateTestimonialPopupProps] =
    useState({
      show: false,
    });

  const [editTestimonialPopupProps, setEditTestimonialPopupProps] = useState({
    show: false,
  });

  const handleCreateTestimonial = () => {
    setCreateTestimonialPopupProps({
      show: true,
      onCancelCreateTestimonialPopup: () =>
        setCreateTestimonialPopupProps({
          show: false,
        }),
      onResponseOfCreateTestimonialPopup: (response) => {
        setCreateTestimonialPopupProps({
          show: false,
        });
        dispatch({
          type: ActionType.RESET_GET_TESTIMONIAL,
          payload: {},
        });
        dispatch(
          makeNetworkCallGetAllTestimonial(
            NetworkConstant.getAllTestimonials,
            {},
            {}
          )
        );
      },
    });
  };

  useEffect(() => {
    dispatch(
      makeNetworkCallGetAllTestimonial(
        NetworkConstant.getAllTestimonials,
        {},
        {}
      )
    );
    return () => {
      dispatch({
        type: ActionType.RESET_GET_TESTIMONIAL,
        payload: {},
      });
    };
  }, []);

  useEffect(() => {
    if (getTestimonialReducer?.deleteTestimonial?.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_DELETE_TESTIMONIAL,
        payload: {},
      });
      dispatch({
        type: ActionType.RESET_GET_TESTIMONIAL,
        payload: {},
      });
      dispatch(
        makeNetworkCallGetAllTestimonial(
          NetworkConstant.getAllTestimonials,
          {},
          {}
        )
      );
    }
  }, [getTestimonialReducer?.deleteTestimonial?.statusCode]);

  const deleteTestimonial = (testimonialId) => {
    const updatedUrlWithTestimonialId = `${NetworkConstant.deleteTestimonial}${testimonialId}`;
    dispatch(
      makeNetworkCallDeleteTestimonial(updatedUrlWithTestimonialId, {}, {})
    );
  };

  const editTestimonial = (
    testimonialId,
    title,
    description,
    imageUrl,
    videoUrl
  ) => {    
    setEditTestimonialPopupProps({
      show: true,
      title: title,
      description: description,
      imageUrl: imageUrl,
      videoUrl: videoUrl,
      testimonialId: testimonialId,
      onCancelEditTestimonialPopup: () =>
        setEditTestimonialPopupProps({
          show: false,
        }),
      onResponseOfEditTestimonialPopup: (response) => {
        setEditTestimonialPopupProps({
          show: false,
        });
        dispatch({
          type: ActionType.RESET_GET_TESTIMONIAL,
          payload: {},
        });
        dispatch(
          makeNetworkCallGetAllTestimonial(
            NetworkConstant.getAllTestimonials,
            {},
            {}
          )
        );
      },
    });
  };

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        {getTestimonialReducer?.getTestimonial?.responseData?.map(
          (data, key) => {
            return (
              <div
                key={key}
                className="relative rounded-lg bg-gray-200 shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 cursor-pointer"
                style={{ height: "300px", width: "100%", maxWidth: "500px" }}
                onClick={() => {
                  if (data.videoUrl) {
                    window.open(data.videoUrl, "_blank");
                  }
                }}
              >
                {/* Image */}
                <img
                  src={data?.thumbnail}
                  className="w-full h-full object-cover rounded-lg"
                />

                {/* Title and Description Overlay */}
                <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black to-transparent text-white p-3 rounded-b-lg">
                  <p className="text-lg font-semibold truncate">
                    {data?.title}
                  </p>
                  <p className="text-sm mt-1 line-clamp-3">
                    {data?.description}
                  </p>{" "}
                  {/* Line clamping for 3 lines */}
                </div>

                {/* Tooltip Button for Deletion */}
                <button
                  className="absolute top-2 right-2 bg-gray-300 text-white p-2 rounded-full hover:bg-red-600"
                  title="Delete Testimonial"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering onClick of the parent div
                    deleteTestimonial(data.id);
                  }}
                >
                  <DeleteIcon />
                </button>
                <button
                  className="absolute top-2 right-14 bg-gray-300 text-white p-2 rounded-full hover:bg-blue-600"
                  title="Edit Testimonial"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering onClick of the parent div
                    editTestimonial(data.id, data.title, data.description, data.thumbnail, data.videoUrl);
                  }}
                >
                  <Edit />
                </button>
              </div>
            );
          }
        )}
      </div>
      <CreateTestimonialPopup {...createTestimonialPopupProps} />
      <EditTestimonialPopup {...editTestimonialPopupProps} />
      <div className="plus-icon" onClick={handleCreateTestimonial}>
        <img src={require("../../assets/image/plus-icon.png")} />
      </div>
      {apiState.showLoader && <BlueLoader />}
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};
export default Testimonails;
