import { useEffect, useState } from "react";
import "./ManageSection.css";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BlueLoader } from "../../util/AppUtils";
import { ActionType } from "../../redux/action/actionType";
import { Alert, Snackbar } from "@mui/material";
import {
  makeNetworkCallCreateChapterBySectionId,
  makeNetworkCallCreateSection,
  makeNetworkCallDeleteSection,
  makeNetworkCallDeleteSectionMapping,
  makeNetworkCallForGetSectionByCourseId,
} from "../../redux/action/action";
import React from "react";
import { NetworkConstant } from "../../network/NetworkConstant";
import { RequestType } from "../../network/RequestType";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import deleteLottieJson from "../../assets/lottie/delete_animation.json";
import Lottie from "react-lottie-player";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import { useRef } from "react";
import { isNumber } from "../../util/CommonUtils";
import { useNavigate } from "react-router-dom";
import { ShowSnackBar } from "../../snackbar/SnackBar";
import { EditSectionPopup } from "./section-popup/EditSectionPopup";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { CopySectionPopup } from "./copy-section-popup/CopySectionPopup";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

const ManageSections = ({ courseId }) => {
  const [title, setTitle] = useState("");
  const [titleCreateChapter, setTitleCreateChapter] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionCreateChapter, setDescriptionCreateChapter] = useState("");
  const [requiredTrophies, setRequiredTrophies] = useState("");
  const [titleErrorVisible, setTitleErrorVisible] = useState(false);
  const [titleErrorVisibleCreateChapter, setTitleErrorVisibleCreateChapter] =
    useState(false);
  const [requiredTrophiesErrorVisible, setRequiredTrophiesErrorVisible] =
    useState(false);
  const [descriptionErrorVisible, setDescriptionErrorVisible] = useState(false);
  const [
    descriptionErrorVisibleCreateChapter,
    setDescriptionErrorVisibleCreateChapter,
  ] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.apiStateReducer);
  const courseReducer = useSelector((state) => state.courseReducer);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteSectionId, setDeleteSectionId] = useState("");
  const [showCreateChapterPopup, setShowCreateChapterPopup] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(null);
  const [sectionImageUploaded, setSectionImageUploaded] = useState(null);
  const [sectionName, setSectionName] = useState("");
  const [editSectionPopupProps, setEditSectionPopupProps] = useState({
    show: false,
    sectionName: "",
    descriptionName: "",
    imageUrlProps: "",
    sectionId: "",
    courseId: "",
  });
  const [copySectionPopupProps, setCopySectionPopupProps] = useState({
    show: false,
    sectionId: "",
    sectionName: "",
  });
  const imageUpload = useRef(null);
  const sectionImageUpload = useRef(null);
  const navigate = useNavigate();

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  useEffect(() => {
    dispatch(
      makeNetworkCallForGetSectionByCourseId(
        courseId,
        RequestType.GET,
        NetworkConstant.getSectionByCourseId,
        {},
        {}
      )
    );

    return () => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: { showSnackBar: false, snackBarMessage: "" },
      });
      dispatch({
        type: ActionType.RESET_SECTION_BY_COURSE_ID,
        payload: {},
      });
    };
  }, []);

  // useEffect(() => {
  //   if (courseReducer.createChapterBySectionId.statusCode === 200) {
  //     navigate()
  //   }
  // }, [courseReducer.createChapterBySectionId.statusCode]);

  //? handle edit section popup props
  const editSectionOpenPopup = (sectionName, description, sectionId, image) => {
    setEditSectionPopupProps({
      show: true,
      sectionName: sectionName,
      descriptionName: description,
      imageUrlProps: image,
      sectionId: sectionId,
      courseId: courseId,
      onCancelClick: () => setEditSectionPopupProps({ show: false }),
      onResponse: (response) => {
        setEditSectionPopupProps({ show: false });
        dispatch({
          type: ActionType.RESET_SECTION_BY_COURSE_ID,
          payload: {},
        });
        dispatch(
          makeNetworkCallForGetSectionByCourseId(
            courseId,
            RequestType.GET,
            NetworkConstant.getSectionByCourseId,
            {},
            {}
          )
        );
      },
    });
  };

  //? handle copy section click
  const handleCopySectionClick = (event, sectionId, sectionName) => {
    setCopySectionPopupProps({
      show: true,
      sectionId: sectionId,
      sectionName: sectionName,
      onCancelCopySectionPopup: () =>
        setCopySectionPopupProps({
          show: false,
          sectionId: "",
          sectionName: "",
        }),
      onResponseCopySectionPopup: (response) =>
        setCopySectionPopupProps({
          show: false,
          sectionId: "",
          sectionName: "",
        }),
    });
  };

  useEffect(() => {
    setTitleErrorVisibleCreateChapter(false);
  }, [titleCreateChapter]);

  useEffect(() => {
    setDescriptionErrorVisibleCreateChapter(false);
  }, [descriptionCreateChapter]);

  useEffect(() => {
    setRequiredTrophiesErrorVisible(false);
  }, [requiredTrophies]);

  useEffect(() => {
    if (courseReducer.createSectionResponse.statusCode === 200) {
      setTitle("");
      setDescription("");
      setShowModal(false);
      dispatch({
        type: ActionType.RESET_SECTION_BY_COURSE_ID,
        payload: {},
      });
      dispatch(
        makeNetworkCallForGetSectionByCourseId(
          courseId,
          RequestType.GET,
          NetworkConstant.getSectionByCourseId,
          {},
          {}
        )
      );
      dispatch({
        type: ActionType.RESET_CREATE_SECTION_RESPONSE,
        payload: {},
      });
    }
  }, [courseReducer.createSectionResponse.statusCode]);

  useEffect(() => {
    setTitleErrorVisible(false);
  }, [title]);

  useEffect(() => {
    setDescriptionErrorVisible(false);
  }, [description]);

  const handleOnClickOfCancel = () => {
    setShowModal(false);
    setDeleteSectionId("");
  };

  const handleOnClickPlusIcon = () => {
    setShowModal(true);
  };

  const handleOnChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleAddChapterClick = (sectionResponse) => {
    setShowCreateChapterPopup(true);
    setDeleteSectionId(sectionResponse.sectionId);
  };

  const handleOnChangeTitleCreateChapter = (event) => {
    setTitleCreateChapter(event.target.value);
  };

  const handleOnChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleOnChangeDescriptionCreateChapter = (event) => {
    setDescriptionCreateChapter(event.target.value);
  };

  const handleOnChangeRequiredTrophies = (event) => {
    setRequiredTrophies(event.target.value);
  };

  const handleCancelCreateChapter = () => {
    setImageUploaded(null);
    setTitleCreateChapter("");
    setDescriptionCreateChapter("");
    setRequiredTrophies("");
    setTitleErrorVisibleCreateChapter(false);
    setDescriptionErrorVisibleCreateChapter(false);
    setRequiredTrophiesErrorVisible(false);
    setShowSnackBar(false);
    setDeleteSectionId("");
    setShowCreateChapterPopup(false);
  };

  const checkForValidations = () => {
    let isError = false;
    if (titleCreateChapter === "") {
      isError = true;
      setTitleErrorVisibleCreateChapter(true);
    }
    if (descriptionCreateChapter === "") {
      isError = true;
      setDescriptionErrorVisibleCreateChapter(true);
    }
    if (requiredTrophies === "" || !isNumber(requiredTrophies)) {
      isError = true;
      setRequiredTrophiesErrorVisible(true);
    }
    if (imageUploaded === null || imageUploaded === "") {
      isError = true;
      setShowSnackBar(true);
    }
    return isError;
  };

  const handleOnSaveCreateChapter = () => {
    if (!checkForValidations()) {
      let createChapterRequest = {
        name: titleCreateChapter,
        briefDescription: descriptionCreateChapter,
        requiredTrophies: parseInt(requiredTrophies),
        sectionId: deleteSectionId,
      };
      let formData = new FormData();
      formData.append("image", imageUploaded);
      formData.append(
        "createChapterRequest",
        JSON.stringify(createChapterRequest)
      );
      dispatch(
        makeNetworkCallCreateChapterBySectionId(
          RequestType.POST,
          NetworkConstant.createChapterBySectionId,
          formData,
          {},
          deleteSectionId
        )
      );
    }
  };

  const handleOnChangeImageUpload = (event) => {
    setImageUploaded(event.target.files[0]);
    setShowSnackBar(false);
    const imageSrc = URL.createObjectURL(event.target.files[0]);
    const imagePreviewElement = document.querySelector("#image-upload-preview");
    imagePreviewElement.src = imageSrc;
  };

  const handleOnChangeSectionUploadImage = (event) => {
    setSectionImageUploaded(event.target.files[0]);
    setShowSnackBar(false);
    const imageSrc = URL.createObjectURL(event.target.files[0]);
    const imagePreviewElement = document.querySelector(
      "#section-image-upload-preview"
    );
    imagePreviewElement.src = imageSrc;
  };

  const handleDeleteSection = (sectionResponse) => {
    setShowDeletePopup(true);
    setSectionName(sectionResponse.sectionName);
    setDeleteSectionId(sectionResponse.sectionId);
  };

  const handleConfirmDelete = () => {
    dispatch(
      makeNetworkCallDeleteSection(
        RequestType.DELETE,
        NetworkConstant.deleteCourseSectionMapping,
        courseId,
        deleteSectionId,
        {},
        {}
      )
    );
  };

  //? actions for speed dial
  const actions = [
    { icon: <FileCopyIcon />, name: "Copy", color: "white" },
    { icon: <EditIcon />, name: "Edit section", color: "blue" },
    { icon: <AddIcon />, name: "Add chapter", color: "blue" },
    { icon: <DeleteIcon />, name: "Delete", color: "red" },
  ];

  useEffect(() => {
    if (courseReducer.deleteSection.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_DELETE_SECTION,
        payload: {},
      });
      dispatch({
        type: ActionType.RESET_SECTION_BY_COURSE_ID,
        payload: {},
      });
      setShowDeletePopup(false);
      setDeleteSectionId("");
      dispatch(
        makeNetworkCallForGetSectionByCourseId(
          courseId,
          RequestType.GET,
          NetworkConstant.getSectionByCourseId,
          {},
          {}
        )
      );
    }
  }, [courseReducer.deleteSection.statusCode]);

  function checkAllValues() {
    let isValid = true;
    if (title === "") {
      setTitleErrorVisible(true);
      isValid = false;
    }
    if (description === "") {
      setDescriptionErrorVisible(true);
      isValid = false;
    }
    if (sectionImageUploaded === null || sectionImageUploaded === "") {
      setShowSnackBar(true);
      isValid = false;
    }
    return isValid;
  }

  const handleOnSave = () => {
    if (checkAllValues()) {
      let createSectionRequest = {
        courseId: courseId,
        name: title,
        description: description,
        sectionId: "",
      };
      let formData = new FormData();
      formData.append("image", sectionImageUploaded);
      formData.append(
        "createSectionRequestJson",
        JSON.stringify(createSectionRequest)
      );

      dispatch(
        makeNetworkCallCreateSection(
          RequestType.POST,
          NetworkConstant.createSection,
          formData,
          {}
        )
      );
    }
  };

  const customStyles = {
    content: {
      width: "60%",
      height: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "auto",
      marginBottom: "auto",
      borderRadius: "10px",
      border: "none",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      display: "flex",
      flexDirection: "column",
      paddingLeft: "5%",
      paddingRight: "5%",
    },
    input: {
      border: "none",
      outline: "none",
    },
  };

  return (
    <>
      {courseReducer.getSectionByCourseId.responseData.map((val, key) => {
        return (
          <div className="section-list" key={key}>
            <div className="section-content">
              <h3
                style={{
                  fontFamily: "FuturaCondensed",
                  fontSize: "x-large",
                }}
              >
                {val.sectionName}
              </h3>
              <p
                style={{
                  fontFamily: "FuturaCondensed",
                  fontSize: "large",
                }}
              >
                {val.description}
              </p>
              <div className="manage-section-button-container">
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  icon={<SpeedDialIcon />}
                  FabProps={{
                    size: "small",
                  }}
                  direction="left"
                  style={{
                    marginRight: "20px",
                  }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      // sx={{
                      //   backgroundColor: action.color,
                      // }}
                      onClick={(event) => {
                        if (action.name === "Copy") {
                          handleCopySectionClick(
                            event,
                            val.sectionId,
                            val.sectionName
                          );
                        } else if (action.name === "Edit section") {
                          editSectionOpenPopup(
                            val.sectionName,
                            val.description,
                            val.sectionId,
                            val.image
                          );
                        } else if (action.name === "Add chapter") {
                          handleAddChapterClick(val);
                        } else if (action.name === "Delete") {
                          handleDeleteSection(val);
                        }
                      }}
                    />
                  ))}
                </SpeedDial>
              </div>
            </div>
          </div>
        );
      })}
      <Dialog open={showDeletePopup}>
        <DialogTitle>
          <div className="delete-container">
            <Lottie
              loop
              animationData={deleteLottieJson}
              play
              style={{ width: 150, height: 150 }}
            />
            <p className="delete-message">
              Are you sure you want to delete ?
              {<p className="section-name-style">{sectionName}</p>}
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="button-container-delete">
            <button
              className="button-style-no"
              onClick={() => {
                setDeleteSectionId("");
                return setShowDeletePopup(false);
              }}
            >
              No
            </button>
            <button className="button-style-yes" onClick={handleConfirmDelete}>
              Yes
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <div className="plus-icon" onClick={handleOnClickPlusIcon}>
        <img src={require("../../assets/image/plus-icon.png")} />
      </div>
      <Dialog style={customStyles} open={showModal}>
        <DialogTitle className="section-dialog-title">
          <TextField
            error={titleErrorVisible}
            id="outlined-error-helper-text"
            label="Title"
            style={{
              marginTop: "5%",
            }}
            helperText={titleErrorVisible ? "Please enter title" : ""}
            onChange={handleOnChangeTitle}
            value={title}
          />
          <TextField
            error={descriptionErrorVisible}
            id="outlined-multiline-static"
            label="Description"
            multiline
            rows={5}
            style={{
              marginTop: "5%",
            }}
            helperText={
              descriptionErrorVisible ? "Please enter description" : ""
            }
            onChange={handleOnChangeDescription}
            value={description}
          />
          <div className="add-image">
            <img
              id="section-image-upload-preview"
              src={require("../../assets/image/thumbnail-background.png")}
              height={100}
              width={100}
              style={{
                borderRadius: "10px",
              }}
            />

            <Fab
              onClick={() => sectionImageUpload.current.click()}
              style={{
                width: "20%",
                height: "40px",
                borderRadius: "5px",
                marginTop: "3%",
                backgroundColor: "#ffbd03",
                alignSelf: "center",
                marginLeft: "10px",
                fontFamily: "FuturaCondensed",
                textAlignment: "center",
                letterSpacing: "1px",
              }}
            >
              <AddIcon
                style={{
                  marginRight: "5px",
                }}
              />
              Add Image
            </Fab>
            <input
              hidden
              className="file-thumbnail"
              type="file"
              onChange={handleOnChangeSectionUploadImage}
              ref={sectionImageUpload}
            />
          </div>
        </DialogTitle>
        <DialogContent className="section-dialog-content">
          <button
            className="button-style-manage-section"
            onClick={handleOnClickOfCancel}
          >
            Cancel
          </button>
          <button
            onClick={handleOnSave}
            className="button-style-manage-section"
          >
            Save
          </button>
        </DialogContent>
      </Dialog>
      {/* //? dialog for create chapter */}
      <Dialog style={customStyles} open={showCreateChapterPopup}>
        <DialogTitle className="section-create-chapter-dialog">
          <TextField
            error={titleErrorVisibleCreateChapter}
            id="outlined-error-helper-text"
            label="Title"
            style={{
              marginTop: "5%",
            }}
            helperText={
              titleErrorVisibleCreateChapter ? "Please enter title" : ""
            }
            onChange={handleOnChangeTitleCreateChapter}
            value={titleCreateChapter}
          />
          <TextField
            error={descriptionErrorVisibleCreateChapter}
            id="outlined-multiline-static"
            label="Description"
            multiline
            rows={5}
            style={{
              marginTop: "5%",
            }}
            helperText={
              descriptionErrorVisibleCreateChapter
                ? "Please enter description"
                : ""
            }
            onChange={handleOnChangeDescriptionCreateChapter}
            value={descriptionCreateChapter}
          />
          <TextField
            error={requiredTrophiesErrorVisible}
            id="outlined-error-helper-text"
            label="Required Trophies"
            style={{
              marginTop: "5%",
            }}
            helperText={
              requiredTrophiesErrorVisible
                ? "Please enter required trophies in number"
                : ""
            }
            onChange={handleOnChangeRequiredTrophies}
            value={requiredTrophies}
          />

          <div className="add-image">
            <img
              id="image-upload-preview"
              src={require("../../assets/image/thumbnail-background.png")}
              height={100}
              width={100}
              style={{
                borderRadius: "10px",
              }}
            />

            <Fab
              onClick={() => imageUpload.current.click()}
              style={{
                width: "20%",
                height: "40px",
                borderRadius: "5px",
                marginTop: "3%",
                backgroundColor: "#ffbd03",
                alignSelf: "center",
                marginLeft: "10px",
                fontFamily: "FuturaCondensed",
                textAlignment: "center",
                letterSpacing: "1px",
              }}
            >
              <AddIcon
                style={{
                  marginRight: "5px",
                }}
              />
              Add Image
            </Fab>
            <input
              hidden
              className="file-thumbnail"
              type="file"
              onChange={handleOnChangeImageUpload}
              ref={imageUpload}
            />
          </div>
        </DialogTitle>
        <DialogContent className="section-dialog-content">
          <button
            className="button-style-manage-section"
            onClick={handleCancelCreateChapter}
          >
            Cancel
          </button>
          <button
            onClick={handleOnSaveCreateChapter}
            className="button-style-manage-section"
          >
            Save
          </button>
        </DialogContent>
      </Dialog>
      {apiState.showLoader && <BlueLoader />}
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        open={showSnackBar}
        autoHideDuration={6000}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Please upload image
        </Alert>
      </Snackbar>
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
      <EditSectionPopup {...editSectionPopupProps} />
      <CopySectionPopup {...copySectionPopupProps} />
    </>
  );
};

export default ManageSections;
