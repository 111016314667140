import { TextField } from "@mui/material";
import "./NotificationForCartUsers.css";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { makeNetworkCallSendNotificationToAllDeviceType } from "../../redux/action/action";
import { RequestType } from "../../network/RequestType";
import { NetworkConstant } from "../../network/NetworkConstant";
import { useEffect } from "react";
import { ActionType } from "../../redux/action/actionType";
import { ShowSnackBar } from "../../snackbar/SnackBar";
import { Constant } from "../../constants/Constant";
import { makeNetworkCallSendNotificationToCartPendingUser, makeNetworkCallToSendExpoPushNotification } from "../../redux/action/NotificationAction";
export const NotificationForCartUsers = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [showTitleError, setShowTitleError] = useState(false);
  const [description, setDescription] = useState("");
  const [showDescriptionError, setShowDescriptionError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const notificationState = useSelector((state) => state.notificationReducer);
  const apiState = useSelector((state) => state.apiStateReducer);

  const handleSendClick = () => {
    if (isValid()) {
      setShowLoading(true);
      dispatch(
        makeNetworkCallSendNotificationToCartPendingUser(
          RequestType.POST,
          NetworkConstant.sendNotificationToAllDeviceType,
          {},
          {}
        )
      );
    }
  };

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: { showSnackBar: false, snackBarMessage: "" },
      });
    }, 5000);
  }

  useEffect(() => {
    if (title !== "" && title !== undefined) {
      setShowTitleError(false);
    }
    if (description !== "" && description !== undefined) {
      setShowDescriptionError(false);
    }
  }, [title, description]);

  useEffect(() => {
    if (
      notificationState.sendNotificationToUserOnCartBasis.statusCode === 200
    ) {
      generateRequestForExpoTokenAndHitApi();
        dispatch({
          type: ActionType.RESET_SEND_NOTIFICATION_TO_USER_ON_CART_BASIS,
          payload: {},
        });
    }
  }, [notificationState.sendNotificationToUserOnCartBasis.statusCode]);

  //? use effect for successful push notification send
  useEffect(() => {
    if (
      notificationState.sendNotificationToAllDeviceTypeForExpoApi.statusCode ===
      200
    ) {
      setShowLoading(false)
      dispatch({
        type: ActionType.RESET_SEND_NOTIFICATION_THROUGH_EXPO_SERVER,
        payload: {},
      });
    }
  }, [notificationState.sendNotificationToAllDeviceTypeForExpoApi.statusCode]);

  const generateRequestForExpoTokenAndHitApi = () => {

    const filteredList =
      notificationState.sendNotificationToUserOnCartBasis.responseData.filter(
        (val) => val.userDeviceToken?.includes("ExponentPushToken")
      );

    const tokenList = filteredList?.map((val) =>
      val.userDeviceToken
    );
    const notificationRequest = {
      title: title,
      data: {
        show: description,
      },
      tokens: tokenList,
    };
    dispatch(
      makeNetworkCallToSendExpoPushNotification(notificationRequest, {})
    );
  };

  const isValid = () => {
    var isAllValid = true;
    if (title === undefined || title === "") {
      setShowTitleError(true);
      isAllValid = false;
    }
    if (description === undefined || description === "") {
      setShowDescriptionError(true);
      isAllValid = false;
    }
    return isAllValid;
  };

  //? style for input props

  const inputPropsStyle = {
    inputProp: {
      style: { fontFamily: Constant.FUTURA_CONDENSED },
    },
  };

  return (
    <>
      <div className="notification-area">
        <TextField
          className="text-area"
          id="outlined-error-helper-text"
          error={showTitleError}
          helperText={showTitleError ? <span>Please enter title</span> : ""}
          onChange={(event) => setTitle(event.target.value)}
          inputProps={inputPropsStyle.inputProp}
          value={title}
          label={<span className="font-change-style">Title</span>}
        />
        <TextField
          style={{
            marginTop: "20px",
          }}
          className="text-area"
          id="outlined-error-helper-text"
          multiline
          inputProps={inputPropsStyle.inputProp}
          error={showDescriptionError}
          helperText={
            showDescriptionError ? <span>Please enter description</span> : ""
          }
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          label={<span className="font-change-style">Description</span>}
          rows={10}
        />

        <Stack
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
          direction="row"
          spacing={1}
        >
          <LoadingButton
            loading={showLoading}
            loadingPosition="start"
            startIcon={<SendIcon />}
            variant="contained"
            onClick={handleSendClick}
          >
            <span>Send</span>
          </LoadingButton>
        </Stack>
      </div>
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};
