import Lottie from "react-lottie-player";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent, Button } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { makeNetworkCallPublishCourse } from "../../../../redux/action/action";
import { NetworkConstant } from "../../../../network/NetworkConstant";
import { ActionType } from "../../../../redux/action/actionType";
import { RequestType } from "../../../../network/RequestType";
import { ShowSnackBar } from "../../../../snackbar/SnackBar";
import "./PublishCoursePopup.css";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import cloudAnimation from "../../../../assets/lottie/cloud-animation.json"
const PublishCoursePopup = ({
  show,
  courseName,
  courseId,
  onCancelClick,
  onResponse,
}) => {
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.apiStateReducer);
  const courseReducer = useSelector((state) => state.courseReducer);
  const [showLoadingForPublish, setShowLoadingForPublish] = useState(false);
  const [showLoadingForUnPublish, setShowLoadingForUnPublish] = useState(false);
  const [isPublish, setIsPublish] = useState(false)

  const handleOnClickPublish = () => {
    setShowLoadingForPublish(true);
    setIsPublish(true)
    dispatch(
      makeNetworkCallPublishCourse(
        RequestType.POST,
        NetworkConstant.publishCourse,
        courseId,
        true,
        {},
        {}
      )
    );
  };

  const handleOnClickUnPublish = () => {
    setShowLoadingForUnPublish(true);
    setIsPublish(false)
    dispatch(
      makeNetworkCallPublishCourse(
        RequestType.POST,
        NetworkConstant.publishCourse,
        courseId,
        false,
        {},
        {}
      )
    );
  };

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  useEffect(() => {
    if (courseReducer.publishCourse.statusCode === 200) {
      setShowLoadingForPublish(false);
      setShowLoadingForUnPublish(false);
      dispatch({
        type: ActionType.RESET_PUBLISH_COURSE,
        payload: {},
      });
      onResponse(isPublish);
    }
  }, [courseReducer.publishCourse.statusCode]);

  return (
    <>
      <Dialog open={show}>
        <DialogTitle>
          <div className="publish-container">
            <Lottie
              loop
              animationData={cloudAnimation}
              play
              style={{ width: 150, height: 150 }}
            />

            <p className="publish-message">
              Are you sure you want to Publish ?
              {<p className="course-name-style">{courseName}</p>}
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="button-container-publish">
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={onCancelClick}
            >
              Cancel
            </Button>
            <div className="end-button">
              <Stack
                style={{
                  marginRight: "10px",
                }}
                direction="row"
                spacing={1}
              >
                <LoadingButton
                  loading={showLoadingForPublish}
                  loadingPosition="start"
                  startIcon={<SendIcon />}
                  variant="contained"
                  onClick={handleOnClickPublish}
                >
                  Publish
                </LoadingButton>
              </Stack>
              <Stack direction="row" spacing={1}>
                <LoadingButton
                  loading={showLoadingForUnPublish}
                  loadingPosition="start"
                  startIcon={<SendIcon />}
                  variant="contained"
                  onClick={handleOnClickUnPublish}
                >
                  UnPublish
                </LoadingButton>
              </Stack>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ShowSnackBar
        severity={apiState.snackBarSeverity}
        isVisible={apiState.showSnackBar}
        message={apiState.snackBarMessage}
      />
    </>
  );
};

export { PublishCoursePopup };
