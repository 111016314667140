import { Alert, Snackbar } from "@mui/material";

export const ShowSnackBar = ({ severity, message, isVisible }) => {
  return (
    <Snackbar
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      open={isVisible}
      autoHideDuration={6000}
    >
      <Alert severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
