import { ActionType } from "../action/actionType";

const initialState = {
  createCarousel: {
    statusCode: 0,
    responseMessage: "",
  },
  getAllCarousel: {
    statusCode: 0,
    responseData: [],
  },
  deleteCarousel: {
    statusCode: 0,
    responseMessage: "",
  },
};

export const ManageAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.CREATE_CAROUSEL: {
      return {
        ...state,
        createCarousel: {
          statusCode: action.payload.createCarousel.statusCode,
          responseMessage: action.payload.createCarousel.responseMessage,
        },
      };
    }
    case ActionType.RESET_CREATE_CAROUSEL: {
      return {
        ...state,
        createCarousel: {
          statusCode: 0,
          responseMessage: "",
        },
      };
    }
    case ActionType.GET_ALL_CAROUSEL: {
      return {
        ...state,
        getAllCarousel: {
          statusCode: action.payload.getAllCarousel.statusCode,
          responseData: action.payload.getAllCarousel.responseData,
        },
      };
    }
    case ActionType.RESET_GET_ALL_CAROUSEL: {
      return {
        ...state,
        getAllCarousel: {
          statusCode: 0,
          responseData: [],
        },
      };
    }
    case ActionType.DELETE_CAROUSEL: {
      return {
        ...state,
        deleteCarousel: {
          statusCode: action.payload.deleteCarousel.statusCode,
          responseMessage: action.payload.deleteCarousel.responseMessage,
        },
      };
    }
    case ActionType.RESET_DELETE_CAROUSEL: {
      return {
        ...state,
        deleteCarousel: {
          statusCode: 0,
          responseMessage: "",
        },
      };
    }
    default:
      return state;
  }
};
