export const GetPriceList = () => {
  const priceList = [];
  priceList.push(10);
  priceList.push(15);
  priceList.push(20);
  for (let i = 32; i <= 1152; i = i + 32) {
    priceList.push(i);
  }
  return priceList;
};

export const isNumber = (value) => {
  if (typeof value === "string") {
    return !isNaN(value);
  }
};

export const formatNumber = (number) => {
  return number.toLocaleString("hi-IN");
};

export const parseDate = (date) => {
  const inputDate = new Date(date);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedInputDate = inputDate.toLocaleDateString("en-US", options);
  return formattedInputDate;
};

//? parse data into date and time in human readable form
export const parseDataIntoDateAndTimeInHumanReadableForm = (date) => {
  const dateTime = new Date(date);
  dateTime.setHours(dateTime.getHours() + 5); // for converting this into GMT to IST
  dateTime.setMinutes(dateTime.getMinutes() + 30); // for converting this into GMT to IST
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true, // Use 12-hour clock (AM/PM)
  };
  return dateTime.toLocaleString("en-US", options);
};

export const parseDateIntoWeekDaysDateMonthYear = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

export const getParsedCurrentTime = () => {
  const currentDate = new Date();
  const options = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const formattedTime = currentDate.toLocaleTimeString(undefined, options);
  return formattedTime;
};

export const getFormattedTime = (currentTime) => {
  const date = new Date(currentTime);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = `${hours}:${minutes}`;
  return formattedTime;
};

export const checkUrlPattern = (urlValue) => {
  const urlPattern =
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
  return urlPattern.test(urlValue);
};
