import { TextField } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NetworkConstant } from "../../network/NetworkConstant";
import { RequestType } from "../../network/RequestType";
import { makeNetworkCallCreateCourse } from "../../redux/action/action";
import { ActionType } from "../../redux/action/actionType";
import { ShowSnackBar } from "../../snackbar/SnackBar";
import { BlueLoader, inputPropsStyle } from "../../util/AppUtils";
import "./CreateCourse.css";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Cropper from "react-easy-crop";
import Modal from "react-modal";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { formatNumber, GetPriceList, isNumber } from "../../util/CommonUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import { Constant } from "../../constants/Constant";
import Lottie from "react-lottie-player";
import fileAnimation from "../../assets/lottie/files_animation.json";

export default function CreateCourse() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [attachment, setAttachment] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [croppedThumbnail, setCroppedThumbnail] = useState("");
  const [thumbnailUploaded, setThumbnailUploaded] = useState(false);
  const [tagOne, setTagOne] = useState("");
  const [tagTwo, setTagTwo] = useState("");
  const [tagOneList, setTagOneList] = useState([]);
  const [tagTwoList, setTagTwoList] = useState([]);
  const [errorTitlevisible, setErrorTitlevisible] = useState(false);
  const [errorDescriptionvisible, setErrorDescriptionvisible] = useState(false);
  const [errorThumbnailVisible, setErrorThumbnailVisible] = useState(false);
  const [errorAttachmentlVisible, setErrorAttachmentVisible] = useState(false);
  const [priceValue, setPriceValue] = useState("");
  const [showAddPricingPopup, setShowAddPricingPopup] = useState(false);
  const [showDurationError, setShowDurationError] = useState(false);
  const [showPriceError, setShowPriceError] = useState(false);
  const [courseDurationPriceList, setCourseDurationPriceList] = useState([]);
  const [durationValue, setDurationValue] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const apiState = useSelector((state) => state.apiStateReducer);
  const courseReducer = useSelector((state) => state.courseReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const thumbnailInputFile = useRef(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
  }, []);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const handleDeletePrice = (deleteKey) => {
    const filterValue = courseDurationPriceList.filter(
      (item, key) => deleteKey !== key
    );
    setCourseDurationPriceList(filterValue);
  };

  const handleShowAddPricingPopup = () => {
    setPriceValue("");
    setDurationValue("");
    setShowPriceError(false);
    setShowDurationError(false);
    setShowAddPricingPopup(true);
  };

  const handleChangePrice = (event) => {
    setPriceValue(event.target.value);
  };
  const handleChangeDuration = (event) => {
    setDurationValue(event.target.value);
  };

  const checkForValueOfPricePopUp = () => {
    let isError = false;
    if (priceValue === "") {
      setShowPriceError(true);
      isError = true;
    }
    if (durationValue === "") {
      setShowDurationError(true);
      isError = true;
    }

    if (priceValue !== "" && !isNumber(priceValue)) {
      setShowPriceError(true);
      isError = true;
    }
    return isError;
  };

  const handleDoneClick = () => {
    if (!checkForValueOfPricePopUp()) {
      setCourseDurationPriceList((previousData) => [
        ...previousData,
        {
          duration: durationValue,
          price: parseInt(priceValue),
        },
      ]);
      setShowAddPricingPopup(false);
    }
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.RESET_ALL_COURSE,
        payload: {},
      });
    };
  }, []);

  useEffect(() => {
    if (courseReducer.createCourseResponse.statusCode === 200) {
      navigate(
        `/dashboard/course/${courseReducer.createCourseResponse.courseId}`,
        { state: courseReducer.createCourseResponse.courseId }
      );
    }
  }, [courseReducer]);

  useEffect(() => {
    setErrorTitlevisible(false);
  }, [title]);

  useEffect(() => {
    setErrorDescriptionvisible(false);
  }, [description]);

  // useEffect(() => {
  //   setErrorAttachmentVisible(false);
  //   setSeverity("success");
  // }, [attachment]);

  useEffect(() => {
    setErrorThumbnailVisible(false);
    setSeverity("success");
  }, [thumbnail]);

  const customStyles = {
    content: {
      width: "40%",
      height: "40%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "auto",
      marginBottom: "auto",
      borderRadius: "10px",
      border: "none",
      //boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      display: "flex",
      flexDirection: "column",
      paddingLeft: "5%",
      paddingRight: "5%",
      zIndex: "1000",
    },
  };

  const handleDeleteOfChip = (tagName, deletedKey) => {
    if (tagName === "TAG1") {
      const filteredItem = tagOneList.filter((item, key) => key !== deletedKey);
      setTagOneList(filteredItem);
    } else {
      const filteredItem = tagTwoList.filter((item, key) => key !== deletedKey);
      setTagTwoList(filteredItem);
    }
  };

  const handleKeyboardEnter = (event, tagName) => {
    if (event.key == "Enter") {
      if (tagName === "TAG1") {
        setTagOneList((initialData) => [...initialData, tagOne]);
        setTagOne("");
      } else {
        setTagTwoList((initialData) => [...initialData, tagTwo]);
        setTagTwo("");
      }
    }
  };

  const handleOnChangeFile = (event) => {
    setAttachment(event.target.files[0]);
  };

  const handleOnChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleOnChangeCourseDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleOnChangeTagOne = (event) => {
    setTagOne(event.target.value);
  };

  const handleOnChangeTagTwo = (event) => {
    setTagTwo(event.target.value);
  };

  const handleOnChangeThumbnail = (event) => {
    if (
      event.target.files[0] !== undefined &&
      event.target.files[0] !== null &&
      event.target.files[0] !== ""
    )
      setThumbnail(event.target.files[0]);
    setThumbnailUploaded(true);
    // const imageSrc = URL.createObjectURL(event.target.files[0]);
    // const imagePreviewElement = document.querySelector(
    //   "#preview-selected-thumbnail"
    // );
    // imagePreviewElement.src = imageSrc;
  };

  function checkForAllValues() {
    let isValid = true;
    if (title === "") {
      setErrorTitlevisible(true);
      isValid = false;
    }
    if (description === "") {
      setErrorDescriptionvisible(true);
      isValid = false;
    }
    if (thumbnail === "") {
      setSeverity("error");
      setSnackbarMessage("Please upload thumbnail");
      setErrorThumbnailVisible(true);
      isValid = false;
    }
    if (
      courseDurationPriceList === "" ||
      courseDurationPriceList.length === 0
    ) {
      setSeverity("error");
      setSnackbarMessage("Please add pricing");
      setErrorThumbnailVisible(true);
      isValid = false;
    }
    // if (attachment === "") {
    //   setSeverity("error");
    //   setSnackbarMessage("Please upload attachment");
    //   setErrorAttachmentVisible(true);
    //   isValid = false;
    // }

    return isValid;
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleOnClickSave = (event) => {
    event.preventDefault();

    //!check for all values
    if (checkForAllValues()) {
      let courseRequestJson = {
        title: title,
        description: description,
        tag1: tagOneList,
        tag2: tagTwoList,
        courseDurationPriceMapping: courseDurationPriceList,
      };
      let formData = new FormData();
      formData.append("thumbnail", thumbnail);
      if (attachment !== "" && attachment !== undefined) {
        formData.append("attachment", attachment);
      }
      formData.append("courseRequestJson", JSON.stringify(courseRequestJson));
      dispatch(
        makeNetworkCallCreateCourse(
          RequestType.POST,
          NetworkConstant.createCourse,
          formData,
          {}
        )
      );
    }
  };

  if (errorThumbnailVisible || apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: { showSnackBar: false, snackBarMessage: "" },
      });
      setErrorAttachmentVisible(false);
      setErrorThumbnailVisible(false);
    }, 5000);
  }

  //? convert days into month and year

  const convertDaysInMonthOrYear = (days) => {
    var convertedValue = "";
    if (days % 360 === 0) {
      convertedValue = days / 360 + " " + Constant.YEAR;
    } else {
      convertedValue = days / 30 + " " + Constant.MONTH;
    }
    return convertedValue;
  };

  return (
    <>
      <div className="form-create-course">
        <h3 className="heading">Course Title</h3>
        <TextField
          error={errorTitlevisible}
          id="outlined-error-helper-text"
          label={<span>Title</span>}
          inputProps={inputPropsStyle.inputProp}
          helperText={errorTitlevisible ? <span>Please enter title</span> : ""}
          rows={20}
          onChange={handleOnChangeTitle}
          value={title}
        />
        <h3 className="heading">Course Description</h3>
        <TextField
          error={errorDescriptionvisible}
          id="outlined-multiline-static"
          inputProps={inputPropsStyle.inputProp}
          label={<span>Description</span>}
          multiline
          rows={5}
          onChange={handleOnChangeCourseDescription}
          value={description}
          helperText={
            errorDescriptionvisible ? <span>Please enter description</span> : ""
          }
        />
        <h3 className="heading">Tag 1</h3>
        <Stack direction="row" spacing={1}>
          {tagOneList.map((val, key) => {
            return (
              <Chip
                key={key}
                label={val}
                onDelete={() => handleDeleteOfChip("TAG1", key)}
              />
            );
          })}
        </Stack>
        <TextField
          style={{
            marginTop: "2%",
          }}
          id="outlined-error-helper-text"
          label={<span>Enter Tag 1</span>}
          inputProps={inputPropsStyle.inputProp}
          onChange={handleOnChangeTagOne}
          value={tagOne}
          onKeyDown={(event) => handleKeyboardEnter(event, "TAG1")}
        />
        <h3 className="heading">Tag 2</h3>
        <Stack direction="row" spacing={1}>
          {tagTwoList.map((val, key) => {
            return (
              <Chip
                key={key}
                label={val}
                onDelete={() => handleDeleteOfChip("TAG2", key)}
              />
            );
          })}
        </Stack>
        <TextField
          style={{
            marginTop: "2%",
          }}
          id="outlined-error-helper-text"
          label={<span>Enter Tag 2</span>}
          inputProps={inputPropsStyle.inputProp}
          onChange={handleOnChangeTagTwo}
          value={tagTwo}
          onKeyDown={(event) => handleKeyboardEnter(event, "TAG2")}
        />

        {/* //?add pricing list ui */}

        <div className="price-item-container">
          {courseDurationPriceList.map((val, key) => {
            return (
              <div className={"price-item-" + ((key + 1) % 4)}>
                <h3 className="heading-title">Price</h3>
                <p className="value-title">Rs {formatNumber(val.price)}</p>
                <h3 className="heading-title">Duration</h3>
                <p className="value-title">
                  {convertDaysInMonthOrYear(val.duration)}
                </p>
                <div className="delete-icon-box">
                  <Tooltip title="Delete" arrow>
                    <Fab
                      size="small"
                      className="button-delete"
                      color="error"
                      aria-label="delete"
                      onClick={() => handleDeletePrice(key)}
                    >
                      <DeleteIcon />
                    </Fab>
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>

        {/* //? Add Pricing button */}

        <Fab
          onClick={handleShowAddPricingPopup}
          style={{
            width: "20%",
            height: "40px",
            borderRadius: "5px",
            marginTop: "2%",
            alignSelf: "flex-end",
            backgroundColor: "#ffbd03",
            fontFamily: "FuturaCondensed",
            textAlignment: "center",
            letterSpacing: "1px",
          }}
        >
          <AddIcon
            style={{
              marginRight: "5px",
            }}
          />
          Add Pricing
        </Fab>
        {thumbnailUploaded && (
          <div className="thumbnail-container">
            <img
              id="preview-selected-thumbnail"
              className={
                thumbnailUploaded ? "thumbnail-preview" : "initial-thumbnail"
              }
              src={URL.createObjectURL(thumbnail)}
            />
          </div>
        )}
        <button
          className="upload-thumbnail-button"
          onClick={() => thumbnailInputFile.current.click()}
        >
          Upload Thumbnail
        </button>
        <input
          hidden
          className="file-thumbnail"
          type="file"
          onChange={handleOnChangeThumbnail}
          ref={thumbnailInputFile}
        />
        {attachment !== "" && (
          <div className="attachment-container">
            <a
              href={URL.createObjectURL(attachment)}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Lottie
                loop
                animationData={fileAnimation}
                play
                style={{ width: 200, height: 150 }}
              />
            </a>
          </div>
        )}
        <input
          className="file-attachment"
          type="file"
          onChange={handleOnChangeFile}
        />
        <button className="button-save" onClick={handleOnClickSave}>
          Save
        </button>
      </div>
      {/* {thumbnailUploaded && (
        <Cropper
          image={thumbnail}
          crop={crop}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      )} */}
      {showAddPricingPopup && (
        <Dialog open={showAddPricingPopup}>
          <DialogTitle
            style={{
              height: "auto",
              width: "500px",
              display: "flex",
              flexDirection: "column",
              paddingTop: "30px",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            <p className="add-pricing-title">Select Duration</p>
            <FormControl
              style={{
                width: "100%",
                alignSelf: "center",
              }}
            >
              <InputLabel>Duration</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={durationValue}
                label="Duration"
                MenuProps={MenuProps}
                onChange={handleChangeDuration}
              >
                {GetPriceList().map((val, key) => {
                  var showValueOnUi = "";
                  if (key === 0) {
                    showValueOnUi = val + " " + Constant.DAYS;
                  } else if (key === 1) {
                    showValueOnUi = val + " " + Constant.DAYS;
                  } else if (key === 2) {
                    showValueOnUi = val + " " + Constant.DAYS;
                  } else if (val % 384 === 0) {
                    showValueOnUi = val / 384 + " " + Constant.YEAR;
                  } else {
                    showValueOnUi = val / 32 + " " + Constant.MONTH;
                  }
                  return <MenuItem value={val}>{showValueOnUi}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <p className="add-pricing-title-add-price">Add Price</p>
            <TextField
              style={{
                width: "100%",
                alignSelf: "center",
              }}
              id="outlined-error-helper-text"
              label={<span>Price</span>}
              onChange={handleChangePrice}
              inputProps={inputPropsStyle.inputProp}
              value={priceValue}
              error={showPriceError}
              helperText={
                showPriceError ? <span>Please enter price in number</span> : ""
              }
            />
          </DialogTitle>
          <DialogContent>
            <div className="button-container-add-price">
              <button
                className="button-style-cancel-add-price"
                onClick={() => {
                  return setShowAddPricingPopup(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button-style-done-add-price"
                onClick={handleDoneClick}
              >
                Done
              </button>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {apiState.showLoader && <BlueLoader />}
      <ShowSnackBar
        severity={severity}
        isVisible={apiState.showSnackBar || errorThumbnailVisible}
        message={
          errorThumbnailVisible ? snackbarMessage : apiState.snackBarMessage
        }
      />
    </>
  );
}
